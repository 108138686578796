import React, { createContext, useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import Tabs from './casino-tab';
import AllGames from './all-games';
// import  casinoGameList1  from "./data";
import Intl from '../../common/Intl'
import { withAppContext } from 'src/store/initAppContext';
import { useLocation, useParams } from "react-router-dom";
import cookieUtil from "src/util/cookieUtil";
import { ECookieName } from "src/util/utilModel";
import BlackjackSEO from "src/components/SEOComponents/BlackjackSEO";
import RouletteSEO from "src/components/SEOComponents/RouletteSEO";
import DragontigerSEO from "src/components/SEOComponents/DragontigerSEO";
import BaccaratSEO from "src/components/SEOComponents/BaccaratSEO";
import gameData from "src/static/game-data/casinogames.json";
import SEOContents from "src/components/SEOContents";
import EvolutionSEO from "src/components/SEOComponents/EvoSE0";
import SUPERNOWASEO from "src/components/SEOComponents/supernowaSEO";
// import BETGAMESSEO from "src/components/SEOComponents/bet-games";
import BetgamesSEO from "src/components/SEOComponents/betgames";
import EZUGISEO from "src/components/SEOComponents/ezugiSEO";
import Mojos7 from "src/components/SEOComponents/sevnMojos";
import SexyGamingSEO from "src/components/SEOComponents/sexyhgaming";
import ONLINECASINOSEO from "src/components/SEOComponents/onlineCasino";
import MACAWSEO from "src/components/SEOComponents/macaw";
import TVbetSEO from "src/components/SEOComponents/TVbetseo";
import SuperspadeSEO from "src/components/SEOComponents/superspadeseo";
import AgSEO from "src/components/SEOComponents/AgSeo";
// import { Helmet } from "react-helmet";
import LightningRouletteSEO from "src/components/SEOComponents/LightningRouletteSEO";
import FunkyTimeSEO from "src/components/SEOComponents/FunkyTimeSEO";
import CrazyTimeSEO from "src/components/SEOComponents/CrazyTimeSEO";
import TeenpattiSEO from "src/components/SEOComponents/TeenpattiSEO";

// Provider Icons for Live Casino, Slot Games, Bingo, Teenpatti---------------------------------
export const allIcon = `${'/static/media/AllProviderIcon.ab512083.png'}`;
export const netentIcon = `${'/static/media/netent.png'}`;
// export const betsoftIcon = `${'/static/media/Betsofticon.png'}`;
export const tvBetIcon = `${'/static/media/tvbet_icon.png'}`;
export const superSpadeIcon = `${'/static/media/superspade.png'}`;
export const ezugiIcon = `${'/static/media/ezugi_icon.png'}`;
export const evolutionIcon = `${'/static/media/evolution_icon.png'}`;
export const bettvIcon = `${'/static/media/bettv.png'}`;
export const agIcon = `${'/static/media/amazing_gaming.png'}`;
export const supernowaIcon = `${'/static/media/supernowa.png'}`;
export const spribeIcon = `${'/static/media/spribe_icon.png'}`;
export const sexygamingIcon = `${'/static/media/sexygamingIcon.png'}`
const kingmkersicon = `${'/static/media/kingmkersicon.png'}`;
const mplayicon = `${'/static/media/mplayicon.png'}`;
const macawicon = `${'/static/media/macawicon.png'}`;
const vivogamingIcon = `${'/static/media/vivogaming.png'}`;


//Macaw Images
// const hotbaloon = `${'/static/common/media/hotBaloon.png'}`
// const hotbaloonHindi = `${'/static/common/media/hotBaloonHindi.png'}`
// const macludo = `${'/static/common/media/macawMacludo.png'}`
// const macludoHindi = `${'/static/common/media/macawMacludoHindi.png'}`
// const hotbaloon = `${'/static/common/media/HotBallonNew.jpg'}`
// const neon = `${'/static/common/media/neon.jpg'}`
// const macludo = `${'/static/common/media/MacLudoNew.jpg'}`

const moj = `${'/static/media/7mojos.png'}`
let ALLGAMES = <Intl langKey="GAME@ALL" />;
// let NETENT = <Intl langKey="GAME@NETENT"/>;
// let BETSOFT = <Intl langKey="GAME@BETSOFT" />;
let AG = <Intl langKey="GAME@AG" />;
let SUPERSPADE = <Intl langKey="GAME@SUPERSPADE" />;
let EVOGAMES = <Intl langKey="GAME@EVOGAMES" />;
let BETGAMES = <Intl langKey="GAME@BETGAMES" />;
let EZUGI = <Intl langKey="GAME@EZUGI" />;
let TVBET = <Intl langKey="GAME@TVBET" />;
let SUPERNOWA = <Intl langKey="GAME@SUPERNOWA" />;
// let SPRIBE = <Intl langKey="GAME@SPRIBE" />;
let SEXYGAMING = <Intl langKey="GAME@SEXYGAMING" />;
let PRAGMATICPLAY = <Intl langKey="GAME@PRAGMATICPLAY" />;
// let SEXYGAMING = <Intl langKey="GAME@SEXYGAMING"/>;

const Language = cookieUtil.get(ECookieName.COOKIE_LANG);


let KINGMAKER = <Intl langKey="GAME@KINGMAKER" />
let MPLAY = <Intl langKey="GAME@MPLAY" />;
let MOJOS = <Intl langKey='GAME@7MOJO' />;
let MACAW = <Intl langKey="GAME@MACAW" />;
let VIVO = <Intl langKey="GAME@VIVO" />;


const providerList = ['all','evolution', 'ezugi','ag', 'betgames', 'tvbet', 'super-spade',  'supernowa', 'mplay',  '7mojos', 'macaw', 'kingmaker', 'vivo'];
const providerTab = [ALLGAMES, EVOGAMES, EZUGI, AG, BETGAMES, TVBET, SUPERSPADE, SUPERNOWA, MPLAY, MOJOS, MACAW, KINGMAKER, VIVO];

const providerIcons = [
    {
        id: 1,
        name: ALLGAMES,
        icon: allIcon
    },
    {
        id: 2,
        name: EVOGAMES,
        icon: evolutionIcon
    },
    {
        id: 3,
        name: EZUGI,
        icon: ezugiIcon
    },
    {
        id: 4,
        name: AG,
        icon: agIcon
    },
    {
        id: 5,
        name: BETGAMES,
        icon: bettvIcon
    },
    {
        id: 6,
        name: TVBET,
        icon: tvBetIcon
    },
    {
        id: 7,
        name: SUPERSPADE,
        icon: superSpadeIcon
    },
    {
        id: 8,
        name: SUPERNOWA,
        icon: supernowaIcon
    },
    // {
    //     id:12,
    //     name: SPRIBE,
    //     icon: spribeIcon
    // },
    {
        id: 9,
        name: MPLAY,
        icon: mplayicon
    },
    {

        id: 10,
        name: MOJOS,
        icon: moj
    },
    {
        id: 11,
        name: MACAW,
        icon: macawicon
    },
    {
        id: 12,
        name: KINGMAKER,
        icon: kingmkersicon
    },
    // {
    //     id: 13,
    //     name: SEXYGAMING,
    //     icon: sexygamingIcon
    // },
    {
        id: 13,
        name: VIVO,
        icon: vivogamingIcon
    }
]

// const structuredData = (domain) => {
//     const breadcrumbData = {
//         "@context": "https://schema.org",
//         "@type": "BreadcrumbList",
//         "itemListElement":
//             [
//                 {
//                     "@type": "ListItem",
//                     "item": `${domain}`,
//                     "name": "Homepage",
//                     "position": 1
//                 },
//                 {
//                     "@type": "ListItem",
//                     "item": `${domain}/live-casino`,
//                     "name": "Play Online Casino Games",
//                     "position": 2
//                 }],
//         "name": "Breadcrumbs"
//     }
  
//     return JSON.stringify(breadcrumbData);
// }

function CasinoTabGames(props) {
    const { app: { brandId }, theme: { config } } = props
    const { showSeoFooterInsidePages, showSEOfooterForYolo } = config
    const location = useLocation();
    const getLastqueryString = location.pathname.split("/").pop();
  

    const [casinoGames, setCasinoGames] = useState(gameData);
    const [filteredData, setfilteredData] = useState(gameData);
    const [search, setSearch] = useState('');
    const { provider, id } = useParams();
    // const param = useParams();
    // console.log('AAAAA-param',param);
  

    //const navigate = useNavigate()

    // useEffect(() => {
    //     // gameData(Language);
    //     // Now Lazy load game data 
    //     // import('src/static/game-data/casinogames.json').then(data => data.default).then(data => {
    //     // setCasinoGames(data);
    //     // if (!id) {
    //     //     setfilteredData(casinoGames)
    //     // }
    //     // }).catch(err => console.log('Something went wrong. Unable to fetch game data', err))



    //     //I am removing it becacuse in iexchange-frontend-desktop repo it is not there. if needed, we can enable 
    //     // window.scrollTo(0,0)
    //     // console.log('hiostory', history.location.searchText)
    //     // if(history.location.searchText !== undefined) {
    //     //     searchData(history.location.searchText)
    //     // }
    // }, [])



    const searchData = (val) => {
        setSearch(val);
    
        if (val !== "") {
            let _val = val && val.replace(/[\_\-\s]/gi, '');
            const filteredItems = casinoGames.filter((item) => {
                const { game = '', catagoryName = '', type = '', slug = '' } = item;
        
                // Normalize the fields by removing unwanted characters and converting to lowercase
                const normalizedGame = game.replace(/[\_\-\s]/gi, '').toLowerCase();
                const normalizedCategoryName = catagoryName.replace(/[\_\-\s]/gi, '').toLowerCase();
                const normalizedType = type.replace(/[\_\-\s]/gi, '').toLowerCase();
                const normalizedSlug = slug.replace(/[\_\-\s]/gi, '').toLowerCase();
        
                // Check if the cleaned-up field values start with the normalized search value
                return (
                    normalizedGame.includes(_val) || 
                    normalizedCategoryName.includes(_val) || 
                    normalizedType.includes(_val) || 
                    normalizedSlug.includes(_val)
                );
            

                // Clean up search value and item fields for better comparison
                // let type = item.type.toLowerCase() && item.type.replace(/[\_\-\s]/gi, '');
                // let slug = item.slug ? item.slug.replace(/[\_\-\s]/gi, '') : '';
                // let category = item.catagoryName ? item.catagoryName.replace(/[\_\-\s]/gi, '') : '';

    
                // // Check if either type or slug matches the search value
                // if (type.toLowerCase().includes(_val.toLowerCase()) || slug.toLowerCase().includes(_val.toLowerCase())) {
                //     return item;
                // }
                // else if ((item.game.replace(/[\_\-\s]/gi, '').toLowerCase()).startsWith(_val.toLowerCase())) {
                //     //searchOn = 'catagoryName'
                //     return item;
                //   }
                //   else if ((category.toLowerCase()).startsWith(_val.toLowerCase())) {
                //     //searchOn = 'catagoryName'
                //     return item;
                //   }
                //  else {
                //     const {platformCode, ...rest } = item;
                //     console.log('AAAAA-rest',rest);
                //     console.log('AAAAAA-',Object.values(rest).join('').toLowerCase().includes(val.toLowerCase()))
                //     // Fallback: Search through all other fields in the object
                //     return Object.values([platformCode]).join('').toLowerCase().includes(val.toLowerCase());
                // }
            });
    
            setfilteredData(filteredItems);
        } else {
            // Reset to original data when search input is empty
            setfilteredData(casinoGames);
        }
    };
    
    

    const budgetData = () => {

    }


    let domainURL = 'https://www.yolo247.site';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.baaziadda.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 46) {
        domainURL = 'https://www.fun88.site';
      }
    else if (brandId === 47) {
        domainURL = 'https://www.fun88.online';
    }

    const _id = provider &&  id ? id : provider;



    return (
        <>
            {
                _id && (
                    <SEOContents
                        pageName={
                            _id === "evolution" ? "evolution"
                                : _id === "supernowa" ? "supernowa"
                                : _id === "ezugi"  ? "ezugi"
                                : _id === "7mojos" ? "7MOJOS"
                                : _id === "sexy-gaming" ? "sexygaming"
                                : _id === 'macaw' ? 'macaw'
                                : _id === 'tvbet' ? 'tvbet'
                                : _id === 'super-spade' ? 'superspade'
                                : _id === 'ag'  ? 'ag'
                                : _id === 'live-blackjack'  ? "live-blackjack"
                                : _id === 'live-roulette' ? "roulette"
                                : _id === 'live-baccarat' ? "live-baccarat"
                                : _id === 'betgames' ? "betgames"
                                : _id === 'crazytime' ? "crazytime" 
                                : _id === 'crazy-time' ? "crazytime" 
                                : _id === 'lightning-roulette' ? "lightningroulette" 
                                : _id === 'monopoly-live' ? "monopolylive"  
                                : _id === 'funky-time' ? "funkytime"
                                : _id === 'lightning-dice' ? "lightningdice"
                                : _id === 'macludo' ? 'macludo'
                                : _id === 'bingo' ? 'bingo'
                                : _id === 'evo' ? 'evo'
                                : _id === 'dragon-tiger' ? (provider ==='evolution'? 'evodragonTiger' : 'dragonTiger')
                                : _id === 'first person blackjack' ? 'First-Person-Blackjack'
                                : _id === 'teen-patti' && provider ==='evolution' ? 'evo-teenpatti'
                                : _id === 'teenpatti 20 20' ? 'teenpatti2020-2020'
                                : _id === 'andar-bahar' && provider ==='kingmaker' ? 'kingmaker-andarbahar'
                                : _id === 'andar-bahar' && provider !=='kingmaker' ? 'andar-bahar'
                                : _id === 'dream-catcher' ? 'dream-catcher'
                                : _id === 'hi-lo' ? 'hi-lo'
                                : _id === 'teen-patti-20-20' && provider ==='super-spade' ? 'sup-teenpatti2020'
                                : _id
                        }
                    />
                )
            }
            {
                !_id &&  <SEOContents pageName="onlineCasinoGames" />

            }
            {/* <Helmet>
                <script type="application/ld+json">
                    {
                        structuredData(domainURL)
                    }
                </script>
            </Helmet> */}
            <CasinoWrapper>
                <TabListSection>
                    <Tabs
                        searchText={search}
                        providerIcons={providerIcons}
                        tabs={providerTab}
                        onSearch={searchData}
                        isTabsHidden={false}
                        budgetClick={budgetData}
                        minWidthInContent={'initial'}
                        providerList={providerList}
                        render={(index) => {
                            return (
                                <>
                                    {providerTab[index] === ALLGAMES && <AllGames data={filteredData} />}
                                    {providerTab[index] === AG && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'ag';
                                    })} />}
                                    {providerTab[index] === SUPERSPADE && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'super-spade';
                                    })} />}

                                    {providerTab[index] === EVOGAMES && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'evolution';
                                    })} />}

                                    {providerTab[index] === BETGAMES && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'betgames';
                                    })} />}
                                    {providerTab[index] === EZUGI && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'ezugi';
                                    })} />}
                                    {providerTab[index] === TVBET && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'tvbet';
                                    })} />}

                                    {providerTab[index] === SUPERNOWA && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'supernowa';
                                    })} />}
                                    {/* {providerTab[index] === SPRIBE && <AllGames data={filteredData.filter((element, index) => {
                                    return element.type.toLowerCase() === 'SPRIBELIVECASINO';
                                })} />} */}
                                    {providerTab[index] === SEXYGAMING && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'sexygaming';
                                    })} />}
                                    {providerTab[index] === PRAGMATICPLAY && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'pragmaticplay';
                                    })} />}
                                    {providerTab[index] === MPLAY && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'mplay';
                                    })} />}
                                    {providerTab[index] === MOJOS && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === '7mojos';
                                    })} />}
                                    {providerTab[index] === MACAW && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'macaw';
                                    })} />}
                                    {providerTab[index] === KINGMAKER && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'kingmaker';
                                    })} />}
                                    {providerTab[index] === VIVO && <AllGames data={filteredData.filter((element, index) => {
                                        return element.type.toLowerCase() === 'vivo';
                                    })} />}
                                </>
                            );
                        }} />
                </TabListSection>


            </CasinoWrapper> 
            {getLastqueryString === 'live-blackjack' && showSeoFooterInsidePages ? <BlackjackSEO /> :
                getLastqueryString === 'live-roulette' && showSeoFooterInsidePages ? <RouletteSEO /> :
                    id == 'evolution' && showSEOfooterForYolo ? <EvolutionSEO /> :
                        id == 'betgames' && showSEOfooterForYolo ? <BetgamesSEO /> :
                            id == 'supernowa' && showSEOfooterForYolo ? <SUPERNOWASEO /> :
                                id === 'sexy-gaming' && showSEOfooterForYolo ? <SexyGamingSEO /> :
                                    id === 'ezugi' && showSEOfooterForYolo ? <EZUGISEO /> :
                                        id === '7MOJOS' && showSEOfooterForYolo ? <Mojos7 /> :
                                            id === 'macaw' && showSEOfooterForYolo ? <MACAWSEO /> :
                                                id === 'tvbet' && showSEOfooterForYolo && !(brandId == 39 || brandId == 40) ? <TVbetSEO /> :
                                                    id === 'super-spade' && showSEOfooterForYolo && !(brandId == 39 || brandId == 40) ? <SuperspadeSEO /> :
                                                        id === "ag" && showSEOfooterForYolo && !(brandId == 39 || brandId == 40) ? <AgSEO /> :
                                                            getLastqueryString === 'dragon-tiger' && showSeoFooterInsidePages ? <DragontigerSEO /> :
                                                                getLastqueryString === 'live-baccarat' && showSeoFooterInsidePages ? <BaccaratSEO /> :
                                                                    getLastqueryString === 'teenpatti' && showSeoFooterInsidePages ? <TeenpattiSEO /> :
                                                                    showSEOfooterForYolo && getLastqueryString === 'live-casino' ? <ONLINECASINOSEO /> : 
                                                                        showSEOfooterForYolo && getLastqueryString === 'lightning-roulette' ? <LightningRouletteSEO /> : 
                                                                        showSEOfooterForYolo && getLastqueryString === 'funky-time' && (brandId == 39 || brandId == 40) ? <FunkyTimeSEO /> : showSEOfooterForYolo && getLastqueryString === 'crazy-time' && (brandId == 39 || brandId == 40) ? <CrazyTimeSEO /> : 
                                                                        <></>}</>
    )
}


export default withAppContext(withTheme(CasinoTabGames));

const CasinoWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
`;

const TabListSection = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    
`;
