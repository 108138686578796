import React from 'react'
import { Accordion, AccordionWrapper } from '../seo-content/seo_content'

import { Helmet } from "react-helmet";
import { withAppContext } from 'src/store/initAppContext';
import DragontigerSEO39 from './DragontigerSEO39';


const DragontigerSEO = (props) => {
    const { app: { brandId } } = props;

    let domainURL = 'https://www.yolo247.site';

    if (brandId === 32) {
        domainURL = 'https://www.yolo247.club';
    }
    else if (brandId === 33) {
        domainURL = 'https://www.baaziadda.com';
    }
    else if (brandId === 34) {
        domainURL = 'https://www.betdaily.club';
    }
    else if (brandId === 39) {
        domainURL = 'https://www.fomo7.com';
    } 
    else if (brandId === 40) {
        domainURL = 'https://www.fomo7.xyz';
    }

    const domain = (brandId === 31 || brandId === 32) ? 'Yolo247' : brandId === 33 ? 'Baazi Adda' : (brandId === 39 || brandId === 40) ? 'FOMO7' : 'Betdaily';

    return (brandId === 46 || brandId === 47)? <></>:(
        <>
            {brandId == 33 ? <></> :
                <AccordionWrapper>
                    <Accordion>
                        <input type="checkbox" id="section1" className="accordion__input" />
                        <label htmlFor="section1" className="accordion__label">{(brandId == 39 || brandId == 40) ? <h1>Dragon Tiger Online Casino Game on {domain} Live Casino</h1> : <h1>Play Dragon Tiger Game</h1>}</label>
                        {(brandId == 39 || brandId == 40) ? <DragontigerSEO39 /> : (
                            <div className="accordion__content">
                                <h1>Dragon Tiger Game</h1>
                                <p>Dragon Tiger Game is a two-card version of Baccarat. Only two cards are drawn in this game, one for the Dragon and the other for the Tiger. The players place a bet on the possibility of a higher card, and there are other proposition bets as well. The Dragon Tiger Game is quite popular in Asian countries mainly because of its name. The game is so quick that it usually only takes around 25 seconds to finish a round, including 15 seconds for placing bets and five seconds taken by the dealer to reveal the cards.</p>

                                <h2>Dragon vs Tiger Game</h2>
                                <p>Dragon vs Tiger Game, as the name suggests, is all about these two beasts: Dragon & Tiger. This game has a relatively low house edge as the results could either be Tiger win, Dragon win, or a tie. Although this game looks like Baccarat, it has a close resemblance to Casino War.</p>

                                <h2>Variations Available for Dragon Tiger Online</h2>
                                <p>The Dragon Tiger Online is well-known in online casinos across the world. Dragon Game has won the hearts of many players because of the winning probabilities. The live Dragon Tiger game tables are quite popular in online casinos. Game software providers such as Ezugi, Superspade, Supernowa, Spribe, Betgames, and Netent are known for their unmatchable variations of the classic casino games. Even though these software developers use the same game rules for the player’s convenience, all the Dragon Tiger game tables are unique, with different designs and graphics that allow players to choose from diverse experiences.</p>

                                <h2>Tips & Strategies to Win</h2>
                                <p>Though The Game Seems So Much Simple, It's Hard To Find A Winning Strategy. The Chances Of Winning Are Random And Based On Pure Luck. However, You Can Try A Few Things To Increase Your Chances Of Ensuring More Wins.</p>

                                <h3>Bankroll Management Strategy</h3>
                                <p>To keep playing the Dragon Tiger Game for longer, players should devise a bankroll management strategy. This means placing small bets and only betting the money you can stand to lose. These strategies will ensure you get more out of your bankroll.</p>

                                <h3>Reduce Tries & Reduce Losses</h3>
                                <p>Never overdo it when you are losing. Since the game moves quite rapidly, it's always smart to play only a few games to minimize losses.</p>

                                <h3>Use Martingale or Other Strategies</h3>
                                <p>There are other tips that have mixed results: sticking to one side of a Dragon or Tiger, avoiding betting on a tie, checking the winning trend, and avoiding a pattern while betting could prove profitable for players.</p>

                                <h2>What Makes {domain} the Best Site to Play the Dragon Tiger Game?</h2>
                                <p>There are various reasons that make {domain} the best site to play Dragon vs Tiger Game. Here are some of the top reasons:</p>

                                <h3>Easy Interface</h3>
                                <p>{domain} offers a live Dragon Tiger Game with a user-friendly interface. There is no chance for users to get confused with the flow or process.</p>

                                <h3>24x7 Customer Support</h3>
                                <p>Our customer support is available 24/7, 365 days a year, and we have a valid gaming licence.</p>

                                <h3>Rewarding Experience</h3>
                                <p>We offer a 400% welcome bonus to all new players. You can also enjoy other casino bonuses like loyalty programs and special events. For existing players, there are a variety of referral, cashback, and refill bonuses to select from. {domain} is a great online casino choice for Indian players!</p>

                                <h2>How to Play Dragon vs Tiger Game?</h2>
                                <p>The Dragon Vs Tiger Game Is Played With Just 2 Cards: Dragon And Tiger. Go Through What It Feels Like To Play The Dragon Vs Tiger Game.</p>

                                { (brandId === 31) ? 
                                    <ul>
                                        <li>The Players Have To Choose The Card With The Highest Ranking.</li>
                                        <li>They Can Also Bet On The Possibility Of A Tie.</li>
                                        <li>Here The Players Do Not Compete Against The Dealer But With Other Players.</li>
                                        <li>The Table Is Covered With A Green Rug, Which Is Imitated Impeccably In Online Live Games.</li>
                                        <li>There Are Two Squares On The Table, One For The Dragon And One For The Tiger.</li>
                                        <li>The Game Background Always Has Two Statues In Gold And Glass, Showing Dragons With Each On Either Side.</li>
                                        <li>The Rules Are Really Simple That Even New Players Can Master The Game Within Minutes. The Dragon Vs Tiger Game Is Played With 6 Decks Of Cards Without The Joker Cards.</li>
                                        <li>Ace Has The Lowest Value Of 1, And The King Is The Highest Card.</li>
                                        <li>Cards From 2 To 10 Are Worth Their Card Values With J Being 11, Q Equal To 12, And K Is Worth 13 Points.</li>
                                        <li>The Players Also Have Other Side Betting Options, Which Make Up Around 4 Bets.</li>
                                    </ul> : 
                                    <ul>
                                        <li>The players have to choose the card with the highest ranking.</li>
                                        <li>They can also bet on the possibility of a tie.</li>
                                        <li>Here, the players do not compete against the dealer but with other players.</li>
                                        <li>The table is covered with a green rug, which is imitated impeccably in online live games.</li>
                                        <li>There are two squares on the table, one for the Dragon and one for the Tiger.</li>
                                        <li>The game background always has two statues in gold and glass, showing dragons with each on either side.</li>
                                    </ul>
                                }

                                {(brandId !== 31) && <p>The rules are really simple that even new players can master the game within minutes. The Dragon vs Tiger Game is played with 6 decks of cards without the joker cards. Ace has the lowest value of 1, and the King is the highest card. Cards from 2 to 10 are worth their card values, with J being 11, Q equal to 12, and K worth 13 points. Players also have other side betting options, which make up around 4 bets.</p>}

                                <h2>Where to Start?</h2>
                                <p>To play the Dragon Tiger Game for real cash, a player must register on {domain}. This process to open a casino account requires a few basic details. Once confirmed, players can add funds through their preferred payment gateway and get a welcome bonus. Choose the game that suits your needs and budget. Try the demo version if you are confused. The best Dragon vs Tiger Game is now available for betting with real money. To earn more profits, follow the tips and strategies. The app is now available to download.</p>

                                <h2>Interesting Facts to Kickstart Your Journey</h2>
                                <p>While we are here, let’s understand the game even better through these fun and interesting facts:</p>

                                <ul>
                                    <li>The rules in online and land-based casinos are the same.</li>
                                    <li>There aren’t any winning strategies for the Dragon Tiger Game.</li>
                                    <li>Dragon vs Tiger is known to be the easiest casino game.</li>
                                    <li>It is believed to be the oldest game of chance worldwide.</li>
                                    <li>The game first originated in the Chinese gambling houses of Macau.</li>
                                </ul>

                            </div>
                        )}
                    </Accordion>
                </AccordionWrapper>
            }
        </>
    )
}

export default withAppContext(DragontigerSEO)