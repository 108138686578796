 const seoData = {
        "31": {
            "default": {
                "title": "Yolo247 | Online Betting in India for Sports & Casino",
                "description": "Join the best site for online betting in India, Yolo247! Explore all sports betting & casino games on the safest site for real money online gambling. ",
                "canonical": "https://yolo247.site",
                "alternate": "https://m.yolo247.site",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "onlineCasinoGames": {
                "title": "Online Casino Games | Live Casino Online Games on Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical": "https://yolo247.site/online-casino-games",
                "alternate": "https://m.yolo247.site/online-casino-games",
                "keywords": " Online Casino Games, Live Casino Online, Play Casino games online"
            },
            "live-baccarat": {
                "title": "Play Baccarat Online for FREE or Real Money | Yolo247",
                "description": "Play Baccarat Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Baccarat Live now!",
                "canonical": "https://www.yolo247.site/live-casino/live-baccarat",
                "alternate": "https://m.yolo247.site/live-casino/live-baccarat",
                "keywords": "live-baccarat, Live-Baccarat, Live Baccarat, livebaccarat, Livebaccarat, Baccarat Online Game, Baccarat Live, Baccarat"
            },    
            "live-blackjack": {
                "title": "Play Blackjack Online Casino Game | 100% Safe & Fast Payouts",
                "description": "Play Blackjack online game on the best casino platform. Safest & fastest payouts guaranteed on Yolo247 for the Blackjack game. Play Blackjack card game now!",
                "canonical": "https://yolo247.site/online-casino-games/live-blackjack",
                "alternate": "https://m.yolo247.site/online-casino-games/live-blackjack",
                "keywords": "blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "roulette": {
                "title": "Play Roulette Game Online - Free Registration & Instant Payouts",
                "description": "Play Roulette Online for real money on Yolo247. Get all betting options, like inside & outside, with better payouts than ever before. Bet on Roulette online. ",
                "canonical": "https://yolo247.site/online-casino-games/live-roulette",
                "alternate": "https://m.yolo247.site/online-casino-games/live-roulette",
                "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "lightningroulette": {
                "title": "Play Lightning Roulette Online at Yolo247 | Thrilling Gameplay",
                "description": "Lightning roulette has become one of the best online casino games on Yolo247. Play Online lightning roulette for real money with higher payouts & all betting features.",
                "canonical": "https://yolo247.site/online-casino-games/lightning-roulette",
                "alternate": "https://m.yolo247.site/online-casino-games/lightning-roulette",
                "keywords": "lightningroulette game,lightningroulette online,casino lightningroulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "dragonTiger": {
                "title": "Online Dragon Tiger Game - Get Instant Wins on Yolo247",
                "description": "Play Dragon Tiger Game Online on the best casino platform, Yolo247. Fast, safe & simple. Get the best Dragon Tiger Casino betting experience in India.",
                "canonical": "https://yolo247.site/online-casino-games/dragon-tiger",
                "alternate": "https://m.yolo247.site/online-casino-games/dragon-tiger",
                "keywords": "Yolo247 is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
            },
            "baccarat": {
                "title": "Play Baccarat Online for FREE or Real Money - Yolo247",
                "description": "Play Baccarat Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Baccarat Live now!",
                "canonical": "https://yolo247.site/online-casino-games/live-baccarat",
                "alternate": "https://m.yolo247.site/online-casino-games/live-baccarat",
                "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
            },
            "andarBahar": {
                "title": "Play Andar Bahar Online for FREE or Real Money - Yolo247",
                "description": "Play Andar Bahar Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Andar Bahar Live now!",
                "canonical": "https://yolo247.site/online-casino-games/andar-bahar",
                "alternate": "https://m.yolo247.site/online-casino-games/andar-bahar",
                "keywords": "Andar Bahar game,Andar Bahar online,Andar Bahar casino,Andar Bahar gaming,Andar Bahar rule,Andar Bahar game online,Andar Bahar game rules,play Andar Bahar online,Andar Bahar online play,Andar Bahar online free,best Andar Bahar online casino,best casino for Andar Bahar"
            },
            "dreamCatcher": {
                "title": "Play Dream Catcher Online for FREE or Real Money - Yolo247",
                "description": "Play Dream Catcher Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Dream Catcher Live now!",
                "canonical": "https://yolo247.site/online-casino-games/dream-catcher",
                "alternate": "https://m.yolo247.site/online-casino-games/dream-catcher",
                "keywords": "Dream Catcher game,Dream Catcher online,Dream Catcher casino,Dream Catcher gaming,Dream Catcher rule,Dream Catcher game online,Dream Catcher game rules,play Dream Catcher online,Dream Catcher online play,Dream Catcher online free,best Dream Catcher online casino,best casino for Dream Catcher"
            },
            "hilo": {
                "title": "Play Hilo Online for FREE or Real Money - Yolo247",
                "description": "Play Hilo Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Hilo Live now!",
                "canonical": "https://yolo247.site/online-casino-games/hi-lo",
                "alternate": "https://m.yolo247.site/online-casino-games/hi-lo",
                "keywords": "Hilo game,Hilo online,Hilo casino,Hilo gaming,Hilo rule,Hilo game online,Hilo game rules,play Hilo online,Hilo online play,Hilo online free,best Hilo online casino,best casino for Hilo"
            },
            "teenpattiOnlinePlay": {
                "title": "Online TeenPatti Game for Real Cash | Start Playing Now!",
                "description": "Online TeenPatti is a thrilling classic card game. Play 3 patti online on Yolo247 and earn real cash. Betting on teen patti game made easy!",
                "canonical": "https://yolo247.site/teenpatti-online-play",
                "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
            },
            "sportsBetting": {
                "title": "Best Sports Betting Site in India - Yolo247 Betting Exchange",
                "description": "Betting Exchange on Yolo247 offers all the gambling options. Place bets on any sport through one of the best betting exchange sites in India. Bet with INR.",
                "canonical": "https://yolo247.site/betting-exchange",
                "alternate": "https://m.yolo247.site/betting-exchange?id=0",
                "keywords": "betting exchange"
            },
            "onlineCricketSattaBazar": {
                "title": "Online Cricket Betting | Best Cricket Satta Bazar in India",
                "description": "Online cricket betting on Yolo247 provides the best betting odds for cricket in India. Get livescore, all betting options & fast withdrawal. Bet on cricket now!",
                "keywords": " Online Cricket Betting Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Yolo247 online cricket betting, Yolo247 cricket betting odds, Yolo247 cricket betting, cricket betting sites, online cricket satta bazar,",
                "canonical": "https://yolo247.site/betting-exchange/online-cricket-betting",
                "alternate": "https://m.yolo247.site/betting-exchange/online-cricket-betting",
            },
            "footballBetting": {
                "title": "Football Betting Online - Predict & Win Real Money on yolo247",
                "description": "Football betting on Yolo247 provides the best odds & faster payouts. Enrich your online football betting experience. Bet on football matches now with INR. ",
                "canonical": "https://yolo247.site/betting-exchange/football-betting",
                "alternate": "https://m.yolo247.site/betting-exchange/football-betting",
                "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
            },
            "tennisBetting": {
                "title": "Tennis Betting in India | Bet on Tennis Matches Online",
                "description": "Tennis betting offers an opportunity to bet on tennis matches online. Get the best tennis odds on Yolo247. Available for all tournaments with better payouts. ",
                "canonical": "https://yolo247.site/betting-exchange/tennis-betting",
                "alternate": "https://m.yolo247.site/betting-exchange/tennis-betting",
                "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "volleyballBetting": {
                "title": "Volleyball Betting in India | Bet on Volleyball Matches Online",
                "description": "Volleyball betting is a popular form of sports wagering that allows betting on Volleyball matches online. Get the best volleyball odds on Yolo247.",
                "canonical": "https://yolo247.site/betting-exchange/volleyball-betting",
                "alternate": "https://m.yolo247.site/betting-exchange/volleyball-betting",
                "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
            },
            "promotions": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://yolo247.site/promotions",
                "alternate": "https://m.yolo247.site/promotions",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "specialMarket": {
                "title": "Special Markets on Yolo 247",
                "description": "Special Markets on Yolo 247",
                "canonical" : "https://yolo247.site/specialMarket",
                "alternate": "https://m.yolo247.site/specialMarket",
            },
            "virtualSports": {
                "title": "Virtual Cricket Betting in India - Play at Yolo247 & Win Big Rewards",
                "description": "Virtual cricket betting on Yolo247 enables you to bet on your favourite cricket tournaments and matches in a simulated reality. Bet on virtual cricket leagues.",
                "canonical" : "https://yolo247.site/virtualSports/vci",
                "alternate": "https://m.yolo247.site/virtualSports/vci",
            },
            "virtualSportsBL": {
                "title": "Basketball Betting | Virtual Basketball Game on Yolo247",
                "description": "Basketball betting reaches new heights on Yolo247. Elevate your betting game and learn about the thrill of virtual basketball betting.",
                "canonical" : "https://yolo247.site/virtualSports/vbl",
                "alternate": "https://m.yolo247.site/virtualSports/vbl",
            },
            "virtualSportsFB": {
                "title": "Virtual Bundesliga Betting | Yolo247 Virtual Football Betting",
                "description": "Virtual Bundesliga betting is trendiest on Yolo247. Bet on Virtual Bundesliga matches and unleash your passion for football betting.",
                "canonical" : "https://yolo247.site/virtualSports/vfb",
                "alternate": "https://m.yolo247.site/virtualSports/vfb",
            },
            "virtualSportsFEL": {
                "title": "Virtual EPL Betting: English Premier League Betting on Yolo247",
                "description": "EPL betting offers excitement and a plunge into the virtual world of football. Learn more about the English Premier League betting on Yolo247. ",
                "canonical" : "https://yolo247.site/virtualSports/vfel",
                "alternate": "https://m.yolo247.site/virtualSports/vfel",
            },
            "virtualSportsFEC": {
                "title": "European Cup Betting And Virtual Sports | Big Rewards on Yolo247",
                "description": "European Cup betting is based on the Euro League. Get the best virtual betting odds for the Euro Cup on Yolo247. Find out more on live Euro Cup betting",
                "canonical" : "https://yolo247.site/virtualSports/vfec",
                "alternate": "https://m.yolo247.site/virtualSports/vfec",
            },
            "virtualSportsVTI": {
                "title": "Virtual Tennis Betting - Play, Win Real Money Online",
                "description": "Virtual Tennis Betting allows you to bet on tennis matches in a simulated reality. Get the thrill of online betting through tennis virtual betting in India. ",
                "canonical" : "https://yolo247.site/virtualSports/vti",
                "alternate": "https://yolo247.site/virtualSports/vti",
            },
            "virtualSportsWC": {
                "title": "Virtual World Cup Betting | How to Make Informed Bets?",
                "description": "Virtual World Cup Betting helps you immerse yourself in a virtual football universe. Bet on your favourite teams and win big on World Cup betting.",
                "canonical" : "https://yolo247.site/virtualSports/vfwc",
                "alternate": "https://yolo247.site/virtualSports/vfwc",
            },
            "slotGames": {
                "title": "Casino Slot Games | Play Slot Games for Real Money on Yolo247",
                "description": "Casino slot games are high on entertainment. Play slot games for real money on Yolo247 and earn exciting rewards.",
                "canonical": "https://yolo247.site/online-casino-games/slotGames",
                "alternate": "https://m.yolo247.site/online-casino-games/slotGames",
                "keywords": "slot games for real money, casino slot games"
            },
            "aviator": {
                "title": "Play Aviator Game Online | Win Real Money on Spribe Aviator",
                "description": "Play Spribe's Aviator Game Online on Yolo247! Enjoy seamless INR betting and win real money at India's top Aviator betting site.",
                "canonical": "https://yolo247.site/instant-games/aviator",
                "alternate": "https://m.yolo247.site/instant-games/aviator",
                "keywords": "Aviator game, Aviator online game "
            },
            "cashshow": {
                "title": "Cash Show Game | Live Casino Online Games on Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical" : "https://yolo247.site/instant-games/cashshow",
                "alternate": "https://m.yolo247.site/instant-games/cashshow",
                "keywords" : "Cash Show game, Cash Show online game "
            },
            "jetx": {
                "title": "Play JetX Game Online at Yolo247 - Win Real Money Online",
                "description": "Jetx Online Game for Real Money is now available on Yolo247! Play Jetx Game now & win exciting rewards. Instant withdrawal & all the betting options. ",
                "canonical" : "https://www.yolo247.site/instant-games/jetx",
                "alternate": "https://m.yolo247.site/instant-games/jetx",
                "keywords" : "Jet X game, Jet X online game "
            },
            "rockpaperscissors": {
                "title": "Play Rock Paper Sissors Online at Yolo247 - Earn Big Rewards",
                "description": "Rock Paper Scissors online casino game is now available on Yolo247. The online version is upgraded and enhanced to offer faster gameplay and smoother interface. ",
                "canonical" : "https://yolo247.site/instant-games/rock-paper-scissors",
                "alternate": "https://yolo247.site/instant-games/rock-paper-scissors",
                "keywords" : "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
            },
            "plinkox": {
                "title": "Play PlinkoX casino games- Top Instant Games on yolo247",
                "description": "Play Plinkox Casino game online on Yolo247. PlinkoX online game provides higher payout and smoother gameplay. Play for real money online. Get the best experience. ",
                "canonical" : "https://www.yolo247.site/instant-games/plinko-x",
                "alternate": "https://m.yolo247.site/instant-games/plinko-x",
                "keywords" : "Plinkox game, Plinkox online game "
            },
            "footballx": {
                "title": "Play FootballX Crash Game Online - Play Instant Game",
                "description": "Footballx casino game offers quality gameplay among all instant games. Play online footballx game for real money. Get 24x7 customer support and faster withdrawal.",
                "canonical" : "https://www.yolo247.site/instant-games/football-x",
                "alternate": "https://m.yolo247.site/instant-games/football-x",
                "keywords" : "Football X game, Football X online game "
            },
            "balloon": {
                "title": "Balloon crash games- Play for real money",
                "description": "Balloon casino game is one of the popular online instant games on Yolo247. Play Balloon for real money & win exciting rewards instantly. ",
                "canonical" : "https://www.yolo247.site/instant-games/balloon",
                "alternate": "https://m.yolo247.site/instant-games/balloon",
                "keywords" : "Balloon game, Balloon online game "
            },
            "mines": {
                "title": "Mines Game Online- Play Instant Game for real cash",
                "description": "Mines game online on Yolo247 is popular among online instant games. Simple rules and faster gameplay are the reason for its popularity. Play Mines casino game now!",
                "canonical" : "https://www.yolo247.site/instant-games/mines",
                "alternate": "https://m.yolo247.site/instant-games/mines",
                "keywords" : "Mines game, Online mines game"
            },
            "SPRIBE": {
                "title": "Spribe Casino Games | Get the best Spribe Games on Yolo247",
                "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
                "canonical" : "https://yolo247.site/instant-games/spribe",
                "alternate": "https://m.yolo247.site/instant-games/spribe",
                "keywords" : "spribe casino games"
            },
            "aviatrix": {
                "title": "Aviatrix Game: Play at Yolo247 & Win Real Money Online",
                "description": "Elevate your gaming with Aviatrix on Yolo247! Soar through the clouds and start your winning journey. Play Aviatrix game now for an unforgettable experience!",
                "canonical" : "https://yolo247.site/instant-games/aviatrix",
                "alternate": "https://m.yolo247.site/instant-games/aviatrix",
                "keywords" : "Aviatrix casino games"
            },
            "instantGames": {
                "title": "Instant Games | Play Best Instant Casino Online on Yolo247",
                "description": "Instant Games on Yolo247 are always on demand. Play all your favourite instant casino games online on your favourite betting platform.",
                "canonical" : "https://yolo247.site/instant-games",
                "alternate": "https://m.yolo247.site/instant-games",
                "keywords" : "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "spribe": {
                "title": "Spribe Games | Play The Best Yolo247 Spribe Games",
                "description": "Spribe games use the latest technology to build innovative and immersive live casino games for users. Find out how to experience thrilling Yolo247 Spribe Games.",
                "canonical" : "https://yolo247.site/instant-games/spribe",
                "alternate": "https://m.yolo247.site/instant-games/spribe",
                "keywords" : "Spribe Games,Yolo247 Spribe games,Yolo Spribe games,Spribe live casino games"
            },
            "cricketx": {
                "title": "Play CricketX Game Online - Play Instant Game for Real Cash",
                "description": "Cricketx casino game offers quality gameplay among all instant games. Play online Cricketx game for real money. Best combination of sports & casino!",
                "canonical" : "https://yolo247.site/instant-games/cricket-x",
                "alternate": "https://m.yolo247.site/instant-games/cricket-x",
                "keywords" : "Cricketx Games,Yolo247 Cricketx games,Yolo Cricketx games,Cricketx casino games"
           },
           "ninjacrash": {
                "title": "Play NinjaCrash Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/ninja-crash",
                "alternate": "https://m.yolo247.site/instant-games/ninja-crash",
                "keywords" : "NinjaCrash game, Online NinjaCrash game"
            },
            "rocketon": {
                "title": "Play Rocketon Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Rocketon! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/rocketon",
                "alternate": "https://m.yolo247.site/instant-games/rocketon",
                "keywords" : "Rocketon game, Online Rocketon game"
            },
            "burningIce": {
                "title": "Play BurningIce Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in BurningIce! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/burning-ice",
                "alternate": "https://m.yolo247.site/instant-games/burning-ice",
                "keywords" : "BurningIce game, Online BurningIce game"
            },
            "cappaDocia": {
                "title": "Play Cappa Docia Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Cappa Docia! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/cappa-docia",
                "alternate": "https://m.yolo247.site/instant-games/cappa-docia",
                "keywords" : "Cappa Docia game, Online Cappa Docia game"
            },
            "geniesBonanza": {
                "title": "Play GeniesBonanza Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in GeniesBonanza! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/genies-bonanza",
                "alternate": "https://m.yolo247.site/instant-games/genies-bonanza",
                "keywords" : "GeniesBonanza game, Online GeniesBonanza game"
            },
            "goal": {
                "title": "Play Goal Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Goal! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/goal",
                "alternate": "https://m.yolo247.site/instant-games/goal",
                "keywords" : "Goal game, Online Goal game"
            },
            "hunterx": {
                "title": "Play Hunterx Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Hunterx! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/hunter-x",
                "alternate": "https://m.yolo247.site/instant-games/hunter-x",
                "keywords" : "Hunterx game, Online Hunterx game"
            },
            "jungles": {
                "title": "Play Jungles Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Jungles! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/jungles",
                "alternate": "https://m.yolo247.site/instant-games/jungles",
                "keywords" : "Jungles game, Online Jungles game"
            },
            "magicGarden": {
                "title": "Play Magic Garden Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Magic Garden! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/magic-garden",
                "alternate": "https://m.yolo247.site/instant-games/magic-garden",
                "keywords" : "Magic Garden game, Online Magic Garden game"
            },
            "multiHotWays": {
                "title": "Play Multi Hot Ways Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Multi Hot Ways! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/multi-hot-ways",
                "alternate": "https://m.yolo247.site/instant-games/multi-hot-ways",
                "keywords" : "Multi Hot Ways game, Online Multi Hot Ways game"
            },
            "paperPlane": {
                "title": "Play Paper Plane Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Paper Plane! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/paper-plane",
                "alternate": "https://m.yolo247.site/instant-games/paper-plane",
                "keywords" : "Paper Plane game, Online Paper Plane game"
            },
            "stockMarket": {
                "title": "Play Stock Market Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Stock Market! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.site/instant-games/stock-market",
                "alternate": "https://m.yolo247.site/instant-games/stock-market",
                "keywords" : "Stock Market game, Online Stock Market game"
            },
            "macludo": {
                "title": "Macludo Casino Games Online - Play & Win 24/7",
                "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "canonical" : "https://yolo247.site/online-casino-games/macludo",
                "alternate": "https://m.yolo247.site/online-casino-games/macludo",
                "keywords" : "Ludo Game, Ludo game Online"
            },
            "crazytime": {
                "title": "Play Crazy Time game online For Real money on yolo247 ",
                "description": "Crazy Time casino game is the best wheel-based casino game on Yolo247. Play Crazy time game online, go crazy with higher winnings and faster game process.  ",
                "canonical" : "https://www.yolo247.site/online-casino-games/crazy-time",
                "alternate" : "https://m.yolo247.site/online-casino-games/crazy-time",
                "keywords" : "Crazytime Game, Crazytime game Online"
            },
            "welcomeBonus": {
                "title": "Betting Promotions at Yolo247 | Best bonuses & offers in India",
                "description": "Betting Promotions tailor-made for you! Get cash back, refill & referral bonuses. Exciting betting offers. Grab the best deals for your next bet. ",
                "canonical" : "https://yolo247.site/promotions/welcome-bonus",
                "alternate": "https://m.yolo247.site/promotions/welcome-bonus",
                "keywords" : "Welcome Bonus"
            },
            "premiumSportsbook": {
                "title": "Premium Sportsbook Betting | Best Online Sportsbook In India",
                "description": " Premium Sportsbook Betting on Yolo247 brings in various options to bet on. Get the best odds for all sports & enjoy the best sports betting experience",
                "canonical" : "https://yolo247.site/premium-sportsbook",
                "alternate": "https://m.yolo247.site/premium-sportsbook",
                "keywords" : "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
            },
            "refillBonus": {
                "title": "Refill bonus | Best Casino Refill Bonus on Yolo247",
                "description": "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
                "canonical" : "https://yolo247.site/promotions/refill-bonus",
                "alternate": "https://m.yolo247.site/promotions/refill-bonus",
                "keywords" : "Refill bonus"
            },
            "cashbackBonus": {
                "title": "Cashback Bonus | Best Cashback Casino Bonus on Yolo247",
                "description": "Cashback Bonus for all top games & bets! Claim your cashback casino bonus. Better Offers with Bigger Opportunities! Grab the deal now.",
                "canonical" : "https://yolo247.site/promotions/cashback-bonus",
                "alternate": "https://m.yolo247.site/promotions/cashback-bonus",
                "keywords" : "Cashback Bonus"
            },
            "referralBonus": {
                "title": "Yolo247 Referral Bonus | Get Your Yolo247 Referral Code",
                "description": "Yolo247 Referral Bonus is open for old & new users now. Get Yolo247 Referral Code today. Referral codes are available on site now.",
                "canonical" : "https://yolo247.site/promotions/referral-bonus",
                "alternate": "https://m.yolo247.site/promotions/referral-bonus",
                "keywords" : "Referral Bonus"
            },
            "termsandconditions": {
                "title": "Terms & Conditions | Yolo247 Online Betting T&C",
                "description": "Terms & Conditions of Yolo247 updated! Check the obligations, terms of use, registration details, rules of play, deposit & withdrawal policies and more.",
                "canonical" : "https://yolo247.site/terms-conditions",
                "alternate": "https://m.yolo247.site/about/terms-conditions",
                "keywords" : "Terms & Conditions"
            },
            "responsibleGambling": {
                "title": "Yolo247 & Responsible Gambling | Best Online Betting Site in India",
                "description": "Responsible Gambling is highly insisted. At Yolo247, we educate, insist and highly recommend to be aware of the negative impacts of gambling.",
                "canonical" : "https://yolo247.site/responsible-gambling",
                "alternate": "https://m.yolo247.site/about/responsible-gambling",
                "keywords" : "Responsible Gambling"
            },
            "privacyPolicy": {
                "title": "Yolo247 Privacy Policy | Best Online Betting Site in India",
                "description": "Privacy Policy reading is always integral in the process of betting. Go through definitions and types of data collected. Click to know more!",
                "canonical" : "https://yolo247.site/privacy-policy",
                "alternate": "https://m.yolo247.site/about/privacy-policy",
                "keywords" : "privacy policy"
            },
            "kycPolicy": {
                "title": "KYC Policy of Yolo247 | Get to know the KYC requirements",
                "description": "KYC Policy at Yolo247 is clear and transparent. Go through the guidelines, documents required, transaction limits and much more.",
                "canonical" : "https://yolo247.site/kyc-policy",
                "alternate": "https://m.yolo247.site/about/kyc-policy",
                "keywords" : "KYC Policy"
            },
            "exclusionPolicy": {
                "title": "Exclusion Policy  | Self-Exclusion Policy of Yolo247",
                "description": "Exclusion Policy (self-exclusion) is a facility that the site offers to help customers who feel that their Gambling is out of ",
                "canonical" : "https://yolo247.site/exclusion-policy",
                "alternate": "https://m.yolo247.site/about/self-exclusion-policy",
                "keywords" : "exclusion policy "
            },
            "amlPolicy": {
                "title": "AML Policy | Anti Money Laundering Policy of Yolo247",
                "description": "AML policy, also known as Anti-Money Laundering Policy, is actively adhered to by Yolo247. Your safety is our priority. Read more!",
                "canonical" : "https://yolo247.site/aml-policy",
                "alternate": "https://m.yolo247.site/about/aml-policy",
                "keywords" : "AML Policy"
            },
            "aboutus": {
                "title": "About Yolo247 | Your Ultimate Destination for Online Gaming",
                "description": "Yolo247 is an online casino & sports betting platform offering a seamless & exciting gaming experience. Find out more on India's top gaming platform - Yolo247.",
                "canonical" : "https://yolo247.site/about-us",
                "alternate": "https://m.yolo247.site/about/about-us",
                "keywords" : "About us, About Yolo247"
            },
            "7MOJOS": {
                "title": "7Mojos Games | Explore the top games at the best platform",
                "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
                "canonical" : "https://yolo247.site/online-casino-games/7mojos",
                "alternate": "https://m.yolo247.site/online-casino-games/7mojos",
                "keywords" : "7Mojos Games"
            },
            "supernowa": {
                "title": "Play Supernowa Casino Games on Yolo247",
                "description": " Supernowa casino games are one of the best in business. Check out Supernowa gaming and see the games offered on Yolo247.",
                "canonical" : "https://yolo247.site/online-casino-games/supernowa",
                "alternate": "https://m.yolo247.site/online-casino-games/supernowa",
                "keywords" : "Supernowa Casino Games,Supernowa gaming, Supernowa casino"
            },
            "pragmatic": {
                "title": "Pragmatic Casino Game on Yolo247 | Get 400% Welcome Bonus",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "canonical" : "https://yolo247.site/online-casino-games/slotGames/pragmatic-play",
                "alternate": "https://m.yolo247.site/online-casino-games/slotGames/pragmatic-play",
                "keywords" : "Pragmatic casino, pragmatic games"
            },
            "superspade": {
                "title": "Top Super Spade Games On Yolo247 | Play Now!",
                "description": " Super Spade games are popular because of exciting features, gameplay & payouts. Play Super Spade casino games on Yolo247 and win big. Join now!",
                "canonical" : "https://yolo247.site/online-casino-games/super-spade",
                "alternate": "https://m.yolo247.site/online-casino-games/super-spade",
                "keywords" : "super spade games,play super spade casino games, super spade casino"
            },
            "tvbet": {
                "title": "Play TVBET Casino Games Online | Real Money Games",
                "description": "TVBET casino games provide engaging games. A wide variety of games await online casino gaming enthusiasts. Find out more about Yolo247 TVBET Games.",
                "canonical" : "https://yolo247.site/online-casino-games/tvbet",
                "alternate": "https://m.yolo247.site/online-casino-games/tvbet",
                "keywords" : "TVBET casino games, Yolo247 TVBET games"
            },
            "netent": {
                "title": "Netent Casino Game on Yolo247 | Get Upto 400% Bonus",
                "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
                "canonical" : "https://yolo247.site/online-casino-games/slotGames/netent",
                "alternate": "https://m.yolo247.site/online-casino-games/slotGames/netent",
                "keywords" : "netent games"
            },
            "betgames": {
                "title": "BetGames On Yolo247 | Play BetGames & Win Big",
                "description": "BetGames is one of the modern-day game providers in Yolo247. Several casino gaming enthusiasts love the games. Here's why.BetGames is another top live dealer casino game provider that is available on Yolo247 from the comfort of your home. The best part of BetGames is that instead of promoting state-of-the-art technology, they have made their gaming solutions much more accessible to new gamers who want to explore the gaming industry at their own pace.Over the years, the live casino has been the hot trend in the gaming industry, and BetGames, too, has tapped into this market, creating some of the most awe-inspiring live dealer games. Most of these games make you feel like you are in a physical casino.",
                "canonical" : "https://yolo247.site/online-casino-games/bet-games",
                "alternate": "https://m.yolo247.site/online-casino-games/bet-games",
                "keywords" : "betgames,Yolo247 BetGames"
            },
            "sportsbook": {
                "title": "Sportsbook Betting | Best Online Sportsbook In India",
                "description": "Sportsbook Betting on Yolo247 brings in various options to bet on. Get the best odds for all sports & enjoy the best sports betting experience in India. ",
                "canonical" : "https://yolo247.site/sportsbook",
                "alternate": "https://m.yolo247.site/sportsbook",
                "keywords" : "Sportsbook Betting,Best Online Sportsbook, Sports Betting in India"
            },
            "appDownload": {
                "title": "Yolo247 App Download | Free Download for Android",
                "description": "Download Yolo247 App to get the best casino and sports betting experience. Go for real-money gambling & get the best betting app in India now!",
                "canonical" : "https://yolo247.site/app-download",
                "alternate": "https://m.yolo247.site/app-download",
                "keywords" : "Yolo App Download, Yolo Android App"
            },
            "signup": {
                "title": "Sign Up  On Yolo247 - Best Online Betting Site in India",
                "description": "Yolo247 sign up gives you an option to play online casino games and to bet on any sports. Registration is faster than ever before! Register with Yolo247 now!",
                "canonical" : "https://yolo247.site/signup",
                "alternate": "https://m.yolo247.site/signup",
                "keywords" : "Yolo User Registration"
            },
            "faq": {
                "title": "Yolo247 FAQ - Know All About Bonus, Sign up, Withdrawal ",
                "description": "Go through all the Frequently Asked Questions about online betting on Yolo247! Find all your questions answered on Yolo247 FAQ section. ",
                "canonical" : "https://yolo247.site/faq",
                "alternate": "https://m.yolo247.site/faq",
            },
            "affiliate": {
                "title": "Yolo247 Affiliate - Best Online Betting Site in India",
                "description": "Affiliate Page",
                "canonical" : "https://yolo247.site/affiliate",
                "alternate": "https://m.yolo247.site/affiliate",
            },
            "loyalty": {
                "title": "Yolo247 Loyalty Program  - Best Online Betting Site in India",
                "description": "Loyalty Page",
                "canonical" : "https://yolo247.site/loyalty",
                "alternate": "https://m.yolo247.site/loyalty",
            },
            "login": {
                "title": "Log in to Yolo247 Official site for Sports & Casino",
                "description": "Yolo247 Login is easy and fast. Login to your Yolo247 account online and explore all the casino games and sports betting options. ",
                "canonical" : "https://yolo247.site/login",
                "alternate": "https://m.yolo247.site/login",
                "keywords" : "Yolo Login"
            },
       
            "majorLeagueCricketBetting": {
                "title": "MLC Betting in India | Major League Cricket Betting Online",
                "description": "MLC Betting is available on Yolo247! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
                "canonical" : "https://yolo247.site/sports/tournaments/major-league-cricket-betting",
                "alternate": "https://m.yolo247.site/sports/tournaments/major-league-cricket-betting",
                "keywords" : "Major League Cricket Betting Online, MLC Betting in India"
            },
            "dailyDepositBonus": {
                "title": "Refill bonus | Best Casino Refill Bonus on Yolo247",
                "description": "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
                "canonical" : "https://yolo247.site/daily-deposit-bonus",
                "alternate": "https://m.yolo247.site/daily-deposit-bonus",
                "keywords" : "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "popular games": {
                "title": "Popular Casino Games | Online Casino Real Money on Yolo247",
                "description": "Best casino games on Yolo247 provide the best online gambling experience. Choose any of the top casino games & play online casino real money. Get free demo!",
                "canonical" : "https://yolo247.site/online-casino-games/best-casino-games",
                "alternate": "https://m.yolo247.site/online-casino-games/best-casino-games",
                "keywords" : "Best Casino Games Popular Casino Games, online casino real money"
            },
            "evolution": {
                "title": "Play Evolution Gaming Casino | Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on Yolo247",
                "canonical" : "https://yolo247.site/online-casino-games/evolution-gaming",
                "alternate": "https://m.yolo247.site/online-casino-games/evolution-gaming",
                "keywords" : "Evolution Gaming, Evolution Gaming Casino"
            },
            "ezugi": {
                "title": "Ezugi Live Casino | Play Yolo247 Ezugi Games",
                "description": " Ezugi's state-of-the-art live casino games are a massive hit among fans. Let us do a deep dive into Ezugi Live Casino games.Ezugi live casino is a popular gaming provider in the online gaming world. A huge number of casino gaming enthusiasts play Ezugi live casino games on Yolo247. Unlike some of the other competitors, Ezugi gaming provides you with an ultimate gaming experience.A couple of things make Ezugi live casino dealer games so different. Let's learn more about the gaming provider.",
                "canonical" : "https://yolo247.site/online-casino-games/ezugi-live-casino",
                "alternate": "https://m.yolo247.site/online-casino-games/ezugi-live-casino",
                "keywords" : "Ezugi Live Casino,Ezugi, Ezugi Gaming, Yolo247 Ezugi Live Casino"
            },
            "sexygaming": {
                "title": "Play Sexy Gaming Casino Online | Get 400% Signup  Bonus",
                "description": "Sexy Gaming casino is one of Asia's most popular live casino game providers. Play sexy gaming casino games on Yolo247 and win exciting rewards.",
                "canonical" : "https://yolo247.site/online-casino-games/sexy-gaming",
                "alternate": "https://m.yolo247.site/online-casino-games/sexy-gaming",
                "keywords" : "sexy gaming casino,sexy gaming casino games, yolo247 sexy gaming casino games, yolo247 sexy gaming live casino game"
            },
            "monopolylive": {
                "title": "Monopoly Game Online- Explore monoply live casino game on yolo247",
                "description": "Monopoly game online has recently gained popularity, as it has added both offline board game & wheel game features. Play Monopoly casino game now!",
                "canonical" : "https://yolo247.site/online-casino-games/monopoly-live",
                "alternate": "https://m.yolo247.site/online-casino-games/monopoly-live",
                "keywords" : "MONOPOLY LIVE"
            },
            "funkytime": {
                "title": "Play Funky time Casino Game By Evolution Gaming on Yolo247",
                "description": "Funky Time casino game takes us back to the retro gaming mode with the modern online casino touch on Yolo247. Play Funky time online now. ",
                "canonical" : "https://yolo247.site/online-casino-games/funkytime",
                "alternate": "https://m.yolo247.site/online-casino-games/funkytime",
                "keywords" : "funkytime"
            },
            "lightningdice": {
                "title": "Play Lightning Dice - Play Instant Games for Real Cash",
                "description": "Lightning dice casino game online on Yolo247 is simple to learn & easy to master with Higher payout & faster gameplay, Play Lightning dice for real money. ",
                "canonical" : "https://yolo247.site/online-casino-games/lightning-dice",
                "alternate": "https://m.yolo247.site/online-casino-games/lightning-dice",
                "keywords" : "Lightning Dice"
            },
            "macaw": {
                "title": "Macaw Games | Top Macaw Casino Online Games on Yolo247",
                "description": "Macaw games are thrilling and are available online on Yolo247. Learn more about the popular Macaw casino online games.",
                "canonical" : "https://yolo247.site/online-casino-games/macaw",
                "alternate": "https://m.yolo247.site/online-casino-games/macaw",
                "keywords" : "macaw games, macaw casino online"
            },
            "ag": {
                "title": "Play Your Favourite AG Game Online at Yolo247",
                "description": "Amazing Gaming has established itself as one of the best gaming solutions providers. Play Amazing Gaming games on Yolo247.",
                "canonical" : "https://yolo247.site/online-casino-games/ag",
                "alternate": "https://m.yolo247.site/online-casino-games/ag",
                "keywords" : "Amazing Gaming"
            },
            "asiaCupBonanza": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on Yolo247 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Yolo247 online cricket betting, Yolo247 cricket betting odds, Yolo247 cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical" : "https://yolo247.site/asia-cup-bonanza",
                "alternate": "https://m.yolo247.site/asia-cup-bonanza"
            },

            "bingo": {
                "title": "Play Online Bingo Games on Yolo247 Casino with real money",
                "description": "Online Bingo games on Yolo247 allow you to play and place bets with INR payments. All types of Bingo games are available. Play now!",
                "canonical": "https://yolo247.site/online-casino-games/bingo",
                "alternate": "https://m.yolo247.site/online-casino-games/bingo",
                "keywords" : "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "Funkytime": {
                "title": "Play Funky Time Casino Online Game at Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical": "https://yolo247.site/online-casino-games/Funkytime",
                "alternate": "https://m.yolo247.site/online-casino-games/Funkytime",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "evo": {
                "title": "Evo Casino Game on Yolo247 | Get Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on Yolo247",
                "canonical" : "https://yolo247.site/online-casino-games/evo",
                "alternate": "https://m.yolo247.site/online-casino-games/evo",
                "keywords" : "Evo Gaming, Evolution Gaming Casino, Evo Casino Game"
            },
            "nlc": {
                "title": "NLC Casino Game on Yolo247 | 400% Signup  Bonus",
                "description": "Casino slot games are high on entertainment. Play slot games for real money on Yolo247 and earn exciting rewards.",
                "canonical": "https://yolo247.site/online-casino-games/slotGames/no-limit-city",
                "alternate": "https://m.yolo247.site/online-casino-games/slotGames/no-limit-city",
                "keywords": "nlc casino Game, nlc, nlc games, slot games for real money, casino slot games"
            },
            "luckyFruitLines": {
                "canonical": "https://yolo247.site/online-casino-games/slotGames/lucky-fruits-lines",
                "alternate": "https://m.yolo247.site/online-casino-games/slotGames/lucky-fruits-lines",
            },
            "Funky Time": {
                "title": "Funky Time Online | Play for Free or Real Money",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical": "https://yolo247.site/online-casino-games/funky-time",
                "alternate": "https://m.yolo247.site/online-casino-games/funky-time",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "First-Person-Blackjack": {
                "title": "Play Blackjack Live Casino Games | Free or Real Money",
                "description": "Play blackjack online on the most safest online casino. Register now at YOLO247 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on Yolo247, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
                "canonical": "https://yolo247.site/online-casino-games/first%20person%20blackjack",
                "alternate": "https://m.yolo247.site/online-casino-games/first%20person%20blackjack",
                "keywords": "First Person BlackJack,first person black jack,blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "teenpatti": {
                "title": "Online TeenPatti Game for Real Cash | Start Playing Now!",
                "description": "Online TeenPatti is a thrilling classic card game. Play 3 patti online on Yolo247 and earn real cash. Betting on teen patti game made easy!",
                "canonical" : "https://yolo247.site/online-casino-games/teenpatti",
                "alternate": "https://www.yolo247.site/online-casino-games/teenpatti",
                "keywords" : "Teenpatti"
            },
            //member dashboard pages
            "memberDashboard": {
                "canonical" : "https://yolo247.site/member/profile",
                "alternate": "https://m.yolo247.site/member/profile"
            },
            "memberProfileAccountInfo": {
                "canonical" : "https://yolo247.site/member/profile/accountinfo",
                "alternate": "https://m.yolo247.site/member/profile/accountinfo"
            },
            "memberProfileChangePassword": {
                "canonical" : "https://yolo247.site/member/profile/password",
                "alternate": "https://m.yolo247.site/member/profile/password"
            },
            "memberProfileBankDetails": {
                "canonical" : "https://yolo247.site/member/profile/bankdetails",
                "alternate": "https://m.yolo247.site/member/profile/bankdetails"
            },
            "memberProfileKYC": {
                "canonical" : "https://yolo247.site/member/profile/kycpage",
                "alternate": "https://m.yolo247.site/member/profile/kycpage"
            },
            "memberFinanceDeposit": {
                "canonical" : "https://yolo247.site/member/finance/deposit",
                "alternate": "https://m.yolo247.site/member/finance/deposit"
            },
            "memberFinanceWithdrawal": {
                "canonical" : "https://yolo247.site/member/finance/withdrawal",
                "alternate": "https://m.yolo247.site/member/finance/withdrawal"
            },
            "memberFinanceHistory": {
                "canonical" : "https://yolo247.site/member/finance/history",
                "alternate": "https://m.yolo247.site/member/finance/history"
            },
            "memberFinanceTransfer": {
                "canonical" : "https://yolo247.site/member/finance/transfer",
                "alternate": "https://m.yolo247.site/member/finance/transfer"
            },
            "memberMyBets": {
                "canonical" : "https://yolo247.site/member/betDetails/mybets",
                "alternate": "https://m.yolo247.site/member/betDetails/mybets"
            },
            "memberP&L": {
                "canonical" : "https://yolo247.site/member/betDetails/profitandloss",
                "alternate": "https://m.yolo247.site/member/betDetails/profitandloss"
            },
            "memberCasinoStatement": {
                "canonical" : "https://yolo247.site/member/betDetails/casinostatement",
                "alternate": "https://m.yolo247.site/member/betDetails/casinostatement"
            },
            "memberSportsbookStatement": {
                "canonical" : "https://yolo247.site/member/betDetails/sportbookstatement",
                "alternate": "https://m.yolo247.site/member/betDetails/sportbookstatement"
            },
            "memberSTPStatement": {
                "canonical" : "https://yolo247.site/member/betDetails/splitThePotStatement",
                "alternate": "https://m.yolo247.site/member/betDetails/splitThePotStatement"
            },
            "memberGalaxysStatement": {
                "canonical" : "https://yolo247.site/member/betDetails/galaxyStatement",
                "alternate": "https://m.yolo247.site/member/betDetails/galaxyStatement"
            },
            "memberAviatrixStatement": {
                "canonical" : "https://yolo247.site/member/betDetails/aviatrixStatement",
                "alternate": "https://m.yolo247.site/member/betDetails/aviatrixStatement"
            },
            "memberAccountStatement": {
                "canonical" : "https://yolo247.site/member/betDetails/accountStatement",
                "alternate": "https://m.yolo247.site/member/betDetails/accountStatement"
            },
            "memberVirtualSportsStatement": {
                "canonical" : "https://yolo247.site/member/betDetails/virtualSportsStatement",
                "alternate": "https://m.yolo247.site/member/betDetails/virtualSportsStatement"
            },
            "memberTurnoverStatement": {
                "canonical" : "https://yolo247.site/member/betDetails/turnoverStatement",
                "alternate": "https://m.yolo247.site/member/betDetails/turnoverStatement"
            },
            "memberPrormotions": {
                "canonical" : "https://yolo247.site/member/promotions",
                "alternate": "https://m.yolo247.site/member/promotions"
            },
            "memberMessages": {
                "canonical" : "https://yolo247.site/member/message",
                "alternate": "https://m.yolo247.site/member/message"
            },
        
            "big-bash": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://yolo247.site/promotions/big-bash",
                "alternate": "https://m.yolo247.site/promotions/big-bash",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "restricted-territories": {
                "title": "",
                "description": "",
                "canonical" : "https://www.yolo247.site/restricted-territories",
                "alternate": "https://m.yolo247.site/restricted-territories",
                "keywords" : ""
            },
            "rules-regulations": {
                "title": "",
                "description": "",
                "canonical" : "https://www.yolo247.site/rules-regulations",
                "alternate": "https://m.yolo247.site/rules-regulations",
                "keywords" : ""
            },
        },
        "32": {
            "default": {
                "title": "Online Betting ID | Best Site for Casino & Sports | Yolo247",
                "description": "Online betting ID makes casino & sports betting experience better. On Yolo247, get betting ID through WhatsApp instantly. Faster deposit & withdrawal.",
                "canonical" : "https://yolo247.club/",
                "alternate": "https://m.yolo247.club/",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site, online betting id, bet id, betting id online, online betting id provider"
            },
            "bingo": {
                "title": "Play Bingo Casino Game on Yolo247 | Upto 400%  Bonus",
                "description": "Explore a world of excitement at Yolo247, your gateway to thrilling online games and sports prediction. Play the most popular online real money games and seize your opportunity to win real cash prizes with amazing bonus.",
                "canonical": "https://yolo247.club/online-casino-games/bingo",
                "alternate": "https://m.yolo247.club/online-casino-games/bingo",
                "keywords" : "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "teenpatti": {
                "title": "Play Teenpatti Live Casino Games | Free or Real Money",
                "description": "Teenpatti is a popular casino card game on Yolo247. Play this exciting card game for endless entertainment and big wins.",
                "canonical" : "https://yolo247.club/online-casino-games/teenpatti",
                "alternate": "https://www.yolo247.club/online-casino-games/teenpatti",
                "keywords" : "Teenpatti"
            },      
            
            "First-Person-Blackjack": {
                "title": "Play Blackjack Live Casino Games | Free or Real Money",
                "description": "Play blackjack online on the most safest online casino. Register now at YOLO247 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on Yolo247, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
                "canonical": "https://yolo247.club/online-casino-games/first%20person%20blackjack",
                "alternate": "https://m.yolo247.club/online-casino-games/first%20person%20blackjack",
                "keywords": "First Person BlackJack,first person black jack,blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "Funky Time": {
                "title": "Funky Time Online | Play for Free or Real Money",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical": "https://yolo247.club/online-casino-games/funky-time",
                "alternate": "https://m.yolo247.club/online-casino-games/funky-time",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "evo": {
                "title": "Evo Casino Game on Yolo247 | Get Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on Yolo247",
                "canonical" : "https://yolo247.club/online-casino-games/evo",
                "alternate": "https://m.yolo247.club/online-casino-games/evo",
                "keywords" : "Evo Gaming, Evolution Gaming Casino, Evo Casino Game"
            },
            "nlc": {
                "title": "NLC Casino Game on Yolo247 | 400% Signup  Bonus",
                "description": "Casino slot games are high on entertainment. Play slot games for real money on Yolo247 and earn exciting rewards.",
                "canonical": "https://yolo247.club/online-casino-games/slotGames/no-limit-city",
                "alternate": "https://m.yolo247.club/online-casino-games/slotGames/no-limit-city",
                "keywords": "nlc casino Game, nlc, nlc games, slot games for real money, casino slot games"
            },
            "luckyFruitLines": {
                "canonical": "https://yolo247.site/online-casino-games/slotGames/lucky-fruits-lines",
                "alternate": "https://m.yolo247.site/online-casino-games/slotGames/lucky-fruits-lines",
            },
            "Funkytime": {
                "title": "Play Funky Time Casino Online Game at Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical": "https://yolo247.club/online-casino-games/Funkytime",
                "alternate": "https://m.yolo247.club/online-casino-games/Funkytime",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "ag": {
                "title": "Play Your Favourite AG Game Online at Yolo247",
                "description": "Amazing Gaming has established itself as one of the best gaming solutions providers. Play Amazing Gaming games on Yolo247.",
                "canonical" : "https://yolo247.club/online-casino-games/ag",
                "alternate": "https://m.yolo247.club/online-casino-games/ag",
                "keywords" : "Amazing Gaming"
            },
            "slotGames": {
                "title": "Slot Game ID | Betting Opportunities Online | Yolo247",
                "description": "Experience the future of online gaming with advanced slot game IDs. Enjoy a seamless journey into the world of virtual entertainment!",
                "canonical": "https://yolo247.club/online-casino-games/slotGames",
                "alternate": "https://m.yolo247.club/online-casino-games/slotGames",
                "keywords": "slot games for real money, casino slot games, casino slot casino id, Slot game id, slot casino id for real money"
            },
            "macaw": {
                "title": "Macaw Games | Top Macaw Casino Online Games on Yolo247",
                "description": "Macaw games are thrilling and are available online on Yolo247. Learn more about the popular Macaw casino online games.",
                "canonical" : "https://yolo247.club/online-casino-games/macaw",
                "alternate": "https://m.yolo247.club/online-casino-games/macaw",
                "keywords" : "macaw games, macaw casino online"
            },
            "sexygaming": {
                "title": "Play Sexy Gaming Casino Online | Get 400% Signup  Bonus",
                "description": "Sexy Gaming casino is one of Asia's most popular live casino game providers. Play sexy gaming casino games on Yolo247 and win exciting rewards.",
                "canonical" : "https://yolo247.club/online-casino-games/sexy-gaming",
                "alternate": "https://m.yolo247.club/online-casino-games/sexy-gaming",
                "keywords" : "sexy gaming casino,sexy gaming casino games, yolo247 sexy gaming casino games, yolo247 sexy gaming live casino game"
            },
            "monopolylive": {
                "title": "Monopoly ID -Grab Your Exclusive Deals Now!",
                "description": "Unlock the secrets of Monopoly ID on our dynamic webpage. From game updates to expert advice, find everything you need for success",
                "canonical" : "https://yolo247.club/online-casino-games/monopoly-live",
                "alternate": "https://m.yolo247.club/online-casino-games/monopoly-live",
                "keywords" : "MONOPOLY LIVE, monopoly id, monopoly live casino, monopoly live casino game, Play monopoly live online, monopoly live game"
            },
            "lightningdice": {
                "title": " Lightning Dice | Play Live Casino Games on Yolo247",
                "description": "Lightning Dice. Play Lightning Dice games on Yolo247.",
                "canonical" : "https://yolo247.club/online-casino-games/lightning-dice",
                "alternate": "https://m.yolo247.club/online-casino-games/lightning-dice",
                "keywords" : "Lightning Dice"
            },
            "onlineCasinoGames": {
                "title": "Live Casino ID: Play Exciting casino Online",
                "description": "Step into the excitement at live casino ID. Play your favorite casino with live dealers for an unparalleled gaming experience",
                "canonical": "https://yolo247.club/online-casino-games",
                "alternate": "https://m.yolo247.club/online-casino-games",
                "keywords": " Online Casino Games, Live Casino Online, Play Casino games online, casino id, live casino id, Roulette ID Provider"
            },
            "live-baccarat": {
                "title": "Play Baccarat Online for FREE or Real Money | Yolo247",
                "description": "Play Baccarat Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Baccarat Live now!",
                "canonical": "https://www.yolo247.club/live-casino/live-baccarat",
                "alternate": "https://m.yolo247.club/live-casino/live-baccarat",
                "keywords": "live-baccarat, Live-Baccarat, Live Baccarat, livebaccarat, Livebaccarat, Baccarat Online Game, Baccarat Live, Baccarat"
            },
            "live-blackjack": {
                "title": "Secure Your Blackjack ID and Experience Online Thrills",
                "description": "Blackjack ID Your passport to a new era of gaming. Elevate your strategy, enhance your skills, and conquer the tables",
                "canonical": "https://yolo247.club/online-casino-games/live-blackjack",
                "alternate": "https://m.yolo247.club/online-casino-games/live-blackjack",
                "keywords": "blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games,blackjack id,blackjack online,blackjack online real money,"
            },
            "roulette": {
                "title": "Online Roulette with Roulette ID- Win Big with Yolo247",
                "description": "Elevate your gaming adventure with Roulette ID, the premier platform for immersive and rewarding roulette action.",
                "canonical": "https://yolo247.club/online-casino-games/live-roulette",
                "alternate": "https://m.yolo247.club/online-casino-games/live-roulette",
                "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table,roulette id,online roulette,online roulette real money,"
            },
            "lightningroulette": {
                "title": "Spin to Win with Lightning Roulette ID | Grab Exclusive Bonuses",
                "description": "Get ready for high-voltage fun with Lightning Roulette ID. Spin the wheel and feel the excitement with unique twists and chances to win big",
                "canonical": "https://yolo247.club/online-casino-games/lightning-roulette",
                "alternate": "https://m.yolo247.club/online-casino-games/lightning-roulette",
                "keywords": "lightning roulette game,lightning roulette online,casino lightning roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table,lightningroulette id,lightning roulette online,lightning roulette online casino,play lightning roulette online"
            },
            "dragonTiger": {
                "title": "Secure Your Blackjack ID and Experience Online Thrills",
                "description": "Elevate your gaming experience with Dragon Tiger ID. Uncover a world of entertainment and fortune in this exciting online platform",
                "canonical": "https://yolo247.club/online-casino-games/dragon-tiger",
                "alternate": "https://m.yolo247.club/online-casino-games/dragon-tiger",
                "keywords": "dragon tiger id, dragon tiger online, Dragon tiger real money game, Dragon Tiger"
            },
            "baccarat": {
                "title": "Get Your Baccarat ID for Seamless Online Play with Yolo247",
                "description": "Elevate your gaming with Baccarat ID. Dive into the captivating world of online baccarat and redefine your winning moments.",
                "canonical": "https://yolo247.club/online-casino-games/live-baccarat",
                "alternate": "https://m.yolo247.club/online-casino-games/live-baccarat",
                "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat,baccarat id,baccarat online"
            },
            "andarBahar": {
                "title": "Play Andar Bahar Online for FREE or Real Money - Yolo247",
                "description": "Play Andar Bahar Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Andar Bahar Live now!",
                "canonical": "https://yolo247.club/online-casino-games/andar-bahar",
                "alternate": "https://m.yolo247.club/online-casino-games/andar-bahar",
                "keywords": "Andar Bahar game,Andar Bahar online,Andar Bahar casino,Andar Bahar gaming,Andar Bahar rule,Andar Bahar game online,Andar Bahar game rules,play Andar Bahar online,Andar Bahar online play,Andar Bahar online free,best Andar Bahar online casino,best casino for Andar Bahar"
            },
            "dreamCatcher": {
                "title": "Play Dream Catcher Online for FREE or Real Money - Yolo247",
                "description": "Play Dream Catcher Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Dream Catcher Live now!",
                "canonical": "https://yolo247.club/online-casino-games/dream-catcher",
                "alternate": "https://m.yolo247.club/online-casino-games/dream-catcher",
                "keywords": "Dream Catcher game,Dream Catcher online,Dream Catcher casino,Dream Catcher gaming,Dream Catcher rule,Dream Catcher game online,Dream Catcher game rules,play Dream Catcher online,Dream Catcher online play,Dream Catcher online free,best Dream Catcher online casino,best casino for Dream Catcher"
            },
            "hilo": {
                "title": "Play Hilo Online for FREE or Real Money - Yolo247",
                "description": "Play Hilo Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Hilo Live now!",
                "canonical": "https://yolo247.club/online-casino-games/hi-lo",
                "alternate": "https://m.yolo247.club/online-casino-games/hi-lo",
                "keywords": "Hilo game,Hilo online,Hilo casino,Hilo gaming,Hilo rule,Hilo game online,Hilo game rules,play Hilo online,Hilo online play,Hilo online free,best Hilo online casino,best casino for Hilo"
            },
            "teenpattiOnlinePlay": {
                "title": "Grab Your Teenpatti ID | Play at Yolo247 Now!",
                "description": "Experience the magic of Teen Patti ID online. Play with friends, showcase your skills, and win amazing prizes. Join the gaming revolution now!",
                "canonical": "https://m.yolo247.club/teenpatti-online-play",
                "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino, teen patti id, teen patti online play, teenpatti online"
            },
            "sportsBetting": {
                "title": "Sports Betting ID- Place a Bet On Live Sports ",
                "description": "Explore Live Sports on Yolo247 with Sports Betting ID- Betting Exchange offers you the best sports betting experience in India.  400% Welcome Bonus | Instant Withdrawal",
                "canonical": "https://yolo247.club/betting-exchange",
                "alternate": "https://m.yolo247.club/betting-exchange?id=0",
                "keywords": " betting exchange, sports betting id, best online sports betting id provider, sports betting id provider, online sports betting id provider, online sports betting id"
            },
            "onlineCricketSattaBazar": {
                "title": "Get Your Cricket Betting ID - Bet On Your Favorite Sports",
                "description": "Get Your Cricket Betting ID and bet on your favorite cricket matches in one of the best cricket betting sites in India. Place bets on Sportsbook, Exchange, and Virtual Sports on Yolo247",
                "keywords": "Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Yolo247 online cricket betting, Yolo247 cricket betting odds, Yolo247 cricket betting, cricket betting sites, online cricket satta bazar, cricket betting id, online cricket betting id, betting id cricket, online cricket betting id in india, online id for cricket betting",
                "canonical": "https://yolo247.club/betting-exchange/online-cricket-betting",
                "alternate": "https://m.yolo247.club/betting-exchange/online-cricket-betting",
            },
            "footballBetting": {
                "title": "Your Ultimate Football Betting ID Provider- Yolo247",
                "description": "Kick off your winning streak with our football betting ID. Instant sign-up, smooth transactions, and a plethora of options. Your path to victory starts here!",
                "canonical": "https://yolo247.club/betting-exchange/football-betting",
                "alternate": "https://m.yolo247.club/betting-exchange/football-betting",
                "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites, football betting id, football betting online, online football betting india"
            },
            "tennisBetting": {
                "title": "Tennis Betting Id- Turning Predictions into Profits",
                "description": "Enhance your tennis betting thrill with a dedicated betting ID. Quick access and seamless transactions for an elevated gaming experience. Join now!",
                "canonical": "https://yolo247.club/betting-exchange/tennis-betting",
                "alternate": "https://m.yolo247.club/betting-exchange/tennis-betting",
                "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites,tennis betting id,tennis betting,tennis id,best tennis betting sites"
            },
            "volleyballBetting": {
                "title": "Trusted Volleyball Betting ID Platform - Yolo247   ",
                "description": "Volleyball betting ID is made simple with our dedicated ID. Instant registration, seamless transactions, and a world of betting opportunities at your fingertips",
                "canonical": "https://yolo247.club/betting-exchange/volleyball-betting",
                "alternate": "https://m.yolo247.club/betting-exchange/volleyball-betting",
                "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites,volleyball betting id,volleyball betting,volleyball betting odds,best online volleyball betting sites"
            },
            "promotions": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://yolo247.club/promotions",
                "alternate": "https://m.yolo247.club/promotions",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "specialMarket": {
                "title": "Special Markets on Yolo 247",
                "description": "Special Markets on Yolo 247",
                "canonical" : "https://yolo247.club/specialMarket",
                "alternate": "https://m.yolo247.club/specialMarket",
            },
            "virtualSports": {
                "title": "Hit the Jackpot with Online Cricket ID: Your way to Win Big!",
                "description": "Transform into a cricket champion with your online circket ID. Engage in competitive matches, tournaments, and connect with a community of passionate gamers",
                "canonical" : "https://yolo247.club/virtualSports/vci",
                "alternate": "https://m.yolo247.club/virtualSports/vci",
            },
            "virtualSportsBL": {
                "title": "Elevate Your Game with Virtual basketball betting id",
                "description": "Unlock the excitement with premier basketball betting IDs. Safe, secure, and loaded with options for the ultimate hoops betting thrill.",
                "canonical" : "https://yolo247.club/virtualSports/vbl",
                "alternate": "https://m.yolo247.club/virtualSports/vbl",
            },
            "virtualSportsFB": {
                "title": "Bundesliga Betting: Place Your Virtual Sports Bets at Yolo247",
                "description": "Unleash the power of your Bundesliga betting ID for a premier football wagering experience. Predict, bet, and celebrate victories!",
                "canonical" : "https://yolo247.club/virtualSports/vfb",
                "alternate": "https://m.yolo247.club/virtualSports/vfb",
            },
            "virtualSportsFEL": {
                "title": "EPL Betting ID | Bet on Premier League Matches Online",
                "description": "Get in the game with an EPL betting ID. Elevate your stakes, enjoy bonuses, and experience football like never before. Bet smart, win big!",
                "canonical" : "https://yolo247.club/virtualSports/vfel",
                "alternate": "https://m.yolo247.club/virtualSports/vfel",
            },
            "virtualSportsFEC": {
                "title": "European Cup Betting ID : Place Your Bets and Win Big!",
                "description": "Join the action with our European Cup betting ID. Elevate your game with top-notch security and access to premium odds. Bet with confidence!",
                "canonical" : "https://yolo247.club/virtualSports/vfec",
                "alternate": "https://m.yolo247.club/virtualSports/vfec",
            },
            "virtualSportsVTI": {
                "title": "Elevate Your Game with Virtual Tennis Betting ID",
                "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
                "canonical" : "https://yolo247.club/virtualSports/vti",
                "alternate": "https://yolo247.club/virtualSports/vti",
            },
            "virtualSportsWC": {
                "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
                "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
                "canonical" : "https://yolo247.club/virtualSports/vfwc",
                "alternate": "https://m.yolo247.club/virtualSports/vfwc",
            },
            "aviator": {
                "title": "Get Aviator ID & Explore More Instant casino - Yolo247",
                "description": "Unlock a world of aviation with Aviator ID – your passport to exclusive pilot resources and communities. Elevate your flying experience now!",
                "canonical" : "https://yolo247.club/instant-games/aviator",
                "alternate": "https://m.yolo247.club/instant-games/aviator",
                "keywords" : "Aviator game, Aviator online game, aviator id, aviator betting sites, aviator game betting, aviator betting game"
            },
            "cashshow": {
                "title": "Cash Show Game | Live Casino Online Games on Yolo247",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Yolo247.",
                "canonical" : "https://yolo247.club/instant-games/cashshow",
                "alternate": "https://m.yolo247.club/instant-games/cashshow",
                "keywords" : "Cash Show game, Cash Show online game "
            },
            "jetx": {
                "title": "Claim Your jetx ID & Get Exclusive Bonus Rewards",
                "description": "Unlock limitless possibilities with JetX ID – your gateway to seamless online experiences and secure digital identity management",
                "canonical" : "https://www.yolo247.club/instant-games/jetx",
                "alternate": "https://m.yolo247.club/instant-games/jetx",
                "keywords" : "Jet X game, Jet X online game, jetx id, jetx game, jetx online"
            },
            "rockpaperscissors": {
                "title": "Rockpaperscissors ID - Grab Your Exclusive Deals Now!",
                "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
                "canonical" : "https://yolo247.club/instant-games/rock-paper-scissors",
                "alternate": "https://yolo247.club/instant-games/rock-paper-scissors",
                "keywords" : "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
            },
            "plinkox": {
                "title": "Grab Plinkox ID - Inspect More Instant casino - Yolo247",
                "description": "Step into the future with Plinkox ID - Your trusted partner for secure, seamless, and personalized digital experiences",
                "canonical" : "https://www.yolo247.club/instant-games/plinko-x",
                "alternate": "https://m.yolo247.club/instant-games/plinko-x",
                "keywords" : "Plinkox game, Plinkox online game "
            },
            "footballx": {
                "title": "Get Footballx ID Play FootballX Online Game on yolo247",
                "description": "Step into the future of football with FootballX ID. Access exclusive features, connect with like-minded fans, and celebrate the game you love",
                "canonical" : "https://www.yolo247.club/instant-games/football-x",
                "alternate": "https://m.yolo247.club/instant-games/football-x",
                "keywords" : "Football X game, Football X online game "
            },
            "balloon": {
                "title": "Get Balloon Betting ID with 400% bonus- Yolo247",
                "description": "Find your perfect balloon betting ID platform. Enjoy a variety of casino, competitive odds, and a secure environment for online betting",
                "canonical" : "https://www.yolo247.club/instant-games/balloon",
                "alternate": "https://m.yolo247.club/instant-games/balloon",
                "keywords" : "Balloon game, Balloon online game "
            },
            "cricketx": {
                "title": "Claim Your CricketX ID & Get Exclusive Bonus Rewards",
                "description": "Elevate your cricket experience with CricketX ID. Access real-time updates, player profiles, and immersive content. Be part of the game!",
                "canonical" : "https://yolo247.club/instant-games/cricket-x",
                "alternate": "https://m.yolo247.club/instant-games/cricket-x",
                "keywords" : "Cricketx Games,Yolo247 Cricketx games,Yolo Cricketx games,Cricketx casino games,CricketX id,cricket x bet,cricket x game"
            },
            "mines": {
                "title": "Get Mines Game ID & Explore More Instant casino - Yolo247",
                "description": "Elevate your betting journey with a mines betting ID. Unleash the thrill of wagering on a cutting-edge platform",
                "canonical" : "https://www.yolo247.club/instant-games/mines",
                "alternate": "https://m.yolo247.club/instant-games/mines",
                "keywords" : "Mines game, Online mines game"
            },
            "rocketon": {
                "title": "Play Rocketon Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Rocketon! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/rocketon",
                "alternate": "https://m.yolo247.club/instant-games/rocketon",
                "keywords" : "Rocketon game, Online Rocketon game"
            },
            "burningIce": {
                "title": "Play BurningIce Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in BurningIce! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/burning-ice",
                "alternate": "https://m.yolo247.club/instant-games/burning-ice",
                "keywords" : "BurningIce game, Online BurningIce game"
            },
            "cappaDocia": {
                "title": "Play Cappa Docia Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Cappa Docia! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/cappa-docia",
                "alternate": "https://m.yolo247.club/instant-games/cappa-docia",
                "keywords" : "Cappa Docia game, Online Cappa Docia game"
            },
            "geniesBonanza": {
                "title": "Play GeniesBonanza Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in GeniesBonanza! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/genies-bonanza",
                "alternate": "https://m.yolo247.club/instant-games/genies-bonanza",
                "keywords" : "GeniesBonanza game, Online GeniesBonanza game"
            },
            "goal": {
                "title": "Play Goal Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Goal! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/goal",
                "alternate": "https://m.yolo247.club/instant-games/goal",
                "keywords" : "Goal game, Online Goal game"
            },
            "hunterx": {
                "title": "Play Hunterx Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Hunterx! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/hunter-x",
                "alternate": "https://m.yolo247.club/instant-games/hunter-x",
                "keywords" : "Hunterx game, Online Hunterx game"
            },
            "jungles": {
                "title": "Play Jungles Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Jungles! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/jungles",
                "alternate": "https://m.yolo247.club/instant-games/jungles",
                "keywords" : "Jungles game, Online Jungles game"
            },
            "magicGarden": {
                "title": "Play Magic Garden Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Magic Garden! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/magic-garden",
                "alternate": "https://m.yolo247.club/instant-games/magic-garden",
                "keywords" : "Magic Garden game, Online Magic Garden game"
            },
            "multiHotWays": {
                "title": "Play Multi Hot Ways Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Multi Hot Ways! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/multi-hot-ways",
                "alternate": "https://m.yolo247.club/instant-games/multi-hot-ways",
                "keywords" : "Multi Hot Ways game, Online Multi Hot Ways game"
            },
            "paperPlane": {
                "title": "Play Paper Plane Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Paper Plane! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/paper-plane",
                "alternate": "https://m.yolo247.club/instant-games/paper-plane",
                "keywords" : "Paper Plane game, Online Paper Plane game"
            },
            "stockMarket": {
                "title": "Play Stock Market Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Stock Market! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/stock-market",
                "alternate": "https://m.yolo247.club/instant-games/stock-market",
                "keywords" : "Stock Market game, Online Stock Market game"
            },
            "macludo": {
                "title": "Macludo Casino Games Online - Play & Win 24/7",
                "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "canonical" : "https://yolo247.club/online-casino-games/macludo",
                "alternate": "https://m.yolo247.club/online-casino-games/macludo",
                "keywords" : "Ludo Game, Ludo game Online"
            },
            "crazytime": {
                "title": "Play Crazy Time game online For Real money on yolo247 ",
                "description": "Crazy Time casino game is the best wheel-based casino game on Yolo247. Play Crazy time game online, go crazy with higher winnings and faster game process. ",
                "canonical" : "https://www.yolo247.club/online-casino-games/crazy-time",
                "alternate" : "https://m.yolo247.club/online-casino-games/crazy-time",
                "keywords" : "Crazytime Game, Crazytime game Online, crazy time betting, crazy time online game, crazy time id"
            },
            "welcomeBonus": {
                "title": "Play Online Casino & Sports casino and Get 400%  Welcome Bonus at Yolo247",
                "description": "Begin your adventure with a bang! Our welcome bonus is your ticket to a thrilling journey of bonuses and rewards.",
                "canonical" : "https://yolo247.club/promotions/welcome-bonus",
                "alternate": "https://m.yolo247.club/promotions/welcome-bonus",
                "keywords" : "Welcome Bonus, casino welcome bonus"
            },
            "refillBonus": {
                "title": "Refill Casino Bonus: Claim Your Way to Boosted Winnings Today!",
                "description": "Reload your luck with our exclusive refill casino bonus. Elevate your gaming experience with extra rewards. Play smarter, win bigger!",
                "canonical" : "https://yolo247.club/promotions/refill-bonus",
                "alternate": "https://m.yolo247.club/promotions/refill-bonus",
                "keywords" : "Refill bonus, Refill casino bonus"
            },
            "cashbackBonus": {
                "title": "Cash Back Bonus | Get A 5% Cashback Bonus Instantly ",
                "description": "Explore the world of online casinos with cashback bonuses. Enhance your gameplay, recover losses, and enjoy a rewarding casino adventure like never before",
                "canonical" : "https://yolo247.club/promotions/cashback-bonus",
                "alternate": "https://m.yolo247.club/promotions/cashback-bonus",
                "keywords" : "Cashback Bonus, casino cashback bonus, online casino with cashback bonus"
            },
            "referralBonus": {
                "title": "Referral Bonus Bonanza: Elevate Your Rewards Game Today",
                "description": "Unleash the power of online casino referral bonuses! Elevate your play with exclusive rewards and exciting promotions on yolo247",
                "canonical" : "https://yolo247.club/promotions/referral-bonus",
                "alternate": "https://m.yolo247.club/promotions/referral-bonus",
                "keywords" : "Referral Bonus, online casino referral bonus, casino referral bonus, best online casino referral bonus"
            },
            "termsandconditions": {
                "title": "Terms & Conditions | Yolo247 Online Betting T&C",
                "description": "Terms & Conditions of Yolo247 updated! Check the obligations, terms of use, registration details, rules of play, deposit & withdrawal policies and more.",
                "canonical" : "https://yolo247.club/terms-conditions",
                "alternate": "https://m.yolo247.club/about/terms-conditions",
                "keywords" : "Terms & Conditions"
            },
            "responsibleGambling": {
                "title": "Responsible Gambling | Yolo247",
                "description": "Responsible Gambling is highly insisted. At Yolo247, we educate, insist and highly recommend to be aware of the negative impacts of gambling.",
                "canonical" : "https://yolo247.club/responsible-gambling",
                "alternate": "https://m.yolo247.club/about/responsible-gambling",
                "keywords" : "Responsible Gambling"
            },
            "privacyPolicy": {
                "title": "Yolo247 Privacy Policy | Best Online Betting Site in India",
                "description": "Privacy Policy reading is always integral in the process of betting. Go through definitions and types of data collected. Click to know more!",
                "canonical" : "https://yolo247.club/privacy-policy",
                "alternate": "https://m.yolo247.club/about/privacy-policy",
                "keywords" : "privacy policy"
            },
            "kycPolicy": {
                "title": "KYC Policy of Yolo247 | Get to know the KYC requirements",
                "description": "KYC Policy at Yolo247 is clear and transparent. Go through the guidelines, documents required, transaction limits and much more.",
                "canonical" : "https://yolo247.club/kyc-policy",
                "alternate": "https://m.yolo247.club/about/kyc-policy",
                "keywords" : "KYC Policy"
            },
            "exclusionPolicy": {
                "title": "Exclusion Policy  | Self-Exclusion Policy of Yolo247",
                "description": "Exclusion Policy (self-exclusion) is a facility that the site offers to help customers who feel that their Gambling is out of ",
                "canonical" : "https://yolo247.club/exclusion-policy",
                "alternate": "https://m.yolo247.club/about/self-exclusion-policy",
                "keywords" : "exclusion policy "
            },
            "amlPolicy": {
                "title": "AML Policy | Anti Money Laundering Policy of Yolo247",
                "description": "AML policy, also known as Anti-Money Laundering Policy, is actively adhered to by Yolo247. Your safety is our priority. Read more!",
                "canonical" : "https://yolo247.club/aml-policy",
                "alternate": "https://m.yolo247.club/about/aml-policy",
                "keywords" : "AML Policy"
            },
            "aboutus": {
                "title": "About Us | Yolo 247 - World's Biggest Betting ID Provider",
                "description": "Discover the world's biggest betting ID provider,With a wide range of options and a seamless user experience, Join us today and experience the ultimate thrill of online betting with Yolo 247.",
                "canonical" : "https://yolo247.club/about-us",
                "alternate": "https://m.yolo247.club/about/about-us",
                "keywords" : "About us, About Yolo247"
            },
            "SPRIBE": {
                "title": "Spribe Casino Games | Get the best Spribe Games on Yolo247",
                "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
                "canonical" : "https://yolo247.club/instant-games/spribe",
                "alternate": "https://m.yolo247.club/instant-games/spribe",
                "keywords" : "spribe casino games"
            },
            "aviatrix": {
                "title": "Aviatrix Game: Play at Yolo247 & Win Real Money Online",
                "description": "Yolo247 invites you to elevate your gaming experience with Aviatrix, a game that takes you on a journey through the clouds. Play Aviatrix game and Start your winning journey now!",
                "canonical" : "https://yolo247.club/instant-games/aviatrix",
                "alternate": "https://m.yolo247.club/instant-games/aviatrix",
                "keywords" : "Aviatrix casino games"
            },
            "7MOJOS": {
                "title": "7Mojos Games | Explore the top games at the best platform",
                "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
                "canonical" : "https://yolo247.club/online-casino-games/7mojos",
                "alternate": "https://m.yolo247.club/online-casino-games/7mojos",
                "keywords" : "7Mojos Games"
            },
            "supernowa": {
                "title": "Play Supernowa Casino Games on Yolo247",
                "description": " Supernowa casino games are one of the best in business. Check out Supernowa gaming and see the games offered on Yolo247.",
                "canonical" : "https://yolo247.club/online-casino-games/supernowa",
                "alternate": "https://m.yolo247.club/online-casino-games/supernowa",
                "keywords" : "Supernowa Casino Games,Supernowa gaming, Supernowa casino"
            },
            "pragmatic": {
                "title": "Pragmatic Casino Game on Yolo247 | Get 400% Welcome Bonus",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "canonical" : "https://yolo247.club/online-casino-games/slotGames/pragmatic-play",
                "alternate": "https://m.yolo247.club/online-casino-games/slotGames/pragmatic-play",
                "keywords" : "Pragmatic casino, pragmatic games"
            },
            "superspade": {
                "title": "Top Super Spade Games On Yolo247 | Play Now!",
                "description": " Super Spade games are popular because of exciting features, gameplay & payouts. Play Super Spade casino games on Yolo247 and win big. Join now!",
                "canonical" : "https://yolo247.club/online-casino-games/super-spade",
                "alternate": "https://m.yolo247.club/online-casino-games/super-spade",
                "keywords" : "super spade games,play super spade casino games, super spade casino"
            },
            "tvbet": {
                "title": "Play TVBET Casino Games Online | Real Money Games",
                "description": "TVBET casino games provide engaging games. A wide variety of games await online casino gaming enthusiasts. Find out more about Yolo247 TVBET Games.",
                "canonical" : "https://yolo247.club/online-casino-games/tvbet",
                "alternate": "https://m.yolo247.club/online-casino-games/tvbet",
                "keywords" : "TVBET casino games, Yolo247 TVBET games"
            },
            "netent": {
                "title": "Netent Casino Game on Yolo247 | Get Upto 400%  Bonus",
                "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
                "canonical" : "https://yolo247.club/online-casino-games/slotGames/netent",
                "alternate": "https://m.yolo247.club/online-casino-games/slotGames/netent",
                "keywords" : "netent games"
            },
            "betgames": {
                "title": "BetGames On Yolo247 | Play BetGames & Win Big",
                "description": "BetGames is one of the modern-day game providers in Yolo247. Several casino gaming enthusiasts love the games. Here's why.BetGames is another top live dealer casino game provider that is available on Yolo247 from the comfort of your home. The best part of BetGames is that instead of promoting state-of-the-art technology, they have made their gaming solutions much more accessible to new gamers who want to explore the gaming industry at their own pace.Over the years, the live casino has been the hot trend in the gaming industry, and BetGames, too, has tapped into this market, creating some of the most awe-inspiring live dealer games. Most of these games make you feel like you are in a physical casino.",
                "canonical" : "https://yolo247.club/online-casino-games/bet-games",
                "alternate": "https://m.yolo247.club/online-casino-games/bet-games",
                "keywords" : "betgames,Yolo247 BetGames"
            },
            "sportsbook": {
                "title": "Get your sportsbook ID & Place a bet on your favorite sports",
                "description": "Unleash the power of your sportsbook ID for a premium betting experience. Join us for quick registration, seamless transactions, and endless excitement",
                "canonical" : "https://yolo247.club/sportsbook",
                "alternate": "https://m.yolo247.club/sportsbook",
                "keywords" : "Sportsbook Betting,Best Online Sportsbook, Sports Betting in India, sportsbook id, online sportsbook betting, sportsbook betting, best sportsbook betting sites"
            },
            "premiumSportsbook": {
                "title": "Best Online Sportsbook ID: Experience Victory with Every Wager!",
                "description": "Bet smarter with the best ID sportsbook. Elevate your gaming experience with instant deposits, fast withdrawals, and a wide array of sports to choose from",
                "canonical" : "https://yolo247.club/premium-sportsbook",
                "alternate": "https://m.yolo247.club/premium-sportsbook",
                "keywords" : "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India, best id sportsbook, sportsbook betting online, sportsbook betting"
            },
            "appDownload": {
                "title": "Yolo247 App Download | Free Download for Android",
                "description": "Download Yolo247 App to get the best casino and sports betting experience. Go for real-money gambling & get the best betting app in India now!",
                "canonical" : "https://yolo247.club/app-download",
                "alternate": "https://m.yolo247.club/app-download",
                "keywords" : "Yolo App Download, Yolo Android App"
            },
            "login": {
                "title": "Secure Yolo247 Login: Access Your Account Safely",
                "description": "Login to your Yolo247 account, Safely access your account, and enjoy the convenience of managing your Yolo247 services hassle-free.",
                "canonical" : "https://yolo247.club/login",
                "alternate": "https://m.yolo247.club/login",
                "keywords" : "Yolo Login"
            },
            "signup": {
                "title": "Betting Made Easy: Register on yolo247 for Your Online Betting ID",
                "description": "Yolo 247 Signup Your key to a seamless and efficient entry into an exciting Online Gaming world, Don't miss out on the fun, sign up now!",
                "canonical" : "https://yolo247.club/signup",
                "alternate": "https://m.yolo247.club/signup",
                "keywords" : "Yolo User Registration"
            },
            "faq": {
                "title": "FAQ Yolo247 | Online BettingID Provider in India",
                "description": "Get the most out of Yolo 247 with our concise FAQ. Troubleshoot issues, discover hidden features, and enhance your experience!",
                "canonical" : "https://yolo247.club/faq",
                "alternate": "https://m.yolo247.club/faq",
            },
            "affiliate": {
                "title": "Affiliate Page",
                "description": "Affiliate Page",
                "canonical" : "https://yolo247.club/affiliate",
                "alternate": "https://m.yolo247.club/affiliate",
            },
            "loyalty": {
                "title": "Loyalty Page",
                "description": "Loyalty Page",
                "canonical" : "https://yolo247.club/loyalty",
                "alternate": "https://m.yolo247.club/loyalty",
            },
            "instantGames": {
                "title": "Instant Games | Play Best Instant Casino Online on Yolo247",
                "description": "Instant Games on Yolo247 are always on demand. Play all your favourite instant casino games online on your favourite betting platform.",
                "canonical" : "https://yolo247.club/instant-games",
                "alternate": "https://m.yolo247.club/instant-games",
                "keywords" : "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "majorLeagueCricketBetting": {
                "title": "MLC Betting in India | Major League Cricket Betting Online",
                "description": "MLC Betting is available on Yolo247! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
                "canonical" : "https://yolo247.club/sports/tournaments/major-league-cricket-betting",
                "alternate": "https://m.yolo247.club/sports/tournaments/major-league-cricket-betting",
                "keywords" : "Major League Cricket Betting Online, MLC Betting in India"
            },
            "dailyDepositBonus": {
                "title": "Refill bonus | Best Casino Refill Bonus on Yolo247",
                "description": "Yolo247 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Yolo247.",
                "canonical" : "https://yolo247.club/daily-deposit-bonus",
                "alternate": "https://m.yolo247.club/daily-deposit-bonus",
                "keywords" : "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "popular games": {
                "title": "Live Casino ID: Bet, Spin, and Win Instantly - Yolo247",
                "description": "Get ready for non-stop fun with a personalized casino ID. Join the excitement, play your favorite casino, and win big!",
                "canonical" : "https://yolo247.club//online-casino-games/best-casino-games",
                "alternate": "https://m.yolo247.club/online-casino-games/best-casino-games",
                "keywords" : "Best Casino Games Popular Casino Games, online casino real money, popular games in india, popular pc games, popular video games, online casino betting sites, casino id"
            },
            "evolution": {
                "title": "Play Evolution Gaming Casino | Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on Yolo247",
                "canonical" : "https://yolo247.club/online-casino-games/evolution-gaming",
                "alternate": "https://m.yolo247.club/online-casino-games/evolution-gaming",
                "keywords" : "Evolution Gaming, Evolution Gaming Casino"
             },
             "spribe": {
                 "title": "Spribe Games | Play The Best Yolo247 Spribe Games",
                 "description": "Spribe games use the latest technology to build innovative and immersive live casino games for users. Find out how to experience thrilling Yolo247 Spribe Games.",
                 "canonical" : "https://yolo247.club/instant-games/spribe",
                 "alternate": "https://m.yolo247.club/instant-games/spribe",
                 "keywords" : "Spribe Games,Yolo247 Spribe games,Yolo Spribe games,Spribe live casino games"
            },
            "ezugi": {
                "title": "Ezugi Live Casino | Play Yolo247 Ezugi Games",
                "description": " Ezugi's state-of-the-art live casino games are a massive hit among fans. Let us do a deep dive into Ezugi Live Casino games.Ezugi live casino is a popular gaming provider in the online gaming world. A huge number of casino gaming enthusiasts play Ezugi live casino games on Yolo247. Unlike some of the other competitors, Ezugi gaming provides you with an ultimate gaming experience.A couple of things make Ezugi live casino dealer games so different. Let's learn more about the gaming provider.",
                "canonical" : "https://yolo247.club/online-casino-games/ezugi-live-casino",
                "alternate": "https://m.yolo247.club/online-casino-games/ezugi-live-casino",
                "keywords" : "Ezugi Live Casino,Ezugi, Ezugi Gaming, Yolo247 Ezugi Live Casino"
            },
             "asiaCupBonanza": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on Yolo247 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Yolo247 online cricket betting, Yolo247 cricket betting odds, Yolo247 cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical" : "https://yolo247.club/asia-cup-bonanza",
                "alternate": "https://m.yolo247.club/asia-cup-bonanza"
            },
             //member dashboard pages
             "memberDashboard": {
                "canonical" : "https://yolo247.club/member/profile",
                "alternate": "https://m.yolo247.club/member/profile"
            },
            "memberProfileAccountInfo": {
                "canonical" : "https://yolo247.club/member/profile/accountinfo",
                "alternate": "https://m.yolo247.club/member/profile/accountinfo"
            },
            "memberProfileChangePassword": {
                "canonical" : "https://yolo247.club/member/profile/password",
                "alternate": "https://m.yolo247.club/member/profile/password"
            },
            "memberProfileBankDetails": {
                "canonical" : "https://yolo247.club/member/profile/bankdetails",
                "alternate": "https://m.yolo247.club/member/profile/bankdetails"
            },
            "memberProfileKYC": {
                "canonical" : "https://yolo247.club/member/profile/kycpage",
                "alternate": "https://m.yolo247.club/member/profile/kycpage"
            },
            "memberFinanceDeposit": {
                "canonical" : "https://yolo247.club/member/finance/deposit",
                "alternate": "https://m.yolo247.club/member/finance/deposit"
            },
            "memberFinanceWithdrawal": {
                "canonical" : "https://yolo247.club/member/finance/withdrawal",
                "alternate": "https://m.yolo247.club/member/finance/withdrawal"
            },
            "memberFinanceHistory": {
                "canonical" : "https://yolo247.club/member/finance/history",
                "alternate": "https://m.yolo247.club/member/finance/history"
            },
            "memberFinanceTransfer": {
                "canonical" : "https://yolo247.club/member/finance/transfer",
                "alternate": "https://m.yolo247.club/member/finance/transfer"
            },
            "memberMyBets": {
                "canonical" : "https://yolo247.club/member/betDetails/mybets",
                "alternate": "https://m.yolo247.club/member/betDetails/mybets"
            },
            "memberP&L": {
                "canonical" : "https://yolo247.club/member/betDetails/profitandloss",
                "alternate": "https://m.yolo247.club/member/betDetails/profitandloss"
            },
            "memberCasinoStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/casinostatement",
                "alternate": "https://m.yolo247.club/member/betDetails/casinostatement"
            },
            "memberSportsbookStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/sportbookstatement",
                "alternate": "https://m.yolo247.club/member/betDetails/sportbookstatement"
            },
            "memberSTPStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/splitThePotStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/splitThePotStatement"
            },
            "memberGalaxysStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/galaxyStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/galaxyStatement"
            },
            "memberAviatrixStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/aviatrixStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/aviatrixStatement"
            },
            "memberAccountStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/accountStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/accountStatement"
            },
            "memberVirtualSportsStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/virtualSportsStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/virtualSportsStatement"
            },
            "memberTurnoverStatement": {
                "canonical" : "https://yolo247.club/member/betDetails/turnoverStatement",
                "alternate": "https://m.yolo247.club/member/betDetails/turnoverStatement"
            },
            "memberPrormotions": {
                "canonical" : "https://yolo247.club/member/promotions",
                "alternate": "https://m.yolo247.club/member/promotions"
            },
            "memberMessages": {
                "canonical" : "https://yolo247.club/member/message",
                "alternate": "https://m.yolo247.club/member/message"
            },
            "ninjacrash": {
                "title": "Plan NinjaCrash Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://yolo247.club/instant-games/ninja-crash",
                "alternate": "https://m.yolo247.club/instant-games/ninja-crash",
                "keywords" : "NinjaCrash game, Online NinjaCrash game"
            },
            "big-bash": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://yolo247.club/promotions/big-bash",
                "alternate": "https://m.yolo247.club/promotions/big-bash",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
        },
        "33": {
            "default": {
                "title": "Play Online Casino Games in India 24/7 - Win Real Money ₹₹",
                "description": "Experience non-stop thrills with our Online Casino Games in India. Play to win Real Money and enjoy the excitement of the game. Start your winning journey now!",
                "canonical" : "https://www.Baaziadda.com/",
                "alternate": "https://m.Baaziadda.com/",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "onlineCasinoGames": {
                "title": "Online Casino Games in India | Win Real Money in India",
                "description": "With our Online Casino Games, Win real money and experience the thrill of gaming like never before. Join us at BaaziAdda for an unforgettable journey to riches!",
                "canonical": "https://www.Baaziadda.com/online-casino-games",
                "alternate": "https://m.Baaziadda.com/online-casino-games",
                "keywords": "online casino games,online casino real money,games casino,casino gaming,online casino,online casino india,casino games online,online casino for real money,live casino online,no deposit bonus casino,best online casino in india,online casino india real money,best online casinos in india,live casino india,casino with bonus,bet online casino,best casino apps in india,best casinos in india,best online casino,online betting games,casino games name,online casino games real money,casio india online,online casino app,online casino games india,online gambling games,casino for real money"
            },
            "live-blackjack": {
                "title": "Blackjack Online Casino Game - Play & Win ₹₹₹",
                "description": "Blackjack: Turn your skills into real money rewards! Play and win. The cards are in your favor – are you ready?",
                "canonical": "https://www.Baaziadda.com/online-casino-games/live-blackjack",
                "alternate": "https://m.Baaziadda.com/online-casino-games/live-blackjack",
                "keywords": "blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "roulette": {
                "title": "Play Roulette Online for Free & Real Money - BaaziAdda",
                "description": "Spin the wheel of fortune! Play roulette online for free or real money at BaaziAdda. Unleash the excitement and test your luck today for a chance to Win Big!",
                "canonical": "https://www.Baaziadda.com/online-casino-games/live-roulette",
                "alternate": "https://m.Baaziadda.com/online-casino-games/live-roulette",
                "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "lightningroulette": {
                "title": "Play Lightning Roulette Online | Win Real Money in India",
                "description": "Spin the wheel of fortune with Lightning Roulette online at BaaziAdda! Win real money in India as you feel the electrifying thrill of every spin.",
                "canonical": "https://baaziadda.com/online-casino-games/lightning-roulette",
                "alternate": "https://m.baaziadda.com/online-casino-games/lightning-roulette",
                "keywords": "lightning roulette game,lightning roulette online,casino lightning roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "dragonTiger": {
                "title": "Dragon Tiger Online | Play for Real Money at BaaziAdda",
                "description": "Enter the mystical world of Dragon Tiger online. Play for real money at BaaziAdda and let the fortunes unfold.",
                "canonical": "https://www.Baaziadda.com/online-casino-games/dragontiger",
                "alternate": "https://m.Baaziadda.com/online-casino-games/dragontiger",
                "keywords": "Baaziadda is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
            },
            "baccarat": {
                "title": "Play Baccarat Online for Free & Real Money in India 2024",
                "description": "Play for free or try your luck with real money in India 2024. Join BaaziAdda for an immersive gaming experience and big wins!",
                "canonical": "https://www.Baaziadda.com/online-casino-games/live-baccarat",
                "alternate": "https://m.Baaziadda.com/online-casino-games/live-baccarat",
                "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
            },
            "andarBahar": {
                "title": "Play Andar Bahar Online for FREE or Real Money - BaaziAdda",
                "description": "Play Andar Bahar Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Andar Bahar Live now!",
                "canonical": "https://Baaziadda.com/online-casino-games/andar-bahar",
                "alternate": "https://m.Baaziadda.com/online-casino-games/andar-bahar",
                "keywords": "Andar Bahar game,Andar Bahar online,Andar Bahar casino,Andar Bahar gaming,Andar Bahar rule,Andar Bahar game online,Andar Bahar game rules,play Andar Bahar online,Andar Bahar online play,Andar Bahar online free,best Andar Bahar online casino,best casino for Andar Bahar"
            },
            "dreamCatcher": {
                "title": "Play Dream Catcher Online for FREE or Real Money - BaaziAdda",
                "description": "Play Dream Catcher Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Dream Catcher Live now!",
                "canonical": "https://Baaziadda.com/online-casino-games/dream-catcher",
                "alternate": "https://m.Baaziadda.com/online-casino-games/dream-catcher",
                "keywords": "Dream Catcher game,Dream Catcher online,Dream Catcher casino,Dream Catcher gaming,Dream Catcher rule,Dream Catcher game online,Dream Catcher game rules,play Dream Catcher online,Dream Catcher online play,Dream Catcher online free,best Dream Catcher online casino,best casino for Dream Catcher"
            },
            "hilo": {
                "title": "Play Hilo Online for FREE or Real Money - BaaziAdda",
                "description": "Play Hilo Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Hilo Live now!",
                "canonical": "https://Baaziadda.com/online-casino-games/hi-lo",
                "alternate": "https://m.Baaziadda.com/online-casino-games/hi-lo",
                "keywords": "Hilo game,Hilo online,Hilo casino,Hilo gaming,Hilo rule,Hilo game online,Hilo game rules,play Hilo online,Hilo online play,Hilo online free,best Hilo online casino,best casino for Hilo"
            },
            "teenpattiOnlinePlay": {
                "title": "Teen Patti Online | Win Real Money in India - BaaziAdda",
                "description": "Embrace the spirit of Teen Patti online at BaaziAdda! Win real money in India as you play this beloved card game. Play Now!",
                "canonical": "https://www.baaziadda.com/teenpatti-online-play",
                "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
            },
            "sportsBetting": {
                "title": "Betting Exchange | Everything you need to Win at Betting",
                "description": "Find everything you need to elevate your betting game and maximize your wins. Take control of your bets and conquer the exchange!",
                "canonical": "https://www.Baaziadda.com/betting-exchange",
                "alternate": "https://m.Baaziadda.com/betting-exchange?id=0",
                "keywords": "sports betting,sports satta,sports betting app,sports betting in india,bet on sports online,bet on sports,sports book,sportsbetting,sports bet,sports betting sites,best sports betting apps,betting sites with free registration bonus,best sports betting sites,online cricket satta bazar,online cricket betting,cricket satta,cricket betting app,cricket online betting apps,cricket betting,cricket betting sites,best cricket betting app,cricket betting apps in india,best cricket betting apps in india,cricket betting sites,live cricket betting,cricket betting app download,indian cricket betting sites,best betting sites for cricket in india,cricket bet online india,best cricket betting sites,cricket betting website,cricket online betting sites,online betting apps cricket,cricket betting online sites in india,football betting,best football prediction sites,betting app for football,football prediction site,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites,tennis betting,today tennis match prediction,betting on tennis tips,predictions tennis,tennis predictions today,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "onlineCricketSattaBazar": {
                "title": "Online Cricket Betting in India | Best Odds at BaaziAdda",
                "description": "Elevate your game with online cricket betting at BaaziAdda! Enjoy the best odds and dive into the excitement of cricket. Play Now!",
                "canonical": "https://www.Baaziadda.com/betting-exchange/online-cricket-satta-bazar",
                "alternate": "https://m.Baaziadda.com/betting-exchange/online-cricket-satta-bazar",
                "keywords": "Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Baaziadda online cricket betting, Baaziadda cricket betting odds, Baaziadda cricket betting, cricket betting sites, online cricket satta bazar",
            },
            "footballBetting": {
                "title": "Football Betting Online in India | Best Odds at BaaziAdda",
                "description": "Explore the best odds and immerse yourself in the thrill of the game. Bet on your favorite teams and players for a chance to win real money in India!",
                "canonical": "https://www.Baaziadda.com/betting-exchange/football-betting",
                "alternate": "https://m.Baaziadda.com/betting-exchange/football-betting",
                "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
            },
            "tennisBetting": {
                "title": "Online Tennis Betting in India | Best Odds at BaaziAdda",
                "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
                "canonical": "https://www.Baaziadda.com/betting-exchange/tennis-betting",
                "alternate": "https://m.Baaziadda.com/betting-exchange/tennis-betting",
                "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "volleyballBetting": {
                "title": "Volleyball Betting in India | Best Odds at BaaziAdda",
                "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
                "canonical": "https://Baaziadda.club/betting-exchange/volleyball-betting",
                "alternate": "https://m.Baaziadda.club/betting-exchange/volleyball-betting",
                "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
            },
            "promotions": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at Baaziadda & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://www.Baaziadda.com/promotions",
                "alternate" : "https://m.Baaziadda.com/promotions",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "popular games": {
                "title": "Best Casino Games to Win Money Online | Play Now & Win Big",
                "description": "Discover the Best Casino Games to Win money online! Unleash the thrill of gaming and turn your luck into real money rewards. Let the winning begin!",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/populargames",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/populargames",
                "keywords" : "popular games in india, popular pc games, popular video games"
            },
            "slotGames": {
                "title": "Play Slot Games Online & Win Real Money | BaaziAdda",
                "description": "Spin the reels and win big! Play Slot Games Online at BaaziAdda to win real money. Your jackpot awaits – start spinning now and let the winnings roll in!",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/slotGames",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/slotGames",
                "keywords" : "Slot games, Online slots, casino slot games"
            },
            "aviator": {
                "title": "Play Aviator Casino Game Online 🕹️ at BaaziAdda",
                "description": "Take off with excitement! Play the Aviator Casino Game online at BaaziAdda. Get ready for a gaming experience like no other, where big wins await.",
                "canonical" : "https://www.Baaziadda.com/instant-games/aviator",
                "alternate" : "https://m.Baaziadda.com/instant-games/aviator",
                "keywords" : "Aviator game, Aviator online game "
            },
            "cashshow": {
                "title": "Cash Show Online | Play at BaaziAdda and Win Big",
                "description": "Join the Cash Show online at BaaziAdda and claim your chance to win big! Play for real money and showcase your skills in this exciting game.",
                "canonical" : "https://www.baaziadda.com/instant-games/cashshow",
                "alternate": "https://m.baaziadda.com/instant-games/cashshow",
                "keywords" : "Cash Show game, Cash Show online game "
            },
            "jetx": {
                "title": "JetX Online | Play for Real Money at BaaziAdda",
                "description": "Rev up the thrill with JetX online at BaaziAdda. Play for real money and experience the rush of high-speed gaming.",
                "canonical" : "https://www.baaziadda.com/instant-games/jetx",
                "alternate": "https://m.baaziadda.com/instant-games/jetx",
                "keywords" : "Jet X game, Jet X online game"
            },
            "rockpaperscissors": {
                "title": "Rockpaperscissors ID - Grab Your Exclusive Deals Now!",
                "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
                "canonical" : "https://baaziadda.com/instant-games/rock-paper-scissors",
                "alternate": "https://baaziadda.com/instant-games/rock-paper-scissors",
                "keywords" : "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
            },
            "plinkox": {
                "title": "PlinkoX Game Online | Play for Real Money at BaaziAdda",
                "description": "Drop, win, repeat! Immerse yourself in the PlinkoX game online at BaaziAdda. Play for real money.",
                "canonical" : "https://www.baaziadda.com/instant-games/plinko-x",
                "alternate": "https://m.baaziadda.com/instant-games/plinko-x",
                "keywords" : "Plinkox game, Plinkox online game "
            },
            "footballx": {
                "title": "Football X Game | Live Casino Online Games on baaziadda",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on baaziadda.",
                "canonical" : "https://www.baaziadda.com/instant-games/football-x",
                "alternate": "https://m.baaziadda.com/instant-games/football-x",
                "keywords" : "Football X game, Football X online game "
            },
            "balloon": {
                "title": "Balloon Game | Live Casino Online Games on baaziadda",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on baaziadda.",
                "canonical" : "https://www.baaziadda.com/instant-games/balloon",
                "alternate": "https://m.baaziadda.com/instant-games/balloon",
                "keywords" : "Balloon game, Balloon online game "
            },
            "mines": {
                "title": "Play Mines Online | Play for Real Money at BaaziAdda",
                "description": "Unearth treasures and win real money! Play Mines online at BaaziAdda and experience the excitement of this thrilling game.",
                "canonical" : "https://www.baaziadda.com/instant-games/mines",
                "alternate": "https://m.baaziadda.com/instant-games/mines",
                "keywords" : "Mines game, Online mines game"
            },
            "ninjacrash": {
                "title": "Plan NinjaCrash Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://www.baaziadda.com/instant-games/ninja-crash",
                "alternate": "https://m.baaziadda.com/instant-games/ninja-crash",
                "keywords" : "NinjaCrash game, Online NinjaCrash game"
            },
            "aviatrix": {
                "title": "Aviatrix Casino Game Online | Play Now & Win Big",
                "description": "Embark on a thrilling adventure with Aviatrix Casino Game online. Play now at BaaziAdda and seize the opportunity to win big.",
                "canonical" : "https://www.baaziadda.com/instant-games/aviatrix",
                "alternate": "https://m.baaziadda.com/instant-games/aviatrix",
                "keywords" : "aviatrix game, Online aviatrix game"
            },
            "rocketon": {
                "title": "Play Rocketon Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Rocketon! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://baaziadda.com/instant-games/rocketon",
                "alternate": "https://m.baaziadda.com/instant-games/rocketon",
                "keywords" : "Rocketon game, Online Rocketon game"
            },
            "burningIce": {
                "title": "Play BurningIce Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in BurningIce! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://baaziadda.com/instant-games/burning-ice",
                "alternate": "https://m.baaziadda.com/instant-games/burning-ice",
                "keywords" : "BurningIce game, Online BurningIce game"
            },
            "cappaDocia": {
                "title": "Play Cappa Docia Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Cappa Docia! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://baaziadda.com/instant-games/cappa-docia",
                "alternate": "https://m.baaziadda.com/instant-games/cappa-docia",
                "keywords" : "Cappa Docia game, Online Cappa Docia game"
            },
            "geniesBonanza": {
                "title": "Play GeniesBonanza Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in GeniesBonanza! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://baaziadda.com/instant-games/genies-bonanza",
                "alternate": "https://m.baaziadda.com/instant-games/genies-bonanza",
                "keywords" : "GeniesBonanza game, Online GeniesBonanza game"
            },
            "goal": {
                "title": "Play Goal Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Goal! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://baaziadda.com/instant-games/goal",
                "alternate": "https://m.baaziadda.com/instant-games/goal",
                "keywords" : "Goal game, Online Goal game"
            },
            "hunterx": {
                "title": "Play Hunterx Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Hunterx! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://baaziadda.com/instant-games/hunter-x",
                "alternate": "https://m.baaziadda.com/instant-games/hunter-x",
                "keywords" : "Hunterx game, Online Hunterx game"
            },
            "jungles": {
                "title": "Play Jungles Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Jungles! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://baaziadda.com/instant-games/jungles",
                "alternate": "https://m.baaziadda.com/instant-games/jungles",
                "keywords" : "Jungles game, Online Jungles game"
            },
            "magicGarden": {
                "title": "Play Magic Garden Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Magic Garden! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://baaziadda.com/instant-games/magic-garden",
                "alternate": "https://m.baaziadda.com/instant-games/magic-garden",
                "keywords" : "Magic Garden game, Online Magic Garden game"
            },
            "multiHotWays": {
                "title": "Play Multi Hot Ways Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Multi Hot Ways! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://baaziadda.com/instant-games/multi-hot-ways",
                "alternate": "https://m.baaziadda.com/instant-games/multi-hot-ways",
                "keywords" : "Multi Hot Ways game, Online Multi Hot Ways game"
            },
            "paperPlane": {
                "title": "Play Paper Plane Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Paper Plane! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://baaziadda.com/instant-games/paper-plane",
                "alternate": "https://m.baaziadda.com/instant-games/paper-plane",
                "keywords" : "Paper Plane game, Online Paper Plane game"
            },
            "stockMarket": {
                "title": "Play Stock Market Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Stock Market! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://baaziadda.com/instant-games/stock-market",
                "alternate": "https://m.baaziadda.com/instant-games/stock-market",
                "keywords" : "Stock Market game, Online Stock Market game"
            },
            "macludo": {
                "title": "Ludo Game | Play Ludo Game Online for real cash",
                "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/macludo",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/mac%20ludo",
                "keywords" : "Ludo Game, Ludo game Online"
            },
            "crazytime": {
                "title": "Play CrazyTime Online for Free & Real Money - BaaziAdda",
                "description": "Dive into the whirlwind of excitement with CrazyTime online at BaaziAdda! Brace yourself for a gaming experience like no other – it's time to unleash the craziness and win big!",
                "canonical" : "https://www.baaziadda.com/online-casino-games/crazy-time",
                "alternate" : "https://m.baaziadda.com/online-casino-games/crazy-time",
                "keywords" : "Crazytime Game, Crazytime game Online"
            },
            "monopolylive": {
                "title": "Monopoly Live, Win Real Money | BaaziAdda",
                "description": "Step into the world of Monopoly Live and win real money at BaaziAdda. Play now for a chance to build your empire and claim victory!",
                "canonical" : "https://baaziadda.com/online-casino-games/monopoly-live",
                "alternate": "https://m.baaziadda.com/online-casino-games/monopoly-live",
                "keywords" : "MONOPOLY LIVE"
            },
            "funkytime": {
                "title": "Funkytime: Play at BaaziAdda & Win Real Money Online",
                "description": "Play Funkytime and win real money online. Unleash your groove and enjoy a unique gaming experience. Let the funky wins roll in!",
                "canonical": "https://baaziadda.com/online-casino-games/Funkytime",
                "alternate": "https://m.baaziadda.com/online-casino-games/Funkytime",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "lightningdice": {
                "title": "Lightningdice | Play at BaaziAdda & Win Real Money Online",
                "description": "Feel the electrifying thrill of Lightningdice at BaaziAdda! Play and win real money online as you roll the lightning-infused dice.",
                "canonical" : "https://baaziadda.com/online-casino-games/lightning-dice",
                "alternate": "https://m.baaziadda.com/online-casino-games/lightning-dice",
                "keywords" : "Lightning Dice"
            },
            "cricketx": {
                "title": "Play CricketX Live Online & Win Real Money at BaaziAdda",
                "description": "Experience the excitement of CricketX Live at BaaziAdda! Play online, showcase your cricket skills, and win real money.",
                "canonical" : "https://baaziadda.com/instant-games/cricket-x",
                "alternate": "https://m.baaziadda.com/instant-games/cricket-x",
                "keywords" : "Cricketx Games,BaaziAdda Cricketx games,BaaziAdda Cricketx games,Cricketx casino games"
            },
            "virtualSports": {
                "title": "Online Betting for Sports and Casino at BaaziAdda",
                "description": "Experience the ultimate thrill with online betting for sports and casino at BaaziAdda! Enjoy the best of both worlds as you bet on your favorite sports and casino games.",
                "canonical" : "https://baaziadda.com/virtualSports/vci",
                "alternate": "https://baaziadda.com/virtualSports/vci",
            },
            "virtualSportsBL": {
                "title": "Virtual Sports on Baazi Adda",
                "description": "Virtual Sports on Baazi Adda",
                "canonical" : "https://baaziadda.com/virtualSports/vbl",
                "alternate": "https://baaziadda.com/virtualSports/vbl",
            },
            "virtualSportsFB": {
                "title": "Virtual Sports on Baazi Adda",
                "description": "Virtual Sports on Baazi Adda",
                "canonical" : "https://baaziadda.com/virtualSports/vfb",
                "alternate": "https://baaziadda.com/virtualSports/vfb",
            },
            "virtualSportsFEL": {
                "title": "Virtual Sports on Baazi Adda",
                "description": "Virtual Sports on Baazi Adda",
                "canonical" : "https://baaziadda.com/virtualSports/vfel",
                "alternate": "https://baaziadda.com/virtualSports/vfel",
            },
            "virtualSportsFEC": {
                "title": "Virtual Sports on Baazi Adda",
                "description": "Virtual Sports on Baazi Adda",
                "canonical" : "https://baaziadda.com/virtualSports/vfec",
                "alternate": "https://m.baaziadda.com/virtualSports/vfec",
            },
            "virtualSportsVTI": {
                "title": "Elevate Your Game with Virtual Tennis Betting ID",
                "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
                "canonical" : "https://baaziadda.com/virtualSports/vti",
                "alternate": "https://baaziadda.com/virtualSports/vti",
            },
            "virtualSportsWC": {
                "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
                "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
                "canonical" : "https://baaziadda.com/virtualSports/vfwc",
                "alternate": "https://baaziadda.com/virtualSports/vfwc",
            },
            "premiumSportsbook": {
                "title": "Premium Sportsbook | Online Sports Betting at BaaziAdda",
                "description": "Go for gold with our Premium Sportsbook at BaaziAdda! Engage in online sports betting with the best odds and premium features.",
                "canonical" : "https://baaziadda.com/premium-sportsbook",
                "alternate": "https://m.baaziadda.com/premium-sportsbook",
                "keywords" : "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
            },
            "welcomeBonus": {
                "title": "400% Welcome Bonus to get you started (2024)💰",
                "description": "Claim your fortune with a whopping 400% Welcome Bonus in 2024 at BaaziAdda! 🎉 Your journey to big wins starts with an unbeatable bonus! Sign up now!",
                "canonical" : "https://www.Baaziadda.com/promotions/welcome-bonus",
                "alternate" : "https://m.Baaziadda.com/promotions/welcome-bonus",
                "keywords" : "Welcome Bonus"
            },
            "refillBonus": {
                "title": "Get the BaaziAdda Refill Bonus 💰 and Win Big!",
                "description": "Refill your luck and Win Big with BaaziAdda's Refill Bonus! 💰 Enjoy extra playing power. It's the perfect boost to increase your chances of winning!",
                 "canonical" : "https://www.Baaziadda.com/promotions/refill-bonus",
                "alternate" : "https://m.Baaziadda.com/promotions/refill-bonus",
                "keywords" : "Refill bonus"
            },
            "cashbackBonus": {
                "title": "Cashback Bonus 💰 at BaaziAdda 2024",
                "description": "Enjoy the thrill of gaming with Cashback Bonus at BaaziAdda in 2024! 💰 It's your chance to turn every bet into a win!",
                "canonical" : "https://www.Baaziadda.com/promotions/cashback-bonus",
                "alternate" : "https://m.Baaziadda.com/promotions/cashback-bonus",
                "keywords" : "Cashback Bonus"
            },
            "referralBonus": {
                "title": "Referral Bonus 💰 at BaaziAdda 2024",
                "description": "Share the gaming joy with your friends, and when they join, you both reap the rewards 💰 It's a win-win – start referring now!",
                "canonical" : "https://Baaziadda.com/promotions/referral-bonus",
                "alternate": "https://m.Baaziadda.com/promotions/referral-bonus",
                "keywords" : "Referral Bonus"
            },
            "termsandconditions": {
                "title": "Terms & Conditions | BaaziAdda Online Casino",
                "description": "Read the Terms & Conditions at BaaziAdda Online Casino to ensure a smooth and fair gaming experience. Stay informed, play responsibly, and maximize your chances of winning!",
                "canonical" : "https://www.Baaziadda.com/about/terms-conditions",
                "alternate" : "https://m.Baaziadda.com/about/terms-conditions",
                "keywords" : "Terms & Conditions"
            },
            "responsibleGambling": {
                "title": "Responsible Gambling | BaaziAdda Online Casino",
                "description": "Dive into our Responsible Gambling policies to ensure a safe and enjoyable gaming environment. Your well-being is our top priority!",
                "canonical" : "https://www.Baaziadda.com/about/responsible-gambling",
                "alternate" : "https://m.Baaziadda.com/about/responsible-gambling",
                "keywords" : "Responsible Gambling"
            },
            "privacyPolicy": {
                "title": "Privacy Policy | BaaziAdda Online Casino",
                "description": "Your privacy matters! Discover how we safeguard your information with the Privacy Policy. Play with confidence, knowing that your data is secure and protected.",
                "canonical" : "https://www.Baaziadda.com/about/privacy-policy",
                "alternate" : "https://m.Baaziadda.com/about/privacy-policy",
                "keywords" : "privacy policy"
            },
            "kycPolicy": {
                "title": "KYC Policy | BaaziAdda Online Casino",
                "description": "Learn about our Know Your Customer policy at BaaziAdda. Ensuring a secure gaming space, we prioritize transparency and accountability to enhance your gaming.",
                "canonical" : "https://www.Baaziadda.com/about/kyc-policy",
                "alternate" : "https://m.Baaziadda.com/about/kyc-policy",
                "keywords" : "KYC Policy"
            },
            "exclusionPolicy": {
                "title": "Exclusion Policy | BaaziAdda Online Casino",
                "description": "Play responsibly with our Exclusion Policy. Learn about the tools we provide to promote responsible gaming and help you stay in control of your play.",
                "canonical" : "https://www.Baaziadda.com/about/self-exclusion-policy",
                "alternate" : "https://m.Baaziadda.com/about/self-exclusion-policy",
                "keywords" : "exclusion policy "
            },
            "amlPolicy": {
                "title": "AML Policy | BaaziAdda Online Casino",
                "description": "Explore our Anti-Money Laundering (AML) Policy. We are committed to maintaining the integrity of our platform for a fair and secure gaming experience.",
                "canonical" : "https://www.Baaziadda.com/about/aml-policy",
                "alternate" : "https://m.Baaziadda.com/about/aml-policy",
                "keywords" : "AML Policy"
            },
            "aboutus": {
                "title": "About Us | BaaziAdda Online Casino",
                "description": "Get to know us better! Explore the story behind BaaziAdda. Discover our mission, values, and commitment to providing an exceptional gaming experience.",
                "canonical" : "https://www.Baaziadda.com/about/about-us",
                "alternate" : "https://m.Baaziadda.com/about/about-us",
                "keywords" : "About us, About Baaziadda"
            },
            "faq": {
                "title": "Frequently Asked Questions | BaaziAdda Online Casino",
                "description": "Have questions? Find answers in our Frequently Asked Questions section at BaaziAdda Online Casino.",
                "canonical" : "https://baaziadda.com/faq",
                "alternate": "https://baaziadda.com/faq",
            },
            "affiliate": {
                "title": "Affiliate | BaaziAdda Online Casino",
                "description": "Join the BaaziAdda Affiliate program and turn your passion for gaming into rewards. Start earning commissions and enjoy the benefits of our rewarding partnership!",
                "canonical" : "https://baaziadda.com/affiliate",
                "alternate": "https://m.baaziadda.com/affiliate",
            },
            "bingo": {
                "title": "Play Bingo Online - endless fun & real money at BaaziAdda",
                "description": "Experience the thrill of playing bingo online at our top-rated gaming platform. Enjoy a wide variety of exciting bingo games & wing big",
                "canonical": "https://baaziadda.com/online-casino-games/bingo",
                "alternate": "https://m.baaziadda.com/online-casino-games/bingo",
                "keywords" : "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "SPRIBE": {
                "title": "Spribe Casino Games | Get the best Spribe Games on Baaziadda",
                "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
                "canonical" : "https://www.Baaziadda.com/instant-games/spribe",
                "alternate" : "https://m.Baaziadda.com/instant-games/spribe",
                "keywords" : "spribe casino games"
            },
            "7MOJOS": {
                "title": "7Mojos Games | Explore the top games at the best platform",
                "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/7mojos",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/7mojos",
                "keywords" : "7Mojos Games"
            },
            "supernowa": {
                "title": "Supernowa Games | Live Casino Games from Baaziadda",
                "description": "Supernowa games at the best! Play live casino games, online card games and slots on the safest gaming platform. Click to explore.",
                "canonical" : "https://m.Baaziadda.com/online-casino-games/supernowa",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/supernowa",
                "keywords" : "supernowa games"
            },
            "pragmatic": {
                "title": "Pragmatic Casino Games online| Play on Baaziadda",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/slotGames/pragmatic-play",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/slotGames/pragmatic-play",
                "keywords" : "Pragmatic casino, pragmatic games"
            },
            "superspade": {
                "title": "Super Spade Games | Online Casino on Baaziadda",
                "description": "Super Spade Games are online casino's delight. Play the best games with just one sign-in. Simple process, simpler registration. Join now!",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/super-spade",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/super-spade",
                "keywords" : "super spade games"
            },
            "tvbet": {
                "title": "TVBet Games | Live Betting & Gaming on Baaziadda",
                "description": "TV Bet Games offers a wide variety of online gaming options. Explore all the top-rated games in one platform through one sign-in. Join now!",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/tvbet",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/tvbet",
                "keywords" : "tvbet games"
            },
            "netent": {
                "title": "NetEnt Games | Slots & Casino Games on Baaziadda",
                "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/slotGames/netent",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/slotGames/netent",
                "keywords" : "netent games"
            },
            "betgames": {
                "title": "BetGames | Live Dealer of Online Games on Baaziadda",
                "description": "BetGames provides live casino games. Play a plethora of live casino games with one single registration. Faster withdrawal & 24x7 customer support.",
                "canonical" : "https://www.Baaziadda.com/online-casino-games/bet-games",
                "alternate" : "https://m.Baaziadda.com/online-casino-games/bet-games",
                "keywords" : "betgames"
            },
            "sportsbook": {
                "title": "Sportsbook Online | Sports Betting at BaaziAdda",
                "description": "Elevate your sports betting experience with Sportsbook Online at BaaziAdda! Enjoy a seamless and thrilling betting experience with the best odds.",
                "canonical" : "https://m.Baaziadda.com/sportsbook",
                "alternate" : "https://m.Baaziadda.com/sportsbook",
                "keywords" : "BTI Sports, BTI Sports Betting"
            },
            "appDownload": {
                "title": "BaaziAdda APK  App - Download & Win!",
                "description": "Check out the main reasons why you need to download the Baaziadda app on your device. Learn more about the benefits of our app, bonuses and other offers",
                "canonical" : "https://www.Baaziadda.com/app-download",
                "alternate" : "https://m.Baaziadda.com/app-download",
                "keywords" : "Baaziadda App Download, Baaziadda Android App"
            },
            "login": {
                "title": "Login BaaziAdda | Play Online Casino Games",
                "description": "Baaziadda Login now to play a wide range of exciting Online casino games and win big.don't miss out start playing today!",
                "canonical" : "https://www.Baaziadda.com/login",
                "alternate" : "https://m.Baaziadda.com/login",
                "keywords" : "Baaziadda Login"
            },
            "signup": {
                "title": "Sign Up Now - Baaziadda & explore Online Casino Games",
                "description": "Signup at the Baaziadda official website today for access to premium Sports & Casino Games",
                "canonical" : "https://baaziadda.com/signup",
                "alternate": "https://m.baaziadda.com/signup",
                "keywords" : "Baaziadda User Registration"
            },
            "instantGames": {
                "title": "Instant Games | Enjoy the Best Instant Casino Games Now!",
                "description": " Experience the thrill of instant games and win big in seconds! Play a wide variety of exciting casino games for instant entertainment & instant rewards. Join & start winning instantly!.",
                "canonical" : "https://www.Baaziadda.com/instant-games",
                "alternate": "https://m.Baaziadda.com/instant-games",
                "keywords" : "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "dailyDepositBonus": {
                "title": "Baaziadda Daily Deposit Bonus | Best Casino Daily Deposit Scheme",
                "description": "Baaziadda Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Baaziadda.",
                "canonical" : "https://www.Baaziadda.com/daily-deposit-bonus",
                "alternate": "https://m.Baaziadda.com/daily-deposit-bonus",
                "keywords" : "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "asiaCupBonanza": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on Baaziadda provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Baaziadda online cricket betting, Baaziadda cricket betting odds, Baaziadda cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical" : "https://www.baaziadda.com/asia-cup-bonanza",
                "alternate": "https://m.baaziadda.com/asia-cup-bonanza"
            },
            "big-bash": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://www.baaziadda.com/promotions/big-bash",
                "alternate": "https://m.baaziadda.com/promotions/big-bash",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
        },
        "34":{
            "default": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at Betdaily & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://www.betdaily.club/",
                "alternate" : "https://m.betdaily.club/",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "onlineCasinoGames": {
                "title": "Online Casino Games (Real Money) - Play with Any Currency",
                "description": "Experience the ultimate online casino games in India at Betdaily. Play online casino for real money and enjoy the thrill of gambling with exciting bonus offers.",
                "canonical": "https://www.betdaily.club/online-casino-games",
                "alternate": "https://m.betdaily.club/online-casino-games",
                "keywords": "online casino games,online casino real money,games casino,casino gaming,online casino,online casino india,casino games online,online casino for real money,live casino online,no deposit bonus casino,best online casino in india,online casino india real money,best online casinos in india,live casino india,casino with bonus,bet online casino,best casino apps in india,best casinos in india,best online casino,online betting games,casino games name,online casino games real money,casio india online,online casino app,online casino games india,online gambling games,casino for real money"
            },
            "live-blackjack": {
                "title": "Play Blackjack Online at Betdaily (Don't Get Bored Again)",
                "description": "Play blackjack online on the most safest online casino. Register now at Betdaily and get exclusive welcome bonus and offers to win your favorite games",
                "canonical": "https://www.betdaily.club/online-casino-games/live-blackjack",
                "alternate": "https://m.betdaily.club/online-casino-games/live-blackjack",
                "keywords": "blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "roulette": {
                "title": "Play Roulette Game Online - Free Registration & Instant Payouts",
                "description": "Betdaily is the leading online casino to play roulette game in India. Play the best real money games here. Sign up now and win welcome bonus!",
                "canonical": "https://www.betdaily.club/online-casino-games/live-roulette",
                "alternate": "https://m.betdaily.club/online-casino-games/live-roulette",
                "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "lightningroulette": {
                "title": "Play Lightning Roulette Online | Win Real Money in India",
                "description": "Spin the wheel of fortune with Lightning Roulette online at Betdaily! Win real money in India as you feel the electrifying thrill of every spin.",
                "canonical": "https://betdaily.club/online-casino-games/lightning-roulette",
                "alternate": "https://m.betdaily.club/online-casino-games/lightning-roulette",
                "keywords": "lightning roulette game,lightning roulette online,casino lightning roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "dragonTiger": {
                "title": "Online Dragon Tiger Game - Get Instant Wins 💸 at Betdaily",
                "description": "Betdaily is the leading online casino to play roulette game in India. Play the best real money games here. Sign up now and win welcome bonus!",
                "canonical": "https://www.betdaily.club/online-casino-games/dragon-tiger",
                "alternate": "https://m.betdaily.club/online-casino-games/dragon-tiger",
                "keywords": "Betdaily is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
            },
            "baccarat": {
                "title": "Play Online Baccarat Game - Get the Best Odds at Betdaily",
                "description": "Play baccarat online game with the latest baccarat rules and winnings to suit your taste. Crafted by experts, the Betdaily baccarat casino is just a click away. Play now!!",
                "canonical": "https://www.betdaily.club/online-casino-games/live-baccarat",
                "alternate": "https://m.betdaily.club/online-casino-games/live-baccarat",
                "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
            },
            "andarBahar": {
                "title": "Play Andar Bahar Online for FREE or Real Money - Betdaily",
                "description": "Play Andar Bahar Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Andar Bahar Live now!",
                "canonical": "https://betdaily.club/online-casino-games/andar-bahar",
                "alternate": "https://m.betdaily.club/online-casino-games/andar-bahar",
                "keywords": "Andar Bahar game,Andar Bahar online,Andar Bahar casino,Andar Bahar gaming,Andar Bahar rule,Andar Bahar game online,Andar Bahar game rules,play Andar Bahar online,Andar Bahar online play,Andar Bahar online free,best Andar Bahar online casino,best casino for Andar Bahar"
            },
            "dreamCatcher": {
                "title": "Play Dream Catcher Online for FREE or Real Money - Betdaily",
                "description": "Play Dream Catcher Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Dream Catcher Live now!",
                "canonical": "https://betdaily.club/online-casino-games/dream-catcher",
                "alternate": "https://m.betdaily.club/online-casino-games/dream-catcher",
                "keywords": "Dream Catcher game,Dream Catcher online,Dream Catcher casino,Dream Catcher gaming,Dream Catcher rule,Dream Catcher game online,Dream Catcher game rules,play Dream Catcher online,Dream Catcher online play,Dream Catcher online free,best Dream Catcher online casino,best casino for Dream Catcher"
            },
            "hilo": {
                "title": "Play Hilo Online for FREE or Real Money - Betdaily",
                "description": "Play Hilo Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Hilo Live now!",
                "canonical": "https://betdaily.club/online-casino-games/hi-lo",
                "alternate": "https://m.betdaily.club/online-casino-games/hi-lo",
                "keywords": "Hilo game,Hilo online,Hilo casino,Hilo gaming,Hilo rule,Hilo game online,Hilo game rules,play Hilo online,Hilo online play,Hilo online free,best Hilo online casino,best casino for Hilo"
            },
            "teenpattiOnlinePlay": {
                "title": "Teen Patti Online | Win Real Money in India - Betdaily",
                "description": "Embrace the spirit of Teen Patti online at Betdaily! Win real money in India as you play this beloved card game. Play Now!",
                "canonical": "https://www.betdaily.club/teenpatti-online-play",
                "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
            },
            "sportsBetting": {
                "title": "Betting Exchange | Everything you need to Win at Betting",
                "description": "Find everything you need to elevate your betting game and maximize your wins. Take control of your bets and conquer the exchange!",
                "canonical": "https://www.betdaily.club/betting-exchange",
                "alternate": "https://m.betdaily.club/betting-exchange",
                "keywords": "sports betting,sports satta,sports betting app,sports betting in india,bet on sports online,bet on sports,sports book,sportsbetting,sports bet,sports betting sites,best sports betting apps,betting sites with free registration bonus,best sports betting sites,online cricket satta bazar,online cricket betting,cricket satta,cricket betting app,cricket online betting apps,cricket betting,cricket betting sites,best cricket betting app,cricket betting apps in india,best cricket betting apps in india,cricket betting sites,live cricket betting,cricket betting app download,indian cricket betting sites,best betting sites for cricket in india,cricket bet online india,best cricket betting sites,cricket betting website,cricket online betting sites,online betting apps cricket,cricket betting online sites in india,football betting,best football prediction sites,betting app for football,football prediction site,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites,tennis betting,today tennis match prediction,betting on tennis tips,predictions tennis,tennis predictions today,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "onlineCricketSattaBazar": {
                "title": "Online Cricket Betting in India | Best Odds at Betdaily",
                "description": "Elevate your game with online cricket betting at Betdaily! Enjoy the best odds and dive into the excitement of cricket. Play Now!",
                "keywords": "Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Betdaily online cricket betting, Betdaily cricket betting odds, Betdaily cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical": "https://www.betdaily.club/betting-exchange/online-cricket-satta-bazar",
                "alternate": "https://m.betdaily.club/betting-exchange/online-cricket-satta-bazar"
            },
            "footballBetting": {
                "title": "Football Betting Online in India | Best Odds at Betdaily",
                "description": "Explore the best odds and immerse yourself in the thrill of the game. Bet on your favorite teams and players for a chance to win real money in India!",
                "canonical": "https://www.betdaily.club/betting-exchange/football-betting",
                "alternate": "https://m.betdaily.club/betting-exchange/football-betting",
                "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
            },
            "tennisBetting": {
                "title": "Online Tennis Betting in India | Best Odds at Betdaily",
                "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
                "canonical": "https://www.betdaily.club/betting-exchange/tennis-betting",
                "alternate": "https://m.betdaily.club/betting-exchange/tennis-betting",
                "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "volleyballBetting": {
                "title": "Volleyball Betting in India | Best Odds at Betdaily",
                "description": "Enjoy the best odds and experience the intensity of every match. Bet on your favorite players and win real money in India with every ace!",
                "canonical": "https://betdaily.club/betting-exchange/volleyball-betting",
                "alternate": "https://m.betdaily.club/betting-exchange/volleyball-betting",
                "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
            },
            "promotions": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at Betdaily & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://www.betdaily.club/promotions",
                "alternate" : "https://m.betdaily.club/promotions",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "popular games": {
                "title": "Best Casino Games Online | Get Instant Welcome Bonus & Offer",
                "description": "Explore the most popular and best casino casino online at Betdaily. Choose from thousands of classic casino casino, including online slots, blackjack and roulette.",
                "canonical" : "https://www.betdaily.club/online-casino-games/populargames",
                "alternate" : "https://m.betdaily.club/online-casino-games/populargames",
                "keywords" : "popular games in india, popular pc games, popular video games"
            },
            "slotGames": {
                "title": "Play Slot Games - Online Slots | Betdaily",
                "description": "Slot Games at the best! Play online slots with easy registration and easier withdrawal process. Get 100+ casino slot games to choose from.",
                "canonical" : "https://www.betdaily.club/online-casino-games/slotGames",
                "alternate" : "https://m.betdaily.club/online-casino-games/slotGames",
                "keywords" : "Slot games, Online slots, casino slot games"
            },
            "aviator": {
                "title": "Play Aviator Casino Game Online 🕹️ at Betdaily",
                "description": "Take off with excitement! Play the Aviator Casino Game online at Betdaily. Get ready for a gaming experience like no other, where big wins await.",
                "canonical" : "https://www.betdaily.club/instant-games/aviator",
                "alternate" : "https://m.betdaily.club/instant-games/aviator",
                "keywords" : "Aviator game, Aviator online game "
            },
            "cashshow": {
                "title": "Play Aviator Casino Game Online 🕹️ at Betdaily",
                "description": "Take off with excitement! Play the Aviator Casino Game online at Betdaily. Get ready for a gaming experience like no other, where big wins await.",
                "canonical" : "https://www.betdaily.club/instant-games/cashshow",
                "alternate": "https://m.betdaily.club/instant-games/cashshow",
                "keywords" : "Cash Show game, Cash Show online game "
            },
            "jetx": {
                "title": "JetX Online | Play for Real Money at Betdaily",
                "description": "Rev up the thrill with JetX online at Betdaily. Play for real money and experience the rush of high-speed gaming.",
                "canonical" : "https://www.betdaily.club/instant-games/jetx",
                "alternate": "https://m.betdaily.club/instant-games/jetx",
                "keywords" : "Jet X game, Jet X online game"
            },
            "rockpaperscissors": {
                "title": "Rockpaperscissors ID - Grab Your Exclusive Deals Now!",
                "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
                "canonical" : "https://betdaily.club/instant-games/rock-paper-scissors",
                "alternate": "https://betdaily.club/instant-games/rock-paper-scissors",
                "keywords" : "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
            },
            "plinkox": {
                "title": "Plinkox Game | Live Casino Online Games on Betdaily",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
                "canonical" : "https://www.betdaily.club/instant-games/plinko-x",
                "alternate": "https://m.betdaily.club/instant-games/plinko-x",
                "keywords" : "Plinkox game, Plinkox online game "
            },
            "footballx": {
                "title": "Football X Game | Live Casino Online Games on Betdaily",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
                "canonical" : "https://www.betdaily.club/instant-games/football-x",
                "alternate": "https://m.betdaily.club/instant-games/football-x",
                "keywords" : "Football X game, Football X online game "
            },
            "balloon": {
                "title": "Balloon Game | Live Casino Online Games on Betdaily",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
                "canonical" : "https://www.betdaily.club/instant-games/balloon",
                "alternate": "https://m.betdaily.club/instant-games/balloon",
                "keywords" : "Balloon game, Balloon online game "
            },
            "mines": {
                "title": "Mines Game | Live Casino Online Games on Betdaily",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on Betdaily.",
                "canonical" : "https://www.betdaily.club/instant-games/mines",
                "alternate": "https://m.betdaily.club/instant-games/mines",
                "keywords" : "Mines game, Online mines game "
            },
            "ninjacrash": {
                "title": "Plan NinjaCrash Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in NinjaCrash! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://www.betdaily.club/instant-games/ninja-crash",
                "alternate": "https://m.betdaily.club/instant-games/ninja-crash",
                "keywords" : "NinjaCrash game, Online NinjaCrash game"
            },
            "aviatrix": {
                "title": "Aviatrix Casino Game Online | Play Now & Win Big",
                "description": "Embark on a thrilling adventure with Aviatrix Casino Game online. Play now at Betdaily and seize the opportunity to win big.",
                "canonical" : "https://www.betdaily.club/instant-games/aviatrix",
                "alternate": "https://m.betdaily.club/instant-games/aviatrix",
                "keywords" : "aviatrix game, Online aviatrix game"
            },
            "rocketon": {
                "title": "Play Rocketon Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Rocketon! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://betdaily.club/instant-games/rocketon",
                "alternate": "https://m.betdaily.club/instant-games/rocketon",
                "keywords" : "Rocketon game, Online Rocketon game"
            },
            "burningIce": {
                "title": "Play BurningIce Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in BurningIce! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://betdaily.club/instant-games/burning-ice",
                "alternate": "https://m.betdaily.club/instant-games/burning-ice",
                "keywords" : "BurningIce game, Online BurningIce game"
            },
            "cappaDocia": {
                "title": "Play Cappa Docia Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Cappa Docia! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://betdaily.club/instant-games/cappa-docia",
                "alternate": "https://m.betdaily.club/instant-games/cappa-docia",
                "keywords" : "Cappa Docia game, Online Cappa Docia game"
            },
            "geniesBonanza": {
                "title": "Play GeniesBonanza Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in GeniesBonanza! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://betdaily.club/instant-games/genies-bonanza",
                "alternate": "https://m.betdaily.club/instant-games/genies-bonanza",
                "keywords" : "GeniesBonanza game, Online GeniesBonanza game"
            },
            "goal": {
                "title": "Play Goal Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Goal! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://betdaily.club/instant-games/goal",
                "alternate": "https://m.betdaily.club/instant-games/goal",
                "keywords" : "Goal game, Online Goal game"
            },
            "hunterx": {
                "title": "Play Hunterx Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Hunterx! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://betdaily.club/instant-games/hunter-x",
                "alternate": "https://m.betdaily.club/instant-games/hunter-x",
                "keywords" : "Hunterx game, Online Hunterx game"
            },
            "jungles": {
                "title": "Play Jungles Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Jungles! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://betdaily.club/instant-games/jungles",
                "alternate": "https://m.betdaily.club/instant-games/jungles",
                "keywords" : "Jungles game, Online Jungles game"
            },
            "magicGarden": {
                "title": "Play Magic Garden Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Magic Garden! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://betdaily.club/instant-games/magic-garden",
                "alternate": "https://m.betdaily.club/instant-games/magic-garden",
                "keywords" : "Magic Garden game, Online Magic Garden game"
            },
            "multiHotWays": {
                "title": "Play Multi Hot Ways Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Multi Hot Ways! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://betdaily.club/instant-games/multi-hot-ways",
                "alternate": "https://m.betdaily.club/instant-games/multi-hot-ways",
                "keywords" : "Multi Hot Ways game, Online Multi Hot Ways game"
            },
            "paperPlane": {
                "title": "Play Paper Plane Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Paper Plane! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://betdaily.club/instant-games/paper-plane",
                "alternate": "https://m.betdaily.club/instant-games/paper-plane",
                "keywords" : "Paper Plane game, Online Paper Plane game"
            },
            "stockMarket": {
                "title": "Play Stock Market Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Stock Market! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://betdaily.club/instant-games/stock-market",
                "alternate": "https://m.betdaily.club/instant-games/stock-market",
                "keywords" : "Stock Market game, Online Stock Market game"
            },
            "macludo": {
                "title": "Ludo Game | Play Ludo Game Online for real cash",
                "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "canonical" : "https://www.betdaily.club/online-casino-games/macludo",
                "alternate" : "https://m.betdaily.club/online-casino-games/macludo",
                "keywords" : "Ludo Game, Ludo game Online"
            },
            "crazytime": {
                "title": "Play CrazyTime Online for Free & Real Money - Betdaily",
                "description": "Dive into the whirlwind of excitement with CrazyTime online at Betdaily! Brace yourself for a gaming experience like no other – it's time to unleash the craziness and win big!",
                "canonical" : "https://www.betdaily.club/online-casino-games/crazy-time",
                "alternate" : "https://m.betdaily.club/online-casino-games/crazy-time",
                "keywords" : "Crazytime Game, Crazytime game Online"
            },
            "monopolylive": {
                "title": "Monopoly Live, Win Real Money | Betdaily",
                "description": "Step into the world of Monopoly Live and win real money at Betdaily. Play now for a chance to build your empire and claim victory!",
                "canonical" : "https://betdaily.club/online-casino-games/monopoly-live",
                "alternate": "https://m.betdaily.club/online-casino-games/monopoly-live",
                "keywords" : "MONOPOLY LIVE"
            },
            "funkytime": {
                "title": "Funkytime: Play at Betdaily & Win Real Money Online",
                "description": "Play Funkytime and win real money online. Unleash your groove and enjoy a unique gaming experience. Let the funky wins roll in!",
                "canonical": "https://betdaily.club/online-casino-games/Funkytime",
                "alternate": "https://m.betdaily.club/online-casino-games/Funkytime",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "lightningdice": {
                "title": "Lightningdice | Play at Betdaily & Win Real Money Online",
                "description": "Feel the electrifying thrill of Lightningdice at Betdaily! Play and win real money online as you roll the lightning-infused dice.",
                "canonical" : "https://betdaily.club/online-casino-games/lightning-dice",
                "alternate": "https://m.betdaily.club/online-casino-games/lightning-dice",
                "keywords" : "Lightning Dice"
            },
            "cricketx": {
                "title": "Play CricketX Live Online & Win Real Money at Betdaily",
                "description": "Experience the excitement of CricketX Live at Betdaily! Play online, showcase your cricket skills, and win real money.",
                "canonical" : "https://betdaily.club/instant-games/cricket-x",
                "alternate": "https://m.betdaily.club/instant-games/cricket-x",
                "keywords" : "Cricketx Games,Betdaily Cricketx games,Betdaily Cricketx games,Cricketx casino games"
            },
            "virtualSports": {
                "title": "Online Betting for Sports and Casino at Betdaily",
                "description": "Experience the ultimate thrill with online betting for sports and casino at Betdaily! Enjoy the best of both worlds as you bet on your favorite sports and casino games.",
                "canonical" : "https://betdaily.club/virtualSports/vci",
                "alternate": "https://betdaily.club/virtualSports/vci",
            },
            "virtualSportsBL": {
                "title": "Virtual Sports on Betdaily",
                "description": "Virtual Sports on Betdaily",
                "canonical" : "https://betdaily.club/virtualSports/vbl",
                "alternate": "https://betdaily.club/virtualSports/vbl",
            },
            "virtualSportsFB": {
                "title": "Virtual Sports on Betdaily",
                "description": "Virtual Sports on Betdaily",
                "canonical" : "https://betdaily.club/virtualSports/vfb",
                "alternate": "https://betdaily.club/virtualSports/vfb",
            },
            "virtualSportsFEL": {
                "title": "Virtual Sports on Betdaily",
                "description": "Virtual Sports on Betdaily",
                "canonical" : "https://betdaily.club/virtualSports/vfel",
                "alternate": "https://betdaily.club/virtualSports/vfel",
            },
            "virtualSportsFEC": {
                "title": "Virtual Sports on Betdaily",
                "description": "Virtual Sports on Betdaily",
                "canonical" : "https://betdaily.club/virtualSports/vfec",
                "alternate": "https://m.betdaily.club/virtualSports/vfec",
            },
            "virtualSportsVTI": {
                "title": "Elevate Your Game with Virtual Tennis Betting ID",
                "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
                "canonical" : "https://betdaily.club/virtualSports/vti",
                "alternate": "https://betdaily.club/virtualSports/vti",
            },
            "virtualSportsWC": {
                "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
                "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
                "canonical" : "https://betdaily.club/virtualSports/vfwc",
                "alternate": "https://betdaily.club/virtualSports/vfwc",
            },
            "premiumSportsbook": {
                "title": "Premium Sportsbook | Online Sports Betting at Betdaily",
                "description": "Go for gold with our Premium Sportsbook at Betdaily! Engage in online sports betting with the best odds and premium features.",
                "canonical" : "https://betdaily.club/premium-sportsbook",
                "alternate": "https://m.betdaily.club/premium-sportsbook",
                "keywords" : "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
            },
            "welcomeBonus": {
                "title": "400% Welcome Bonus to get you started (2024)💰",
                "description": "Claim your fortune with a whopping 400% Welcome Bonus in 2024 at Betdaily! 🎉 Your journey to big wins starts with an unbeatable bonus! Sign up now!",
                "canonical" : "https://www.betdaily.club/promotions/welcome-bonus",
                "alternate" : "https://m.betdaily.club/promotions/welcome-bonus",
                "keywords" : "Welcome Bonus"
            },
            "refillBonus": {
                "title": "Get the Betdaily Refill Bonus 💰 and Win Big!",
                "description": "Refill your luck and Win Big with Betdaily's Refill Bonus! 💰 Enjoy extra playing power. It's the perfect boost to increase your chances of winning!",
                "canonical" : "https://www.betdaily.club/promotions/refill-bonus",
                "alternate" : "https://m.betdaily.club/promotions/refill-bonus",
                "keywords" : "Refill bonus"
            },
            "cashbackBonus": {
                "title": "Cashback Bonus 💰 at Betdaily 2024",
                "description": "Enjoy the thrill of gaming with Cashback Bonus at Betdaily in 2024! 💰 It's your chance to turn every bet into a win!",
                "canonical" : "https://www.betdaily.club/promotions/cashback-bonus",
                "alternate" : "https://m.betdaily.club/promotions/cashback-bonus",
                "keywords" : "Cashback Bonus"
            },
            "referralBonus": {
                "title": "Referral Bonus 💰 at Betdaily 2024",
                "description": "Share the gaming joy with your friends, and when they join, you both reap the rewards 💰 It's a win-win – start referring now!",
                "canonical" : "https://betdaily.club/promotions/referral-bonus",
                "alternate": "https://m.betdaily.club/promotions/referral-bonus",
                "keywords" : "Referral Bonus"
            },
            "termsandconditions": {
                "title": "Terms & Conditions | Betdaily Online Casino",
                "description": "Read the Terms & Conditions at Betdaily Online Casino to ensure a smooth and fair gaming experience. Stay informed, play responsibly, and maximize your chances of winning!",
                "canonical" : "https://www.betdaily.club/about/terms-conditions",
                "alternate" : "https://m.betdaily.club/about/terms-conditions",
                "keywords" : "Terms & Conditions"
            },
            "responsibleGambling": {
                "title": "Responsible Gambling | Betdaily Online Casino",
                "description": "Dive into our Responsible Gambling policies to ensure a safe and enjoyable gaming environment. Your well-being is our top priority!",
                "canonical" : "https://www.betdaily.club/about/responsible-gambling",
                "alternate" : "https://m.betdaily.club/about/responsible-gambling",
                "keywords" : "Responsible Gambling"
            },
            "privacyPolicy": {
                "title": "Privacy Policy | Betdaily Online Casino",
                "description": "Your privacy matters! Discover how we safeguard your information with the Privacy Policy. Play with confidence, knowing that your data is secure and protected.",
                "canonical" : "https://www.betdaily.club/about/privacy-policy",
                "alternate" : "https://m.betdaily.club/about/privacy-policy",
                "keywords" : "privacy policy"
            },
            "kycPolicy": {
                "title": "KYC Policy | Betdaily Online Casino",
                "description": "Learn about our Know Your Customer policy at Betdaily. Ensuring a secure gaming space, we prioritize transparency and accountability to enhance your gaming.",
                "canonical" : "https://www.betdaily.club/about/kyc-policy",
                "alternate" : "https://m.betdaily.club/about/kyc-policy",
                "keywords" : "KYC Policy"
            },
            "exclusionPolicy": {
                "title": "Exclusion Policy | Betdaily Online Casino",
                "description": "Play responsibly with our Exclusion Policy. Learn about the tools we provide to promote responsible gaming and help you stay in control of your play.",
                "canonical" : "https://www.betdaily.club/about/self-exclusion-policy",
                "alternate" : "https://m.betdaily.club/about/self-exclusion-policy",
                "keywords" : "exclusion policy "
            },
            "amlPolicy": {
                "title": "AML Policy | Betdaily Online Casino",
                "description": "Explore our Anti-Money Laundering (AML) Policy. We are committed to maintaining the integrity of our platform for a fair and secure gaming experience.",
                "canonical" : "https://www.betdaily.club/about/aml-policy",
                "alternate" : "https://m.betdaily.club/about/aml-policy",
                "keywords" : "AML Policy"
            },
            "aboutus": {
                "title": "About Us | Betdaily Online Casino",
                "description": "Get to know us better! Explore the story behind Betdaily. Discover our mission, values, and commitment to providing an exceptional gaming experience.",
                "canonical" : "https://www.betdaily.club/about/about-us",
                "alternate" : "https://m.betdaily.club/about/about-us",
                "keywords" : "About us, About Betdaily"
            },
            "faq": {
                "title": "Frequently Asked Questions | Betdaily Online Casino",
                "description": "Have questions? Find answers in our Frequently Asked Questions section at Betdaily Online Casino.",
                "canonical" : "https://betdaily.club/faq",
                "alternate": "https://betdaily.club/faq",
            },
            "affiliate": {
                "title": "Affiliate | Betdaily Online Casino",
                "description": "Join the Betdaily Affiliate program and turn your passion for gaming into rewards. Start earning commissions and enjoy the benefits of our rewarding partnership!",
                "canonical" : "https://betdaily.club/affiliate",
                "alternate": "https://m.betdaily.club/affiliate",
            },
            "bingo": {
                "title": "Play Bingo Online - endless fun & real money at Betdaily",
                "description": "Experience the thrill of playing bingo online at our top-rated gaming platform. Enjoy a wide variety of exciting bingo games & wing big",
                "canonical": "https://betdaily.club/online-casino-games/bingo",
                "alternate": "https://m.betdaily.club/online-casino-games/bingo",
                "keywords" : "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "SPRIBE": {
                "title": "Spribe Casino Games | Get the best Spribe Games on Betdaily",
                "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
                "canonical" : "https://www.betdaily.club/instant-games/spribe",
                "alternate" : "https://m.betdaily.club/instant-games/spribe",
                "keywords" : "spribe casino games"
            },
            "7MOJOS": {
                "title": "7Mojos Games | Explore the top games at the best platform",
                "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
                "canonical" : "https://www.betdaily.club/online-casino-games/7mojos",
                "alternate" : "https://m.betdaily.club/online-casino-games/7mojos",
                "keywords" : "7Mojos Games"
            },
            "supernowa": {
                "title": "Supernowa Games | Live Casino Games from Betdaily",
                "description": "Supernowa games at the best! Play live casino games, online card games and slots on the safest gaming platform. Click to explore.",
                "canonical" : "https://www.betdaily.club/online-casino-games/supernowa",
                "alternate" : "https://m.betdaily.club/online-casino-games/supernowa",
                "keywords" : "supernowa games"
            },
            "pragmatic": {
                "title": "Pragmatic Casino Games online| Play on Betdaily",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "canonical" : "https://www.betdaily.club/online-casino-games/slotGames/pragmatic-play",
                "alternate" : "https://m.betdaily.club/online-casino-games/slotGames/pragmatic-play",
                "keywords" : "Pragmatic casino, pragmatic games"
            },
            "superspade": {
                "title": "Super Spade Games | Online Casino on Betdaily",
                "description": "Super Spade Games are online casino's delight. Play the best games with just one sign-in. Simple process, simpler registration. Join now!",
                "canonical" : "https://www.betdaily.club/online-casino-games/super-spade",
                "alternate" : "https://m.betdaily.club/online-casino-games/super-spade",
                "keywords" : "super spade games"
            },
            "tvbet": {
                "title": "TVBet Games | Live Betting & Gaming on Betdaily",
                "description": "TV Bet Games offers a wide variety of online gaming options. Explore all the top-rated games in one platform through one sign-in. Join now!",
                "canonical" : "https://www.betdaily.club/online-casino-games/tvbet",
                "alternate" : "https://m.betdaily.club/online-casino-games/tvbet",
                "keywords" : "tvbet games"
            },
            "netent": {
                "title": "NetEnt Games | Slots & Casino Games on Betdaily",
                "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
                "canonical" : "https://www.betdaily.club/online-casino-games/slotGames/netent",
                "alternate" : "https://m.betdaily.club/online-casino-games/slotGames/netent",
                "keywords" : "netent games"
            },
            "betgames": {
                "title": "BetGames | Live Dealer of Online Games on Betdaily",
                "description": "BetGames provides live casino games. Play a plethora of live casino games with one single registration. Faster withdrawal & 24x7 customer support.",
                "canonical" : "https://www.betdaily.club/online-casino-games/bet-games",
                "alternate" : "https://m.betdaily.club/online-casino-games/bet-games",
                "keywords" : "betgames"
            },
            "sportsbook": {
                "title": "Sportsbook Online | Sports Betting at Betdaily",
                "description": "Elevate your sports betting experience with Sportsbook Online at Betdaily! Enjoy a seamless and thrilling betting experience with the best odds.",
                "canonical" : "https://www.betdaily.club/sportsbook",
                "alternate" : "https://m.betdaily.club/sportsbook",
                "keywords" : "BTI Sports, BTI Sports Betting"
            },
            "appDownload": {
                "title": "Betdaily APK  App - Download & Win!",
                "description": "Check out the main reasons why you need to download the Betdaily app on your device. Learn more about the benefits of our app, bonuses and other offers",
                "canonical" : "https://www.betdaily.club/app-download",
                "alternate" : "https://m.betdaily.club/app-download",
                "keywords" : "Betdaily App Download, Betdaily Android App"
            },
            "login": {
                "title": "Login Betdaily | Play Online Casino Games",
                "description": "Betdaily Login now to play a wide range of exciting Online casino games and win big.don't miss out start playing today!",
                "canonical" : "https://www.betdaily.club/login",
                "alternate" : "https://m.betdaily.club/login",
                "keywords" : "Betdaily Login"
            },
            "signup": {
                "title": "Sign Up Now - Betdaily & explore Online Casino Games",
                "description": "Signup at the Betdaily official website today for access to premium Sports & Casino Games",
                "canonical" : "https://betdaily.club/signup",
                "alternate": "https://m.betdaily.club/signup",
                "keywords" : "Betdaily User Registration"
            },
            "instantGames": {
                "title": "Instant Games | Enjoy the Best Instant Casino Games Now!",
                "description": " Experience the thrill of instant games and win big in seconds! Play a wide variety of exciting casino games for instant entertainment & instant rewards. Join & start winning instantly!.",
                "canonical" : "https://www.betdaily.club/instant-games",
                "alternate": "https://m.betdaily.club/instant-games",
                "keywords" : "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "dailyDepositBonus": {
                "title": "Betdaily Daily Deposit Bonus | Best Casino Daily Deposit Scheme",
                "description": "Betdaily Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on Betdaily.",
                "canonical" : "https://www.betdaily.club/daily-deposit-bonus",
                "alternate": "https://m.betdaily.club/daily-deposit-bonus",
                "keywords" : "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "asiaCupBonanza": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on Betdaily provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, Betdaily online cricket betting, Betdaily cricket betting odds, Betdaily cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical" : "https://www.betdaily.club/asia-cup-bonanza",
                "alternate": "https://m.betdaily.club/asia-cup-bonanza"
            },
            "big-bash": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at YOLO247 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://www.betdaily.club/promotions/big-bash",
                "alternate": "https://m.betdaily.club/promotions/big-bash",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
        },
        "39": {
            "default": {
                "title": "FOMO7 | Best Online Casino & Sports Betting Site In India",
                "description": "FOMO7 is the best online betting site in India, offering casino games & sports betting for real money. Join the biggest real money gambling online & win now!",
                "canonical": "https://fomo7.com",
                "alternate": "https://m.fomo7.com",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "onlineCasinoGames": {
                "title": "Live Casino on FOMO7 | Play Real Money Online Casino Games",
                "description": "Play live roulette online for real money. Experience the best online roulette game on FOMO7 with higher payouts, faster withdrawal & safer transactions.",
                "canonical": "https://fomo7.com/online-casino-games",
                "alternate": "https://m.fomo7.com/online-casino-games",
                "keywords": " Online Casino Games, Live Casino Online, Play Casino games online"
            },
            "live-blackjack": {
                "title": "Play Blackjack Online for Real Money - FOMO7 Live Blackjack",
                "description": "Step into the live casino world & play Blackjack online on FOMO7. Experience higher payouts for online Blackjack game. Win real money on Blackjack.",
                "canonical": "https://fomo7.com/online-casino-games/live-blackjack",
                "alternate": "https://m.fomo7.com/online-casino-games/live-blackjack",
                "keywords": "blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "roulette": {
                "title": "Play Roulette Online For Real Money | FOMO7 Live Roulette",
                "description": "Play live roulette online for real money. Experience the best online roulette game on FOMO7 with higher payouts, faster withdrawal & safer transactions.",
                "canonical": "https://fomo7.com/online-casino-games/live-roulette",
                "alternate": "https://m.fomo7.com/online-casino-games/live-roulette",
                "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "lightningroulette": {
                "title": "Play Lightning Roulette Online on FOMO7 | Best Wheel Game",
                "description": "Lightning Roulette live casino game on FOMO7 gives higher multipliers of up to 500x every round. Play Lightning Roulette online for real money.",
                "canonical": "https://fomo7.com/online-casino-games/lightning-roulette",
                "alternate": "https://m.fomo7.com/online-casino-games/lightning-roulette",
                "keywords": "lightningroulette game,lightningroulette online,casino lightningroulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "dragonTiger": {
                "title": "Dragon Tiger Game on FOMO7 | Play Dragon Tiger for Real Cash",
                "description": "Elevate your gaming experience with FOMO7 Dragon Tiger game. Play Dragon Tiger online with faster withdrawal & higher payout.",
                "canonical": "https://fomo7.com/online-casino-games/dragon-tiger",
                "alternate": "https://m.fomo7.com/online-casino-games/dragon-tiger",
                "keywords": "fomo7 is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
            },
            "baccarat": {
                "title": "Play Baccarat Casino Game Online for Real Money on FOMO7",
                "description": "Experience the excitement of Baccarat casino game online. Play Baccarat for real money on FOMO7 with more features & more rewards.",
                "canonical": "https://fomo7.com/online-casino-games/live-baccarat",
                "alternate": "https://m.fomo7.com/online-casino-games/live-baccarat",
                "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
            },
            "andarBahar": {
                "title": "Play Andar Bahar Online for FREE or Real Money - FOMO7",
                "description": "Play Andar Bahar Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Andar Bahar Live now!",
                "canonical": "https://fomo7.com/online-casino-games/andar-bahar",
                "alternate": "https://m.fomo7.com/online-casino-games/andar-bahar",
                "keywords": "Andar Bahar game,Andar Bahar online,Andar Bahar casino,Andar Bahar gaming,Andar Bahar rule,Andar Bahar game online,Andar Bahar game rules,play Andar Bahar online,Andar Bahar online play,Andar Bahar online free,best Andar Bahar online casino,best casino for Andar Bahar"
            },
            "dreamCatcher": {
                "title": "Play Dream Catcher Online for FREE or Real Money - FOMO7",
                "description": "Play Dream Catcher Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Dream Catcher Live now!",
                "canonical": "https://fomo7.com/online-casino-games/dream-catcher",
                "alternate": "https://m.fomo7.com/online-casino-games/dream-catcher",
                "keywords": "Dream Catcher game,Dream Catcher online,Dream Catcher casino,Dream Catcher gaming,Dream Catcher rule,Dream Catcher game online,Dream Catcher game rules,play Dream Catcher online,Dream Catcher online play,Dream Catcher online free,best Dream Catcher online casino,best casino for Dream Catcher"
            },
            "hilo": {
                "title": "Play Hilo Online for FREE or Real Money - FOMO7",
                "description": "Play Hilo Online game with the best interface, smoothest process, seamless game play and fastest withdrawal. Bet on Hilo Live now!",
                "canonical": "https://fomo7.com/online-casino-games/hi-lo",
                "alternate": "https://m.fomo7.com/online-casino-games/hi-lo",
                "keywords": "Hilo game,Hilo online,Hilo casino,Hilo gaming,Hilo rule,Hilo game online,Hilo game rules,play Hilo online,Hilo online play,Hilo online free,best Hilo online casino,best casino for Hilo"
            },
            "teenpattiOnlinePlay": {
                "title": "Play Teen Patti Online Game on FOMO7 | Best Casino Card Game",
                "description": "Play FOMO7 Teen Patti online game for an adrenaline-packed gambling experience! Explore the best casino card game in India with higher payouts.",
                "canonical": "https://fomo7.com/teenpatti-online-play",
                "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
            },
            "sportsBetting": {
                "title": "Best Sports Betting Exchange in India - FOMO7 Exchange",
                "description": "Bet on all the top sports on the best sports betting exchange in India. Get high odds on FOMO7 exchange with Back & Lay options and Fancy bets.",
                "canonical": "https://fomo7.com/betting-exchange",
                "alternate": "https://m.fomo7.com/betting-exchange?id=0",
                "keywords": "betting exchange"
            },
            "onlineCricketSattaBazar": {
                "title": "Online Cricket Betting on FOMO7 | Best Cricket Satta Bazar",
                "description": "Bet on cricket matches on FOMO7 online cricket satta bazar. Get the best odds for all cricket matches & experience the top online cricket betting in India.",
                "keywords": " Online Cricket Betting Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, fomo7 online cricket betting, fomo7 cricket betting odds, fomo7 cricket betting, cricket betting sites, online cricket satta bazar,",
                "canonical": "https://fomo7.com/betting-exchange/online-cricket-betting",
                "alternate": "https://m.fomo7.com/betting-exchange/online-cricket-betting",
            },
            "footballBetting": {
                "title": "Bet on Football in India | Online Football Betting on FOMO7",
                "description": "Bet on football matches on FOMO7. Get high odds for football matches with the best online football betting experience in India.",
                "canonical": "https://fomo7.com/betting-exchange/football-betting",
                "alternate": "https://m.fomo7.com/betting-exchange/football-betting",
                "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
            },
            "tennisBetting": {
                "title": "Online Tennis Betting | Best Tennis Betting Odds on FOMO7",
                "description": "Discover the ultimate tennis betting experience in India. Bet on tennis matches online on FOMO7 with high odds & fast payouts.",
                "canonical": "https://fomo7.com/betting-exchange/tennis-betting",
                "alternate": "https://m.fomo7.com/betting-exchange/tennis-betting",
                "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "volleyballBetting": {
                "title": "Live Volleyball Betting | Best Volleyball Betting Odds on FOMO7",
                "description": "Bet on volleyball matches in India with our trusted platform. Exciting odds & secure transactions on FOMO7 online volleyball betting!",
                "canonical": "https://fomo7.com/betting-exchange/volleyball-betting",
                "alternate": "https://m.fomo7.com/betting-exchange/volleyball-betting",
                "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
            },
            "promotions": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at fomo7 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://fomo7.com/promotions",
                "alternate": "https://m.fomo7.com/promotions",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "specialMarket": {
                "title": "Special Markets on Fomo7",
                "description": "Special Markets on Fomo7",
                "canonical" : "https://fomo7.com/specialMarket",
                "alternate": "https://m.fomo7.com/specialMarket",
            },
            "virtualSports": {
                "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
                "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
                "canonical" : "https://fomo7.com/virtualSports/vci",
                "alternate": "https://m.fomo7.com/virtualSports/vci",
            },
            "virtualSportsBL": {
                "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
                "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
                "canonical" : "https://fomo7.com/virtualSports/vbl",
                "alternate": "https://m.fomo7.com/virtualSports/vbl",
            },
            "virtualSportsFB": {
                "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
                "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
                "canonical" : "https://fomo7.com/virtualSports/vfb",
                "alternate": "https://m.fomo7.com/virtualSports/vfb",
            },
            "virtualSportsFEL": {
                "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
                "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
                "canonical" : "https://fomo7.com/virtualSports/vfel",
                "alternate": "https://m.fomo7.com/virtualSports/vfel",
            },
            "virtualSportsFEC": {
                "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
                "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
                "canonical" : "https://fomo7.com/virtualSports/vfec",
                "alternate": "https://m.fomo7.com/virtualSports/vfec",
            },
            "virtualSportsVTI": {
                "title": "Elevate Your Game with Virtual Tennis Betting ID",
                "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
                "canonical" : "https://fomo7.com/virtualSports/vti",
                "alternate": "https://fomo7.com/virtualSports/vti",
            },
            "virtualSportsWC": {
                "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
                "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
                "canonical" : "https://fomo7.com/virtualSports/vfwc",
                "alternate": "https://fomo7.com/virtualSports/vfwc",
            },
            "slotGames": {
                "title": "Best Slot Games in India | Play Slots on FOMO7",
                "description": "Explore a variety of casino slot games on FOMO7. Play the best slots online & win real money instantly. Hit the jackpot everyday on FOMO7 slots.",
                "canonical": "https://fomo7.com/online-casino-games/slotGames",
                "alternate": "https://m.fomo7.com/online-casino-games/slotGames",
                "keywords": "slot games for real money, casino slot games"
            },
            "aviator": {
                "title": "Play Aviator Game Online - FOMO7 Aviator Game for Real Money",
                "description": "Fly high in the Aviator game online! Play Aviator on FOMO7 for real money with higher multiplier every round. Cash out instantly to your wallet.",
                "canonical": "https://fomo7.com/instant-games/aviator",
                "alternate": "https://m.fomo7.com/instant-games/aviator",
                "keywords": "Aviator game, Aviator online game "
            },
            "cashshow": {
                "title": "Cash Show Casino Game | Play Cash show Online on FOMO7",
                "description": "Play Cash show casino game from Galaxsys on FOMO7! Get real money cashow game with the mix of fashion & thrill.",
                "canonical" : "https://fomo7.com/instant-games/cashshow",
                "alternate": "https://m.fomo7.com/instant-games/cashshow",
                "keywords" : "Cash Show game, Cash Show online game "
            },
            "jetx": {
                "title": "Play JetX Casino Game on FOMO7 | JetX Online Game",
                "description": "Play Jetx game online on FOMO7. Get higher multiplier every round, excited features & faster withdrawals instantly to your wallet. Explore Jetx casino game.",
                "canonical" : "https://www.fomo7.com/instant-games/jetx",
                "alternate": "https://m.fomo7.com/instant-games/jetx",
                "keywords" : "Jet X game, Jet X online game "
            },
            "rockpaperscissors": {
                "title": "Rockpaperscissors ID - Grab Your Exclusive Deals Now!",
                "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
                "canonical" : "https://fomo7.com/instant-games/rock-paper-scissors",
                "alternate": "https://fomo7.com/instant-games/rock-paper-scissors",
                "keywords" : "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
            },
            "plinkox": {
                "title": "Play PlinkoX Online Game for Real Money on FOMO7",
                "description": "Play PlinkoX online on FOMO7 & win big! Get the highest multiplier for PlinkoX Casino game in India with faster withdrawal.",
                "canonical" : "https://www.fomo7.com/instant-games/plinko-x",
                "alternate": "https://m.fomo7.com/instant-games/plinko-x",
                "keywords" : "Plinkox game, Plinkox online game "
            },
            "footballx": {
                "title": "Football X Game | Live Casino Online Games on fomo7",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
                "canonical" : "https://www.fomo7.com/instant-games/football-x",
                "alternate": "https://m.fomo7.com/instant-games/football-x",
                "keywords" : "Football X game, Football X online game "
            },
            "balloon": {
                "title": "Balloon Game | Live Casino Online Games on fomo7",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
                "canonical" : "https://www.fomo7.com/instant-games/balloon",
                "alternate": "https://m.fomo7.com/instant-games/balloon",
                "keywords" : "Balloon game, Balloon online game "
            },
            "mines": {
                "title": "Mines Casino Games on FOMO7 | Best Mine Game Online",
                "description": "Discover the excitement of Mines Casino games on FOMO7! Play real money mines game, win big & feel the thrill with every move.",
                "canonical" : "https://www.fomo7.com/instant-games/mines",
                "alternate": "https://m.fomo7.com/instant-games/mines",
                "keywords" : "Mines game, Online mines game"
            },
            "rocketon": {
                "title": "Play Rocketon Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Rocketon! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://fomo7.com/instant-games/rocketon",
                "alternate": "https://m.fomo7.com/instant-games/rocketon",
                "keywords" : "Rocketon game, Online Rocketon game"
            },
            "burningIce": {
                "title": "Play BurningIce Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in BurningIce! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://fomo7.com/instant-games/burning-ice",
                "alternate": "https://m.fomo7.com/instant-games/burning-ice",
                "keywords" : "BurningIce game, Online BurningIce game"
            },
            "cappaDocia": {
                "title": "Play Cappa Docia Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Cappa Docia! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://fomo7.com/instant-games/cappa-docia",
                "alternate": "https://m.fomo7.com/instant-games/cappa-docia",
                "keywords" : "Cappa Docia game, Online Cappa Docia game"
            },
            "geniesBonanza": {
                "title": "Play GeniesBonanza Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in GeniesBonanza! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://fomo7.com/instant-games/genies-bonanza",
                "alternate": "https://m.fomo7.com/instant-games/genies-bonanza",
                "keywords" : "GeniesBonanza game, Online GeniesBonanza game"
            },
            "goal": {
                "title": "Play Goal Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Goal! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://fomo7.com/instant-games/goal",
                "alternate": "https://m.fomo7.com/instant-games/goal",
                "keywords" : "Goal game, Online Goal game"
            },
            "hunterx": {
                "title": "Play Hunterx Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Hunterx! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://fomo7.com/instant-games/hunter-x",
                "alternate": "https://m.fomo7.com/instant-games/hunter-x",
                "keywords" : "Hunterx game, Online Hunterx game"
            },
            "jungles": {
                "title": "Play Jungles Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Jungles! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://fomo7.com/instant-games/jungles",
                "alternate": "https://m.fomo7.com/instant-games/jungles",
                "keywords" : "Jungles game, Online Jungles game"
            },
            "magicGarden": {
                "title": "Play Magic Garden Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Magic Garden! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://fomo7.com/instant-games/magic-garden",
                "alternate": "https://m.fomo7.com/instant-games/magic-garden",
                "keywords" : "Magic Garden game, Online Magic Garden game"
            },
            "multiHotWays": {
                "title": "Play Multi Hot Ways Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Multi Hot Ways! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://fomo7.com/instant-games/multi-hot-ways",
                "alternate": "https://m.fomo7.com/instant-games/multi-hot-ways",
                "keywords" : "Multi Hot Ways game, Online Multi Hot Ways game"
            },
            "paperPlane": {
                "title": "Play Paper Plane Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Paper Plane! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://fomo7.com/instant-games/paper-plane",
                "alternate": "https://m.fomo7.com/instant-games/paper-plane",
                "keywords" : "Paper Plane game, Online Paper Plane game"
            },
            "stockMarket": {
                "title": "Play Stock Market Game Online & Win Real Money 2024",
                "description": "Gear up for the ultimate challenge in Stock Market! Plan your moves strategically, play online, and stand a chance to win real money in 2024.",
                "canonical" : "https://fomo7.com/instant-games/stock-market",
                "alternate": "https://m.fomo7.com/instant-games/stock-market",
                "keywords" : "Stock Market game, Online Stock Market game"
            },
            "macludo": {
                "title": "Macludo Casino Games Online - Play & Win 24/7",
                "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "canonical" : "https://fomo7.com/online-casino-games/macludo",
                "alternate": "https://m.fomo7.com/online-casino-games/macludo",
                "keywords" : "Ludo Game, Ludo game Online"
            },
            "crazytime": {
                "title": "Crazy Time Live Game on FOMO7 | Play Crazy Time Online",
                "description": "Play Crazy Time live game on FOMO7 for higher payouts every round. Get live crazy time stats, scores & tracker. Join & win real money.",
                "canonical" : "https://www.fomo7.com/online-casino-games/crazy-time",
                "alternate" : "https://m.fomo7.com/online-casino-games/crazy-time",
                "keywords" : "Crazytime Game, Crazytime game Online"
            },
            "welcomeBonus": {
                "title": "Best Casino & Sports Betting Welcome Bonus in India | FOMO7",
                "description": "FOMO7 offers a massive 700% welcome bonus for casino & sports betting. Get the best online betting sign-up bonus. Join & get FOMO7 welcome bonus.",
                "canonical" : "https://fomo7.com/promotions/welcome-bonus",
                "alternate": "https://m.fomo7.com/promotions/welcome-bonus",
                "keywords" : "Joining Bonus"
            },
            "premiumSportsbook": {
                "title": "Premium Sportsbook in India | FOMO7 Online Sportsbook",
                "description": "Bet on sports through FOMO7 Premium Sportsbook. Get premium options, features & payouts, available for all matches, leagues & sports.",
                "canonical" : "https://fomo7.com/premium-sportsbook",
                "alternate": "https://m.fomo7.com/premium-sportsbook",
                "keywords" : "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
            },
            "refillBonus": {
                "title": "Redeposit Bonus for Casino & Sports Betting in India | FOMO7",
                "description": "Get 5% redeposit bonus instantly on FOMO7! Play top games & bet on sports with extra rewards for every deposit. Get the best redeposit bonus for online casinos now!",
                "canonical" : "https://fomo7.com/promotions/refill-bonus",
                "alternate": "https://m.fomo7.com/promotions/refill-bonus",
                "keywords" : "Redeposit bonus"
            },
            "cashbackBonus": {
                "title": "Lossback Bonus on FOMO7 | Get 5% Cashback for your losses",
                "description": "FOMO7 offers a grand 5% lossback bonus without any RO conditions for both casino games & sports betting in India.",
                "canonical" : "https://fomo7.com/promotions/cashback-bonus",
                "alternate": "https://m.fomo7.com/promotions/cashback-bonus",
                "keywords" : "Cashback Bonus"
            },
            "referralBonus": {
                "title": "Refer & Get 21% | Best Online Casino Referral Bonus on FOMO7",
                "description": "A mega 21% referral bonus awaits you on FOMO7! Refer, earn & play the best casino games and bet on your favourite sports. Every referral gets a reward.",
                "canonical" : "https://fomo7.com/promotions/referral-bonus",
                "alternate": "https://m.fomo7.com/promotions/referral-bonus",
                "keywords" : "Referral Bonus"
            },
            "termsandconditions": {
                "title": "Terms & Conditions for FOMO7",
                "description": "Find the complete terms & conditions for playing on FOMO7. Get the restrictions, obligations and allowed practices.",
                "canonical" : "https://fomo7.com/terms-conditions",
                "alternate": "https://m.fomo7.com/about/terms-conditions",
                "keywords" : "Terms & Conditions"
            },
            "responsibleGambling": {
                "title": "Responsible Gaming | FOMO7",
                "description": "Get the requisite details of Responsible Gaming on FOMO7. It is applicable to both casino games and sports prediction.",
                "canonical" : "https://fomo7.com/responsible-gambling",
                "alternate": "https://m.fomo7.com/about/responsible-gambling",
                "keywords" : "Responsible Gambling"
            },
            "privacyPolicy": {
                "title": "Complete Details of Privacy Policy of FOMO7",
                "description": "Find all the information regarding the Privacy Policy of FOMO7. From data storage to security details, get everything related to privacy maintenance.",
                "canonical" : "https://fomo7.com/privacy-policy",
                "alternate": "https://m.fomo7.com/about/privacy-policy",
                "keywords" : "privacy policy"
            },
            "kycPolicy": {
                "title": "KYC Policy of FOMO7 | Find the KYC Requirements & Process",
                "description": " Find the detailed KYC policy of FOMO7. Get the complete guidelines for our KYC policy and understand the documents required for the process.",
                "canonical" : "https://fomo7.com/kyc-policy",
                "alternate": "https://m.fomo7.com/about/kyc-policy",
                "keywords" : "KYC Policy"
            },
            "exclusionPolicy": {
                "title": "Self-Exclusion Policy of FOMO7 | What & How",
                "description": "Find what is the Self-Exclusion Policy of FOMO7. Get detailed information of the entire process of self-exclusion.",
                "canonical" : "https://fomo7.com/exclusion-policy",
                "alternate": "https://m.fomo7.com/about/self-exclusion-policy",
                "keywords" : "exclusion policy "
            },
            "amlPolicy": {
                "title": "AML Policy of FOMO7 | Anti-Money Laundering Policy",
                "description": "Find the complete Anti-Money Laundering policy of FOMO7. Get the details of compliance procedures.",
                "canonical" : "https://fomo7.com/aml-policy",
                "alternate": "https://m.fomo7.com/about/aml-policy",
                "keywords" : "AML Policy"
            },
            "aboutus": {
                "title": "About FOMO7 | Your Online Betting Destination",
                "description": "Learn about FOMO7, a leading online betting site in India that offers various casino games and sports betting options. Find more about us.",
                "canonical" : "https://fomo7.com/about-us",
                "alternate": "https://m.fomo7.com/about/about-us",
                "keywords" : "About us, About fomo7"
            },
            "SPRIBE": {
                "title": "Spribe Casino Games | Get the best Spribe Games on fomo7",
                "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
                "canonical" : "https://fomo7.com/instant-games/spribe",
                "alternate": "https://m.fomo7.com/instant-games/spribe",
                "keywords" : "spribe casino games"
            },
            "aviatrix": {
                "title": "Play Aviatrix Game Online | FOMO7 Aviatrix For Real Cash",
                "description": "Play Aviatrix Online game for real cash on FOMO7. Fly more, win more. Get high multipliers for Aviatrix casino crash game.",
                "canonical" : "https://fomo7.com/instant-games/aviatrix",
                "alternate": "https://m.fomo7.com/instant-games/aviatrix",
                "keywords" : "Aviatrix casino games"
            },
            "7MOJOS": {
                "title": "7Mojos Games | Explore the top games at the best platform",
                "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
                "canonical" : "https://fomo7.com/online-casino-games/7mojos",
                "alternate": "https://m.fomo7.com/online-casino-games/7mojos",
                "keywords" : "7Mojos Games"
            },
            "supernowa": {
                "title": "Play Supernowa Casino Games on fomo7",
                "description": " Supernowa casino games are one of the best in business. Check out Supernowa gaming and see the games offered on fomo7.",
                "canonical" : "https://fomo7.com/online-casino-games/supernowa",
                "alternate": "https://m.fomo7.com/online-casino-games/supernowa",
                "keywords" : "Supernowa Casino Games,Supernowa gaming, Supernowa casino"
            },
            "pragmatic": {
                "title": "Pragmatic Casino Game on fomo7 | Get 400% Welcome Bonus",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "canonical" : "https://fomo7.com/online-casino-games/slotGames/pragmatic-play",
                "alternate": "https://m.fomo7.com/online-casino-games/slotGames/pragmatic-play",
                "keywords" : "Pragmatic casino, pragmatic games"
            },
            "superspade": {
                "title": "Top Super Spade Games On fomo7 | Play Now!",
                "description": " Super Spade games are popular because of exciting features, gameplay & payouts. Play Super Spade casino games on fomo7 and win big. Join now!",
                "canonical" : "https://fomo7.com/online-casino-games/super-spade",
                "alternate": "https://m.fomo7.com/online-casino-games/super-spade",
                "keywords" : "super spade games,play super spade casino games, super spade casino"
            },
            "tvbet": {
                "title": "Play TVBET Casino Games Online | Real Money Games",
                "description": "TVBET casino games provide engaging games. A wide variety of games await online casino gaming enthusiasts. Find out more about fomo7 TVBET Games.",
                "canonical" : "https://fomo7.com/online-casino-games/tvbet",
                "alternate": "https://m.fomo7.com/online-casino-games/tvbet",
                "keywords" : "TVBET casino games, fomo7 TVBET games"
            },
            "netent": {
                "title": "Netent Casino Game on fomo7 | Get Upto 400% Bonus",
                "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
                "canonical" : "https://fomo7.com/online-casino-games/slotGames/netent",
                "alternate": "https://m.fomo7.com/online-casino-games/slotGames/netent",
                "keywords" : "netent games"
            },
            "betgames": {
                "title": "BetGames On fomo7 | Play BetGames & Win Big",
                "description": "BetGames is one of the modern-day game providers in fomo7. Several casino gaming enthusiasts love the games. Here's why.BetGames is another top live dealer casino game provider that is available on fomo7 from the comfort of your home. The best part of BetGames is that instead of promoting state-of-the-art technology, they have made their gaming solutions much more accessible to new gamers who want to explore the gaming industry at their own pace.Over the years, the live casino has been the hot trend in the gaming industry, and BetGames, too, has tapped into this market, creating some of the most awe-inspiring live dealer games. Most of these games make you feel like you are in a physical casino.",
                "canonical" : "https://fomo7.com/online-casino-games/bet-games",
                "alternate": "https://m.fomo7.com/online-casino-games/bet-games",
                "keywords" : "betgames,fomo7 BetGames"
            },
            "sportsbook": {
                "title": "Online Sportsbook in India | Best Sportsbook Odds on FOMO7",
                "description": "Bet on sports through FOMO7 online sportsbook. Explore betting options for all sports with high odds, fast payouts and for all matches, leagues & tournaments.",
                "canonical" : "https://fomo7.com/sportsbook",
                "alternate": "https://m.fomo7.com/sportsbook",
                "keywords" : "Sportsbook Betting,Best Online Sportsbook, Sports Betting in India"
            },
            "appDownload": {
                "title": "FOMO7 Online Betting App | Best Casino & Sports Betting Apk",
                "description": "Download FOMO7 App for the best casino & sports betting experience in India. Play real-money gambling on FOMO7 apk. Get the best online betting app now!",
                "canonical" : "https://fomo7.com/app-download",
                "alternate": "https://m.fomo7.com/app-download",
                "keywords" : "fomo7 App Download, fomo7 Android App"
            },
            "signup": {
                "title": "Register on FOMO7 | Best online casino & sports betting site",
                "description": "FOMO7 registration process is faster & smoother. Get the best offers & bonuses on FOMO7. Explore casino & sports betting options with one registration.",
                "canonical" : "https://fomo7.com/signup",
                "alternate": "https://m.fomo7.com/signup",
                "keywords" : "fomo7 User Registration"
            },
            "faq": {
                "title": "FOMO7 FAQs | Frequently Asked Questions",
                "description": "Go through a list of frequently asked questions on FOMO7 about registration, deposit, withdrawal & other important aspects of online gambling.",
                "canonical" : "https://fomo7.com/faq",
                "alternate": "https://m.fomo7.com/faq",
            },
            "affiliate": {
                "title": "Affiliate Page",
                "description": "Affiliate Page",
                "canonical" : "https://fomo7.com/affiliate",
                "alternate": "https://m.fomo7.com/affiliate",
            },
            "loyalty": {
                "title": "Loyalty Page",
                "description": "Loyalty Page",
                "canonical" : "https://fomo7.com/loyalty",
                "alternate": "https://m.fomo7.com/loyalty",
            },
            "login": {
                "title": "Log in to FOMO7 | Best online casino & sports betting site",
                "description": "Use your existing FOMO7 credentials to log in now. Explore top casino & sports betting options. Get a mega bonus for every deposit.",
                "canonical" : "https://fomo7.com/login",
                "alternate": "https://m.fomo7.com/login",
                "keywords" : "fomo7 Login"
            },
            "instantGames": {
                "title": "Instant Games on FOMO7 | Play the Best Instant Games Online",
                "description": "Explore the FOMO7 Instant Games & play for real money. Get the best instant games in online casinos in India. Instant rewards await you. Join now!",
                "canonical" : "https://fomo7.com/instant-games",
                "alternate": "https://m.fomo7.com/instant-games",
                "keywords" : "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "majorLeagueCricketBetting": {
                "title": "MLC Betting in India | Major League Cricket Betting Online",
                "description": "MLC Betting is available on fomo7! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
                "canonical" : "https://fomo7.com/sports/tournaments/major-league-cricket-betting",
                "alternate": "https://m.fomo7.com/sports/tournaments/major-league-cricket-betting",
                "keywords" : "Major League Cricket Betting Online, MLC Betting in India"
            },
            "dailyDepositBonus": {
                "title": "Refill bonus | Best Casino Refill Bonus on fomo7",
                "description": "fomo7 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on fomo7.",
                "canonical" : "https://fomo7.com/daily-deposit-bonus",
                "alternate": "https://m.fomo7.com/daily-deposit-bonus",
                "keywords" : "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "popular games": {
                "title": "Play Top Casino Games Online for Real Money on FOMO7",
                "description": "Explore the top casino games in India on FOMO7. Play the best online casino games for real money, with higher chances of winning & withdraw instantly.",
                "canonical" : "https://fomo7.com/online-casino-games/best-casino-games",
                "alternate": "https://m.fomo7.com/online-casino-games/best-casino-games",
                "keywords" : "Best Casino Games Popular Casino Games, online casino real money"
            },
            "evolution": {
                "title": "Play Evolution Gaming Casino | Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on fomo7",
                "canonical" : "https://fomo7.com/online-casino-games/evolution-gaming",
                "alternate": "https://m.fomo7.com/online-casino-games/evolution-gaming",
                "keywords" : "Evolution Gaming, Evolution Gaming Casino"
            },
            
            "spribe": {
                "title": "Spribe Games | Play The Best fomo7 Spribe Games",
                "description": "Spribe games use the latest technology to build innovative and immersive live casino games for users. Find out how to experience thrilling fomo7 Spribe Games.",
                "canonical" : "https://fomo7.com/instant-games/spribe",
                "alternate": "https://m.fomo7.com/instant-games/spribe",
                "keywords" : "Spribe Games,fomo7 Spribe games,fomo7 Spribe games,Spribe live casino games"
            },
            "cricketx": {
                "title": "CricketX Casino Game | Play CricketX for Real Money on FOMO7",
                "description": "Play CricketX Casino game on FOMO7. Immerse yourself in the ultimate gaming experience! Get the thrill of cricket & fun of casino with CricketX game.",
                "canonical" : "https://fomo7.com/instant-games/cricket-x",
                "alternate": "https://m.fomo7.com/instant-games/cricket-x",
                "keywords" : "Cricketx Games,fomo7 Cricketx games,fomo7 Cricketx games,Cricketx casino games"
           },
            "ezugi": {
                "title": "Ezugi Live Casino | Play fomo7 Ezugi Games",
                "description": " Ezugi's state-of-the-art live casino games are a massive hit among fans. Let us do a deep dive into Ezugi Live Casino games.Ezugi live casino is a popular gaming provider in the online gaming world. A huge number of casino gaming enthusiasts play Ezugi live casino games on fomo7. Unlike some of the other competitors, Ezugi gaming provides you with an ultimate gaming experience.A couple of things make Ezugi live casino dealer games so different. Let's learn more about the gaming provider.",
                "canonical" : "https://fomo7.com/online-casino-games/ezugi-live-casino",
                "alternate": "https://m.fomo7.com/online-casino-games/ezugi-live-casino",
                "keywords" : "Ezugi Live Casino,Ezugi, Ezugi Gaming, fomo7 Ezugi Live Casino"
            },
            "sexygaming": {
                "title": "Play Sexy Gaming Casino Online | Get 400% Signup  Bonus",
                "description": "Sexy Gaming casino is one of Asia's most popular live casino game providers. Play sexy gaming casino games on fomo7 and win exciting rewards.",
                "canonical" : "https://fomo7.com/online-casino-games/sexy-gaming",
                "alternate": "https://m.fomo7.com/online-casino-games/sexy-gaming",
                "keywords" : "sexy gaming casino,sexy gaming casino games, fomo7 sexy gaming casino games, fomo7 sexy gaming live casino game"
            },
            "monopolylive": {
                "title": "Play Monopoly Game Online & Win Real Money on FOMO7 Casino",
                "description": "Play Monopoly Live game on FOMO7 casino. Get higher payouts every round & win real money to your account directly.",
                "canonical" : "https://fomo7.com/online-casino-games/monopoly-live",
                "alternate": "https://m.fomo7.com/online-casino-games/monopoly-live",
                "keywords" : "MONOPOLY LIVE"
            },
            "funkytime": {
                "title": "Play Funky Time Casino Game for Real Money on FOMO7",
                "description": "Play Funky Time online game on FOMO7. Explore the wheel game features, payouts & betting options. Join & win real money on Funky Time.",
                "canonical" : "https://fomo7.com/online-casino-games/funkytime",
                "alternate": "https://m.fomo7.com/online-casino-games/funkytime",
                "keywords" : "funkytime"
            },
            "lightningdice": {
                "title": "Play Lightning Dice Casino Game for Real Money on FOMO7",
                "description": "Light up your gaming experience with Lightning Dice casino game on FOMO7. Get lightning multipliers & bigger payouts on online lightning dice game.",
                "canonical" : "https://fomo7.com/online-casino-games/lightning-dice",
                "alternate": "https://m.fomo7.com/online-casino-games/lightning-dice",
                "keywords" : "Lightning Dice"
            },
            "macaw": {
                "title": "Macaw Games | Top Macaw Casino Online Games on fomo7",
                "description": "Macaw games are thrilling and are available online on fomo7. Learn more about the popular Macaw casino online games.",
                "canonical" : "https://fomo7.com/online-casino-games/macaw",
                "alternate": "https://m.fomo7.com/online-casino-games/macaw",
                "keywords" : "macaw games, macaw casino online"
            },
            "ag": {
                "title": "Play Your Favourite AG Game Online at fomo7",
                "description": "Amazing Gaming has established itself as one of the best gaming solutions providers. Play Amazing Gaming games on fomo7.",
                "canonical" : "https://fomo7.com/online-casino-games/ag",
                "alternate": "https://m.fomo7.com/online-casino-games/ag",
                "keywords" : "Amazing Gaming"
            },
            "asiaCupBonanza": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on fomo7 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, fomo7 online cricket betting, fomo7 cricket betting odds, fomo7 cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical" : "https://fomo7.com/asia-cup-bonanza",
                "alternate": "https://m.fomo7.com/asia-cup-bonanza"
            },

            "bingo": {
                "title": "Play Bingo Casino Game on fomo7 | Upto 400%  Bonus",
                "description": "Explore a world of excitement at fomo7, your gateway to thrilling online games and sports prediction. Play the most popular online real money games and seize your opportunity to win real cash prizes with amazing bonus.",
                "canonical": "https://fomo7.com/online-casino-games/bingo",
                "alternate": "https://m.fomo7.com/online-casino-games/bingo",
                "keywords" : "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "Funkytime": {
                "title": "Play Funky Time Casino Online Game at fomo7",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
                "canonical": "https://fomo7.com/online-casino-games/Funkytime",
                "alternate": "https://m.fomo7.com/online-casino-games/Funkytime",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "evo": {
                "title": "Evo Casino Game on fomo7 | Get Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on fomo7",
                "canonical" : "https://fomo7.com/online-casino-games/evo",
                "alternate": "https://m.fomo7.com/online-casino-games/evo",
                "keywords" : "Evo Gaming, Evolution Gaming Casino, Evo Casino Game"
            },
            "nlc": {
                "title": "NLC Casino Game on fomo7 | 400% Signup  Bonus",
                "description": "Casino slot games are high on entertainment. Play slot games for real money on fomo7 and earn exciting rewards.",
                "canonical": "https://fomo7.com/online-casino-games/slotGames/no-limit-city",
                "alternate": "https://m.fomo7.com/online-casino-games/slotGames/no-limit-city",
                "keywords": "nlc casino Game, nlc, nlc games, slot games for real money, casino slot games"
            },
            "Funky Time": {
                "title": "Funky Time Online | Play for Free or Real Money",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
                "canonical": "https://fomo7.com/online-casino-games/funky-time",
                "alternate": "https://m.fomo7.com/online-casino-games/funky-time",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "First-Person-Blackjack": {
                "title": "Play Blackjack Live Casino Games | Free or Real Money",
                "description": "Play blackjack online on the most safest online casino. Register now at fomo7 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on fomo7, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
                "canonical": "https://fomo7.com/online-casino-games/first%20person%20blackjack",
                "alternate": "https://m.fomo7.com/online-casino-games/first%20person%20blackjack",
                "keywords": "First Person BlackJack,first person black jack,blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "teenpatti": {
                "title": "Play Teenpatti Live Casino Games | Free or Real Money",
                "description": "Teenpatti is a popular casino card game on fomo7. Play this exciting card game for endless entertainment and big wins.",
                "canonical" : "https://fomo7.com/online-casino-games/teenpatti",
                "alternate": "https://www.fomo7.com/online-casino-games/teenpatti",
                "keywords" : "Teenpatti"
            },
            //member dashboard pages
            "memberDashboard": {
                "canonical" : "https://fomo7.com/member/profile",
                "alternate": "https://m.fomo7.com/member/profile"
            },
            "memberProfileAccountInfo": {
                "canonical" : "https://fomo7.com/member/profile/accountinfo",
                "alternate": "https://m.fomo7.com/member/profile/accountinfo"
            },
            "memberProfileChangePassword": {
                "canonical" : "https://fomo7.com/member/profile/password",
                "alternate": "https://m.fomo7.com/member/profile/password"
            },
            "memberProfileBankDetails": {
                "canonical" : "https://fomo7.com/member/profile/bankdetails",
                "alternate": "https://m.fomo7.com/member/profile/bankdetails"
            },
            "memberProfileKYC": {
                "canonical" : "https://fomo7.com/member/profile/kycpage",
                "alternate": "https://m.fomo7.com/member/profile/kycpage"
            },
            "memberFinanceDeposit": {
                "canonical" : "https://fomo7.com/member/finance/deposit",
                "alternate": "https://m.fomo7.com/member/finance/deposit"
            },
            "memberFinanceWithdrawal": {
                "canonical" : "https://fomo7.com/member/finance/withdrawal",
                "alternate": "https://m.fomo7.com/member/finance/withdrawal"
            },
            "memberFinanceHistory": {
                "canonical" : "https://fomo7.com/member/finance/history",
                "alternate": "https://m.fomo7.com/member/finance/history"
            },
            "memberFinanceTransfer": {
                "canonical" : "https://fomo7.com/member/finance/transfer",
                "alternate": "https://m.fomo7.com/member/finance/transfer"
            },
            "memberMyBets": {
                "canonical" : "https://fomo7.com/member/betDetails/mybets",
                "alternate": "https://m.fomo7.com/member/betDetails/mybets"
            },
            "memberP&L": {
                "canonical" : "https://fomo7.com/member/betDetails/profitandloss",
                "alternate": "https://m.fomo7.com/member/betDetails/profitandloss"
            },
            "memberCasinoStatement": {
                "canonical" : "https://fomo7.com/member/betDetails/casinostatement",
                "alternate": "https://m.fomo7.com/member/betDetails/casinostatement"
            },
            "memberSportsbookStatement": {
                "canonical" : "https://fomo7.com/member/betDetails/sportbookstatement",
                "alternate": "https://m.fomo7.com/member/betDetails/sportbookstatement"
            },
            "memberSTPStatement": {
                "canonical" : "https://fomo7.com/member/betDetails/splitThePotStatement",
                "alternate": "https://m.fomo7.com/member/betDetails/splitThePotStatement"
            },
            "memberGalaxysStatement": {
                "canonical" : "https://fomo7.com/member/betDetails/galaxyStatement",
                "alternate": "https://m.fomo7.com/member/betDetails/galaxyStatement"
            },
            "memberAviatrixStatement": {
                "canonical" : "https://fomo7.com/member/betDetails/aviatrixStatement",
                "alternate": "https://m.fomo7.com/member/betDetails/aviatrixStatement"
            },
            "memberAccountStatement": {
                "canonical" : "https://fomo7.com/member/betDetails/accountStatement",
                "alternate": "https://m.fomo7.com/member/betDetails/accountStatement"
            },
            "memberVirtualSportsStatement": {
                "canonical" : "https://fomo7.com/member/betDetails/virtualSportsStatement",
                "alternate": "https://m.fomo7.com/member/betDetails/virtualSportsStatement"
            },
            "memberTurnoverStatement": {
                "canonical" : "https://fomo7.com/member/betDetails/turnoverStatement",
                "alternate": "https://m.fomo7.com/member/betDetails/turnoverStatement"
            },
            "memberPrormotions": {
                "canonical" : "https://fomo7.com/member/promotions",
                "alternate": "https://m.fomo7.com/member/promotions"
            },
            "memberMessages": {
                "canonical" : "https://fomo7.com/member/message",
                "alternate": "https://m.fomo7.com/member/message"
            },
            "ninjacrash": {
                "title": " Play Ninja Crash Game Online on FOMO7 for Real Money",
                "description": "Ready to showcase your ninja skills? Play the Ninja Crash casino game online from Galaxsys on FOMO7 for real money.",
                "canonical" : "https://fomo7.com/instant-games/ninja-crash",
                "alternate": "https://m.fomo7.com/instant-games/ninja-crash",
                "keywords" : "NinjaCrash game, Online NinjaCrash game"
            },
            "big-bash": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at fomo7 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://fomo7.com/promotions/big-bash",
                "alternate": "https://m.fomo7.com/promotions/big-bash",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
           
        },
        "40": {
            "default": {
                "title": "FOMO7 | Best Online Casino & Sports Betting Site In India",
                "description": "FOMO7 is the best online betting site in India, offering casino games & sports betting for real money. Join the biggest real money gambling online & win now!",
                "canonical": "https://fomo7.xyz",
                "alternate": "https://m.fomo7.xyz",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "onlineCasinoGames": {
                "title": "Live Casino on FOMO7 | Play Real Money Online Casino Games",
                "description": "Play live roulette online for real money. Experience the best online roulette game on FOMO7 with higher payouts, faster withdrawal & safer transactions.",
                "canonical": "https://fomo7.xyz/online-casino-games",
                "alternate": "https://m.fomo7.xyz/online-casino-games",
                "keywords": " Online Casino Games, Live Casino Online, Play Casino games online"
            },
            "live-blackjack": {
                "title": "Play Blackjack Online for Real Money - FOMO7 Live Blackjack",
                "description": "Step into the live casino world & play Blackjack online on FOMO7. Experience higher payouts for online Blackjack game. Win real money on Blackjack.",
                "canonical": "https://fomo7.xyz/online-casino-games/live-blackjack",
                "alternate": "https://m.fomo7.xyz/online-casino-games/live-blackjack",
                "keywords": "blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "roulette": {
                "title": "Play Roulette Online For Real Money | FOMO7 Live Roulette",
                "description": "Play live roulette online for real money. Experience the best online roulette game on FOMO7 with higher payouts, faster withdrawal & safer transactions.",
                "canonical": "https://fomo7.xyz/online-casino-games/live-roulette",
                "alternate": "https://m.fomo7.xyz/online-casino-games/live-roulette",
                "keywords": "roulette game,roulette online,casino roulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "lightningroulette": {
                "title": "Play Lightning Roulette Online on FOMO7 | Best Wheel Game",
                "description": "Lightning Roulette live casino game on FOMO7 gives higher multipliers of up to 500x every round. Play Lightning Roulette online for real money.",
                "canonical": "https://fomo7.xyz/online-casino-games/lightning-roulette",
                "alternate": "https://m.fomo7.xyz/online-casino-games/lightning-roulette",
                "keywords": "lightningroulette game,lightningroulette online,casino lightningroulette,online roulette india,lightning roulette,online roulette game,roulette casino game,online roulette real money,play roulette online,casino roulette table"
            },
            "dragonTiger": {
                "title": "Dragon Tiger Game on FOMO7 | Play Dragon Tiger for Real Cash",
                "description": "Elevate your gaming experience with FOMO7 Dragon Tiger game. Play Dragon Tiger online with faster withdrawal & higher payout.",
                "canonical": "https://fomo7.xyz/online-casino-games/dragon-tiger",
                "alternate": "https://m.fomo7.xyz/online-casino-games/dragon-tiger",
                "keywords": "fomo7 is your best destination for playing Online Dragon Tiger Game. Sign up now and get welcome Bonus to test your luck with the most popular games."
            },
            "baccarat": {
                "title": "Play Baccarat Casino Game Online for Real Money on FOMO7",
                "description": "Experience the excitement of Baccarat casino game online. Play Baccarat for real money on FOMO7 with more features & more rewards.",
                "canonical": "https://fomo7.xyz/online-casino-games/live-baccarat",
                "alternate": "https://m.fomo7.xyz/online-casino-games/live-baccarat",
                "keywords": "baccarat game,baccarat online,baccarat casino,baccarat gaming,baccarat rule,baccarat game online,baccarat game rules,play baccarat online,baccarat online play,baccarat online free,best baccarat online casino,best casino for baccarat"
            },
            "teenpattiOnlinePlay": {
                "title": "Play Teen Patti Online Game on FOMO7 | Best Casino Card Game",
                "description": "Play FOMO7 Teen Patti online game for an adrenaline-packed gambling experience! Explore the best casino card game in India with higher payouts.",
                "canonical": "https://fomo7.xyz/teenpatti-online-play",
                "keywords": "teen patti ,teen patti game, teen patti online, teen patti casino"
            },
            "sportsBetting": {
                "title": "Best Sports Betting Exchange in India - FOMO7 Exchange",
                "description": "Bet on all the top sports on the best sports betting exchange in India. Get high odds on FOMO7 exchange with Back & Lay options and Fancy bets.",
                "canonical": "https://fomo7.xyz/betting-exchange",
                "alternate": "https://m.fomo7.xyz/betting-exchange?id=0",
                "keywords": "betting exchange"
            },
            "onlineCricketSattaBazar": {
                "title": "Online Cricket Betting on FOMO7 | Best Cricket Satta Bazar",
                "description": "Bet on cricket matches on FOMO7 online cricket satta bazar. Get the best odds for all cricket matches & experience the top online cricket betting in India.",
                "keywords": " Online Cricket Betting Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, fomo7 online cricket betting, fomo7 cricket betting odds, fomo7 cricket betting, cricket betting sites, online cricket satta bazar,",
                "canonical": "https://fomo7.xyz/betting-exchange/online-cricket-betting",
                "alternate": "https://m.fomo7.xyz/betting-exchange/online-cricket-betting",
            },
            "footballBetting": {
                "title": "Bet on Football in India | Online Football Betting on FOMO7",
                "description": "Bet on football matches on FOMO7. Get high odds for football matches with the best online football betting experience in India.",
                "canonical": "https://fomo7.xyz/betting-exchange/football-betting",
                "alternate": "https://m.fomo7.xyz/betting-exchange/football-betting",
                "keywords": "football prediction site,best football prediction sites,betting app for football,football betting,football bet app,online football betting,betting football site,football betting sites,bet online football,bet football online,best football betting sites,best betting football sites"
            },
            "tennisBetting": {
                "title": "Online Tennis Betting | Best Tennis Betting Odds on FOMO7",
                "description": "Discover the ultimate tennis betting experience in India. Bet on tennis matches online on FOMO7 with high odds & fast payouts.",
                "canonical": "https://fomo7.xyz/betting-exchange/tennis-betting",
                "alternate": "https://m.fomo7.xyz/betting-exchange/tennis-betting",
                "keywords": "predictions tennis,tennis predictions today,today tennis match prediction,betting on tennis tips,tennis betting,tennis betting online,tennis match prediction,tennis bet prediction,bet online tennis,online tennis betting sites"
            },
            "volleyballBetting": {
                "title": "Live Volleyball Betting | Best Volleyball Betting Odds on FOMO7",
                "description": "Bet on volleyball matches in India with our trusted platform. Exciting odds & secure transactions on FOMO7 online volleyball betting!",
                "canonical": "https://fomo7.xyz/betting-exchange/volleyball-betting",
                "alternate": "https://m.fomo7.xyz/betting-exchange/volleyball-betting",
                "keywords": "predictions volleyball,volleyball predictions today,today volleyball match prediction,betting on volleyball tips,volleyball betting,volleyball betting online,volleyball match prediction,volleyball bet prediction,bet online volleyball,online volleyball betting sites"
            },
            "promotions": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at fomo7 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://fomo7.xyz/promotions",
                "alternate": "https://m.fomo7.xyz/promotions",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "specialMarket": {
                "title": "Special Markets on Fomo7",
                "description": "Special Markets on Fomo7",
                "canonical" : "https://fomo7.xyz/specialMarket",
                "alternate": "https://m.fomo7.xyz/specialMarket",
            },
            "virtualSports": {
                "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
                "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
                "canonical" : "https://fomo7.xyz/virtualSports/vci",
                "alternate": "https://m.fomo7.xyz/virtualSports/vci",
            },
            "virtualSportsBL": {
                "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
                "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
                "canonical" : "https://fomo7.xyz/virtualSports/vbl",
                "alternate": "https://m.fomo7.xyz/virtualSports/vbl",
            },
            "virtualSportsFB": {
                "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
                "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
                "canonical" : "https://fomo7.xyz/virtualSports/vfb",
                "alternate": "https://m.fomo7.xyz/virtualSports/vfb",
            },
            "virtualSportsFEL": {
                "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
                "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
                "canonical" : "https://fomo7.xyz/virtualSports/vfel",
                "alternate": "https://m.fomo7.xyz/virtualSports/vfel",
            },
            "virtualSportsFEC": {
                "title": "Virtual Sports Betting Online in India | Bet on FOMO7",
                "description": "Get the best virtual sports betting experience in India on FOMO7! Explore all the top sports under our virtual sports section with high odds & fast payouts.",
                "canonical" : "https://fomo7.xyz/virtualSports/vfec",
                "alternate": "https://m.fomo7.xyz/virtualSports/vfec",
            },
            "virtualSportsVTI": {
                "title": "Elevate Your Game with Virtual Tennis Betting ID",
                "description": "Discover the ultimate virtual tennis betting ID for an unparalleled gaming adventure. Predict, bet, and win big with our cutting-edge platform. Join now!",
                "canonical" : "https://fomo7.xyz/virtualSports/vti",
                "alternate": "https://fomo7.xyz/virtualSports/vti",
            },
            "virtualSportsWC": {
                "title": "Navigating Virtual World Cup Betting ID Like a Pro | Bet Smart,Win Big",
                "description": "Explore thrilling with virtual World Cup betting ID services for an immersive gaming experience like never before!",
                "canonical" : "https://fomo7.xyz/virtualSports/vfwc",
                "alternate": "https://fomo7.xyz/virtualSports/vfwc",
            },
            "slotGames": {
                "title": "Best Slot Games in India | Play Slots on FOMO7",
                "description": "Explore a variety of casino slot games on FOMO7. Play the best slots online & win real money instantly. Hit the jackpot everyday on FOMO7 slots.",
                "canonical": "https://fomo7.xyz/online-casino-games/slotGames",
                "alternate": "https://m.fomo7.xyz/online-casino-games/slotGames",
                "keywords": "slot games for real money, casino slot games"
            },
            "aviator": {
                "title": "Play Aviator Game Online - FOMO7 Aviator Game for Real Money",
                "description": "Fly high in the Aviator game online! Play Aviator on FOMO7 for real money with higher multiplier every round. Cash out instantly to your wallet.",
                "canonical": "https://fomo7.xyz/instant-games/aviator",
                "alternate": "https://m.fomo7.xyz/instant-games/aviator",
                "keywords": "Aviator game, Aviator online game "
            },
            "cashshow": {
                "title": "Cash Show Casino Game | Play Cash show Online on FOMO7",
                "description": "Play Cash show casino game from Galaxsys on FOMO7! Get real money cashow game with the mix of fashion & thrill.",
                "canonical" : "https://fomo7.xyz/instant-games/cashshow",
                "alternate": "https://m.fomo7.xyz/instant-games/cashshow",
                "keywords" : "Cash Show game, Cash Show online game "
            },
            "jetx": {
                "title": "Play JetX Casino Game on FOMO7 | JetX Online Game",
                "description": "Play Jetx game online on FOMO7. Get higher multiplier every round, excited features & faster withdrawals instantly to your wallet. Explore Jetx casino game.",
                "canonical" : "https://www.fomo7.xyz/instant-games/jetx",
                "alternate": "https://m.fomo7.xyz/instant-games/jetx",
                "keywords" : "Jet X game, Jet X online game "
            },
            "rockpaperscissors": {
                "title": "Rockpaperscissors ID - Grab Your Exclusive Deals Now!",
                "description": "Explore the thrill of rockpaperscissors ID gaming. Join the fun with our interactive platform and challenge opponents worldwide",
                "canonical" : "https://fomo7.xyz/instant-games/rock-paper-scissors",
                "alternate": "https://fomo7.xyz/instant-games/rock-paper-scissors",
                "keywords" : "rockpaperscissors id, rockpaperscissors game, rockpaperscissors online"
            },
            "plinkox": {
                "title": "Play PlinkoX Online Game for Real Money on FOMO7",
                "description": "Play PlinkoX online on FOMO7 & win big! Get the highest multiplier for PlinkoX Casino game in India with faster withdrawal.",
                "canonical" : "https://www.fomo7.xyz/instant-games/plinko-x",
                "alternate": "https://m.fomo7.xyz/instant-games/plinko-x",
                "keywords" : "Plinkox game, Plinkox online game "
            },
            "footballx": {
                "title": "Football X Game | Live Casino Online Games on fomo7",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
                "canonical" : "https://www.fomo7.xyz/instant-games/football-x",
                "alternate": "https://m.fomo7.xyz/instant-games/football-x",
                "keywords" : "Football X game, Football X online game "
            },
            "balloon": {
                "title": "Balloon Game | Live Casino Online Games on fomo7",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
                "canonical" : "https://www.fomo7.xyz/instant-games/balloon",
                "alternate": "https://m.fomo7.xyz/instant-games/balloon",
                "keywords" : "Balloon game, Balloon online game "
            },
            "mines": {
                "title": "Mines Casino Games on FOMO7 | Best Mine Game Online",
                "description": "Discover the excitement of Mines Casino games on FOMO7! Play real money mines game, win big & feel the thrill with every move.",
                "canonical" : "https://www.fomo7.xyz/instant-games/mines",
                "alternate": "https://m.fomo7.xyz/instant-games/mines",
                "keywords" : "Mines game, Online mines game"
            },
            "macludo": {
                "title": "Macludo Casino Games Online - Play & Win 24/7",
                "description": "Ludo Game is lucrative. Register and get the best gaming experience. Play Ludo Game online for real cash and win. Get the profits directly. ",
                "canonical" : "https://fomo7.xyz/online-casino-games/macludo",
                "alternate": "https://m.fomo7.xyz/online-casino-games/macludo",
                "keywords" : "Ludo Game, Ludo game Online"
            },
            "crazytime": {
                "title": "Crazy Time Live Game on FOMO7 | Play Crazy Time Online",
                "description": "Play Crazy Time live game on FOMO7 for higher payouts every round. Get live crazy time stats, scores & tracker. Join & win real money.",
                "canonical" : "https://www.fomo7.xyz/online-casino-games/crazy-time",
                "alternate" : "https://m.fomo7.xyz/online-casino-games/crazy-time",
                "keywords" : "Crazytime Game, Crazytime game Online"
            },
            "welcomeBonus": {
                "title": "Best Casino & Sports Betting welcome Bonus in India | FOMO7",
                "description": "FOMO7 offers a massive 700% welcome bonus for casino & sports betting. Get the best online betting sign-up bonus. Join & get FOMO7 welcome bonus.",
                "canonical" : "https://fomo7.xyz/promotions/welcome-bonus",
                "alternate": "https://m.fomo7.xyz/promotions/welcome-bonus",
                "keywords" : "Joining Bonus"
            },
            "premiumSportsbook": {
                "title": "Premium Sportsbook in India | FOMO7 Online Sportsbook",
                "description": "Bet on sports through FOMO7 Premium Sportsbook. Get premium options, features & payouts, available for all matches, leagues & sports.",
                "canonical" : "https://fomo7.xyz/premium-sportsbook",
                "alternate": "https://m.fomo7.xyz/premium-sportsbook",
                "keywords" : "Premium Sportsbook Betting ,Best Online Premium Sportsbook Betting, Sports Betting in India"
            },
            "refillBonus": {
                "title": "Redeposit Bonus for Casino & Sports Betting in India | FOMO7",
                "description": "Get 5% redeposit bonus instantly on FOMO7! Play top games & bet on sports with extra rewards for every deposit. Get the best redeposit bonus for online casinos now!",
                "canonical" : "https://fomo7.xyz/promotions/refill-bonus",
                "alternate": "https://m.fomo7.xyz/promotions/refill-bonus",
                "keywords" : "Redeposit bonus"
            },
            "cashbackBonus": {
                "title": "Lossback Bonus on FOMO7 | Get 5% Cashback for your losses",
                "description": "FOMO7 offers a grand 5% lossback bonus without any RO conditions for both casino games & sports betting in India.",
                "canonical" : "https://fomo7.xyz/promotions/cashback-bonus",
                "alternate": "https://m.fomo7.xyz/promotions/cashback-bonus",
                "keywords" : "Cashback Bonus"
            },
            "referralBonus": {
                "title": "Refer & Get 21% | Best Online Casino Referral Bonus on FOMO7",
                "description": "A mega 21% referral bonus awaits you on FOMO7! Refer, earn & play the best casino games and bet on your favourite sports. Every referral gets a reward.",
                "canonical" : "https://fomo7.xyz/promotions/referral-bonus",
                "alternate": "https://m.fomo7.xyz/promotions/referral-bonus",
                "keywords" : "Referral Bonus"
            },
            "termsandconditions": {
                "title": "Terms & Conditions for FOMO7",
                "description": "Find the complete terms & conditions for playing on FOMO7. Get the restrictions, obligations and allowed practices.",
                "canonical" : "https://fomo7.xyz/terms-conditions",
                "alternate": "https://m.fomo7.xyz/about/terms-conditions",
                "keywords" : "Terms & Conditions"
            },
            "responsibleGambling": {
                "title": "Responsible Gaming | FOMO7",
                "description": "Get the requisite details of Responsible Gaming on FOMO7. It is applicable to both casino games and sports prediction.",
                "canonical" : "https://fomo7.xyz/responsible-gambling",
                "alternate": "https://m.fomo7.xyz/about/responsible-gambling",
                "keywords" : "Responsible Gambling"
            },
            "privacyPolicy": {
                "title": "Complete Details of Privacy Policy of FOMO7",
                "description": "Find all the information regarding the Privacy Policy of FOMO7. From data storage to security details, get everything related to privacy maintenance.",
                "canonical" : "https://fomo7.xyz/privacy-policy",
                "alternate": "https://m.fomo7.xyz/about/privacy-policy",
                "keywords" : "privacy policy"
            },
            "kycPolicy": {
                "title": "KYC Policy of FOMO7 | Find the KYC Requirements & Process",
                "description": " Find the detailed KYC policy of FOMO7. Get the complete guidelines for our KYC policy and understand the documents required for the process.",
                "canonical" : "https://fomo7.xyz/kyc-policy",
                "alternate": "https://m.fomo7.xyz/about/kyc-policy",
                "keywords" : "KYC Policy"
            },
            "exclusionPolicy": {
                "title": "Self-Exclusion Policy of FOMO7 | What & How",
                "description": "Find what is the Self-Exclusion Policy of FOMO7. Get detailed information of the entire process of self-exclusion.",
                "canonical" : "https://fomo7.xyz/exclusion-policy",
                "alternate": "https://m.fomo7.xyz/about/self-exclusion-policy",
                "keywords" : "exclusion policy "
            },
            "amlPolicy": {
                "title": "AML Policy of FOMO7 | Anti-Money Laundering Policy",
                "description": "Find the complete Anti-Money Laundering policy of FOMO7. Get the details of xyzpliance procedures.",
                "canonical" : "https://fomo7.xyz/aml-policy",
                "alternate": "https://m.fomo7.xyz/about/aml-policy",
                "keywords" : "AML Policy"
            },
            "aboutus": {
                "title": "About FOMO7 | Your Online Betting Destination",
                "description": "Learn about FOMO7, a leading online betting site in India that offers various casino games and sports betting options. Find more about us.",
                "canonical" : "https://fomo7.xyz/about-us",
                "alternate": "https://m.fomo7.xyz/about/about-us",
                "keywords" : "About us, About fomo7"
            },
            "SPRIBE": {
                "title": "Spribe Casino Games | Get the best Spribe Games on fomo7",
                "description": "Spribe Casino Games are popular and prevalent. We picked the best games for you. Play with just a single sign-in option and explore the gaming options",
                "canonical" : "https://fomo7.xyz/instant-games/spribe",
                "alternate": "https://m.fomo7.xyz/instant-games/spribe",
                "keywords" : "spribe casino games"
            },
            "aviatrix": {
                "title": "Play Aviatrix Game Online | FOMO7 Aviatrix For Real Cash",
                "description": "Play Aviatrix Online game for real cash on FOMO7. Fly more, win more. Get high multipliers for Aviatrix casino crash game.",
                "canonical" : "https://fomo7.xyz/instant-games/aviatrix",
                "alternate": "https://m.fomo7.xyz/instant-games/aviatrix",
                "keywords" : "Aviatrix casino games"
            },
            "7MOJOS": {
                "title": "7Mojos Games | Explore the top games at the best platform",
                "description": "7Mojos Games in one place! One registration, multiple gaming options and better gaming experience. Explore the demo versions as well. Register now. ",
                "canonical" : "https://fomo7.xyz/online-casino-games/7mojos",
                "alternate": "https://m.fomo7.xyz/online-casino-games/7mojos",
                "keywords" : "7Mojos Games"
            },
            "supernowa": {
                "title": "Play Supernowa Casino Games on fomo7",
                "description": " Supernowa casino games are one of the best in business. Check out Supernowa gaming and see the games offered on fomo7.",
                "canonical" : "https://fomo7.xyz/online-casino-games/supernowa",
                "alternate": "https://m.fomo7.xyz/online-casino-games/supernowa",
                "keywords" : "Supernowa Casino Games,Supernowa gaming, Supernowa casino"
            },
            "pragmatic": {
                "title": "Pragmatic Casino Game on fomo7 | Get 400% welcome Bonus",
                "description": "Pragmatic Casino Games are available now! Providing the ultimate gaming experience with a wide range of Pragmatic games online. Explore now!",
                "canonical" : "https://fomo7.xyz/online-casino-games/slotGames/pragmatic-play",
                "alternate": "https://m.fomo7.xyz/online-casino-games/slotGames/pragmatic-play",
                "keywords" : "Pragmatic casino, pragmatic games"
            },
            "superspade": {
                "title": "Top Super Spade Games On fomo7 | Play Now!",
                "description": " Super Spade games are popular because of exciting features, gameplay & payouts. Play Super Spade casino games on fomo7 and win big. Join now!",
                "canonical" : "https://fomo7.xyz/online-casino-games/super-spade",
                "alternate": "https://m.fomo7.xyz/online-casino-games/super-spade",
                "keywords" : "super spade games,play super spade casino games, super spade casino"
            },
            "tvbet": {
                "title": "Play TVBET Casino Games Online | Real Money Games",
                "description": "TVBET casino games provide engaging games. A wide variety of games await online casino gaming enthusiasts. Find out more about fomo7 TVBET Games.",
                "canonical" : "https://fomo7.xyz/online-casino-games/tvbet",
                "alternate": "https://m.fomo7.xyz/online-casino-games/tvbet",
                "keywords" : "TVBET casino games, fomo7 TVBET games"
            },
            "netent": {
                "title": "Netent Casino Game on fomo7 | Get Upto 400% Bonus",
                "description": "NetEnt Games provide the widest range of online slots and casino slot games. Through one registration, explore all the top slot games online.",
                "canonical" : "https://fomo7.xyz/online-casino-games/slotGames/netent",
                "alternate": "https://m.fomo7.xyz/online-casino-games/slotGames/netent",
                "keywords" : "netent games"
            },
            "betgames": {
                "title": "BetGames On fomo7 | Play BetGames & Win Big",
                "description": "BetGames is one of the modern-day game providers in fomo7. Several casino gaming enthusiasts love the games. Here's why.BetGames is another top live dealer casino game provider that is available on fomo7 from the comfort of your home. The best part of BetGames is that instead of promoting state-of-the-art technology, they have made their gaming solutions much more accessible to new gamers who want to explore the gaming industry at their own pace.Over the years, the live casino has been the hot trend in the gaming industry, and BetGames, too, has tapped into this market, creating some of the most awe-inspiring live dealer games. Most of these games make you feel like you are in a physical casino.",
                "canonical" : "https://fomo7.xyz/online-casino-games/bet-games",
                "alternate": "https://m.fomo7.xyz/online-casino-games/bet-games",
                "keywords" : "betgames,fomo7 BetGames"
            },
            "sportsbook": {
                "title": "Online Sportsbook in India | Best Sportsbook Odds on FOMO7",
                "description": "Bet on sports through FOMO7 online sportsbook. Explore betting options for all sports with high odds, fast payouts and for all matches, leagues & tournaments.",
                "canonical" : "https://fomo7.xyz/sportsbook",
                "alternate": "https://m.fomo7.xyz/sportsbook",
                "keywords" : "Sportsbook Betting,Best Online Sportsbook, Sports Betting in India"
            },
            "appDownload": {
                "title": "FOMO7 Online Betting App | Best Casino & Sports Betting Apk",
                "description": "Download FOMO7 App for the best casino & sports betting experience in India. Play real-money gambling on FOMO7 apk. Get the best online betting app now!",
                "canonical" : "https://fomo7.xyz/app-download",
                "alternate": "https://m.fomo7.xyz/app-download",
                "keywords" : "fomo7 App Download, fomo7 Android App"
            },
            "signup": {
                "title": "Register on FOMO7 | Best online casino & sports betting site",
                "description": "FOMO7 registration process is faster & smoother. Get the best offers & bonuses on FOMO7. Explore casino & sports betting options with one registration.",
                "canonical" : "https://fomo7.xyz/signup",
                "alternate": "https://m.fomo7.xyz/signup",
                "keywords" : "fomo7 User Registration"
            },
            "faq": {
                "title": "FOMO7 FAQs | Frequently Asked Questions",
                "description": "Go through a list of frequently asked questions on FOMO7 about registration, deposit, withdrawal & other important aspects of online gambling.",
                "canonical" : "https://fomo7.xyz/faq",
                "alternate": "https://m.fomo7.xyz/faq",
            },
            "affiliate": {
                "title": "Affiliate Page",
                "description": "Affiliate Page",
                "canonical" : "https://fomo7.xyz/affiliate",
                "alternate": "https://m.fomo7.xyz/affiliate",
            },
            "loyalty": {
                "title": "Loyalty Page",
                "description": "Loyalty Page",
                "canonical" : "https://fomo7.xyz/loyalty",
                "alternate": "https://m.fomo7.xyz/loyalty",
            },
            "login": {
                "title": "Log in to FOMO7 | Best online casino & sports betting site",
                "description": "Use your existing FOMO7 credentials to log in now. Explore top casino & sports betting options. Get a mega bonus for every deposit.",
                "canonical" : "https://fomo7.xyz/login",
                "alternate": "https://m.fomo7.xyz/login",
                "keywords" : "fomo7 Login"
            },
            "instantGames": {
                "title": "Instant Games on FOMO7 | Play the Best Instant Games Online",
                "description": "Explore the FOMO7 Instant Games & play for real money. Get the best instant games in online casinos in India. Instant rewards await you. Join now!",
                "canonical" : "https://fomo7.xyz/instant-games",
                "alternate": "https://m.fomo7.xyz/instant-games",
                "keywords" : "Instant games,casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "majorLeagueCricketBetting": {
                "title": "MLC Betting in India | Major League Cricket Betting Online",
                "description": "MLC Betting is available on fomo7! Get the best odds & markets for Major League Cricket betting online. Go through our free MLC gambling guide.",
                "canonical" : "https://fomo7.xyz/sports/tournaments/major-league-cricket-betting",
                "alternate": "https://m.fomo7.xyz/sports/tournaments/major-league-cricket-betting",
                "keywords" : "Major League Cricket Betting Online, MLC Betting in India"
            },
            "dailyDepositBonus": {
                "title": "Refill bonus | Best Casino Refill Bonus on fomo7",
                "description": "fomo7 Daily Deposit Bonus brings in a plethora of casino cashback opportunities. Know the rules, process, T&C of Daily Deposit Bonus on fomo7.",
                "canonical" : "https://fomo7.xyz/daily-deposit-bonus",
                "alternate": "https://m.fomo7.xyz/daily-deposit-bonus",
                "keywords" : "daily deposit bonus, deposit bonus, bonus, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites "
            },
            "popular games": {
                "title": "Play Top Casino Games Online for Real Money on FOMO7",
                "description": "Explore the top casino games in India on FOMO7. Play the best online casino games for real money, with higher chances of winning & withdraw instantly.",
                "canonical" : "https://fomo7.xyz/online-casino-games/best-casino-games",
                "alternate": "https://m.fomo7.xyz/online-casino-games/best-casino-games",
                "keywords" : "Best Casino Games Popular Casino Games, online casino real money"
            },
            "evolution": {
                "title": "Play Evolution Gaming Casino | Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on fomo7",
                "canonical" : "https://fomo7.xyz/online-casino-games/evolution-gaming",
                "alternate": "https://m.fomo7.xyz/online-casino-games/evolution-gaming",
                "keywords" : "Evolution Gaming, Evolution Gaming Casino"
            },
            
            "spribe": {
                "title": "Spribe Games | Play The Best fomo7 Spribe Games",
                "description": "Spribe games use the latest technology to build innovative and immersive live casino games for users. Find out how to experience thrilling fomo7 Spribe Games.",
                "canonical" : "https://fomo7.xyz/instant-games/spribe",
                "alternate": "https://m.fomo7.xyz/instant-games/spribe",
                "keywords" : "Spribe Games,fomo7 Spribe games,fomo7 Spribe games,Spribe live casino games"
            },
            "cricketx": {
                "title": "CricketX Casino Game | Play CricketX for Real Money on FOMO7",
                "description": "Play CricketX Casino game on FOMO7. Immerse yourself in the ultimate gaming experience! Get the thrill of cricket & fun of casino with CricketX game.",
                "canonical" : "https://fomo7.xyz/instant-games/cricket-x",
                "alternate": "https://m.fomo7.xyz/instant-games/cricket-x",
                "keywords" : "Cricketx Games,fomo7 Cricketx games,fomo7 Cricketx games,Cricketx casino games"
           },
            "ezugi": {
                "title": "Ezugi Live Casino | Play fomo7 Ezugi Games",
                "description": " Ezugi's state-of-the-art live casino games are a massive hit among fans. Let us do a deep dive into Ezugi Live Casino games.Ezugi live casino is a popular gaming provider in the online gaming world. A huge number of casino gaming enthusiasts play Ezugi live casino games on fomo7. Unlike some of the other competitors, Ezugi gaming provides you with an ultimate gaming experience.A couple of things make Ezugi live casino dealer games so different. Let's learn more about the gaming provider.",
                "canonical" : "https://fomo7.xyz/online-casino-games/ezugi-live-casino",
                "alternate": "https://m.fomo7.xyz/online-casino-games/ezugi-live-casino",
                "keywords" : "Ezugi Live Casino,Ezugi, Ezugi Gaming, fomo7 Ezugi Live Casino"
            },
            "sexygaming": {
                "title": "Play Sexy Gaming Casino Online | Get 400% Signup  Bonus",
                "description": "Sexy Gaming casino is one of Asia's most popular live casino game providers. Play sexy gaming casino games on fomo7 and win exciting rewards.",
                "canonical" : "https://fomo7.xyz/online-casino-games/sexy-gaming",
                "alternate": "https://m.fomo7.xyz/online-casino-games/sexy-gaming",
                "keywords" : "sexy gaming casino,sexy gaming casino games, fomo7 sexy gaming casino games, fomo7 sexy gaming live casino game"
            },
            "monopolylive": {
                "title": "Play Monopoly Game Online & Win Real Money on FOMO7 Casino",
                "description": "Play Monopoly Live game on FOMO7 casino. Get higher payouts every round & win real money to your account directly.",
                "canonical" : "https://fomo7.xyz/online-casino-games/monopoly-live",
                "alternate": "https://m.fomo7.xyz/online-casino-games/monopoly-live",
                "keywords" : "MONOPOLY LIVE"
            },
            "funkytime": {
                "title": "Play Funky Time Casino Game for Real Money on FOMO7",
                "description": "Play Funky Time online game on FOMO7. Explore the wheel game features, payouts & betting options. Join & win real money on Funky Time.",
                "canonical" : "https://fomo7.xyz/online-casino-games/funkytime",
                "alternate": "https://m.fomo7.xyz/online-casino-games/funkytime",
                "keywords" : "funkytime"
            },
            "lightningdice": {
                "title": "Play Lightning Dice Casino Game for Real Money on FOMO7",
                "description": "Light up your gaming experience with Lightning Dice casino game on FOMO7. Get lightning multipliers & bigger payouts on online lightning dice game.",
                "canonical" : "https://fomo7.xyz/online-casino-games/lightning-dice",
                "alternate": "https://m.fomo7.xyz/online-casino-games/lightning-dice",
                "keywords" : "Lightning Dice"
            },
            "macaw": {
                "title": "Macaw Games | Top Macaw Casino Online Games on fomo7",
                "description": "Macaw games are thrilling and are available online on fomo7. Learn more about the popular Macaw casino online games.",
                "canonical" : "https://fomo7.xyz/online-casino-games/macaw",
                "alternate": "https://m.fomo7.xyz/online-casino-games/macaw",
                "keywords" : "macaw games, macaw casino online"
            },
            "ag": {
                "title": "Play Your Favourite AG Game Online at fomo7",
                "description": "Amazing Gaming has established itself as one of the best gaming solutions providers. Play Amazing Gaming games on fomo7.",
                "canonical" : "https://fomo7.xyz/online-casino-games/ag",
                "alternate": "https://m.fomo7.xyz/online-casino-games/ag",
                "keywords" : "Amazing Gaming"
            },
            "asiaCupBonanza": {
                "title": "Asia Cup Bonanza | Best Cricket Betting Odds",
                "description": "Online cricket betting on fomo7 provides the best betting odds for cricket in India. Get live score, fancy bets & fast withdrawal. Bet on cricket now!",
                "keywords": "Asia Cup Bonanza, Online Cricket Betting, Cricket Betting, cricket betting odds, cricket betting site, best site to bet on cricket, best cricket betting site (s) in India, fomo7 online cricket betting, fomo7 cricket betting odds, fomo7 cricket betting, cricket betting sites, online cricket satta bazar",
                "canonical" : "https://fomo7.xyz/asia-cup-bonanza",
                "alternate": "https://m.fomo7.xyz/asia-cup-bonanza"
            },

            "bingo": {
                "title": "Play Bingo Casino Game on fomo7 | Upto 400%  Bonus",
                "description": "Explore a world of excitement at fomo7, your gateway to thrilling online games and sports prediction. Play the most popular online real money games and seize your opportunity to win real cash prizes with amazing bonus.",
                "canonical": "https://fomo7.xyz/online-casino-games/bingo",
                "alternate": "https://m.fomo7.xyz/online-casino-games/bingo",
                "keywords" : "casino games, bingo, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "Funkytime": {
                "title": "Play Funky Time Casino Online Game at fomo7",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
                "canonical": "https://fomo7.xyz/online-casino-games/Funkytime",
                "alternate": "https://m.fomo7.xyz/online-casino-games/Funkytime",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "evo": {
                "title": "Evo Casino Game on fomo7 | Get Upto 400%  Bonus",
                "description": "Evolution Gaming is one of the best gaming platforms. Learn how to enjoy an array of Evolution Gaming casino games on fomo7",
                "canonical" : "https://fomo7.xyz/online-casino-games/evo",
                "alternate": "https://m.fomo7.xyz/online-casino-games/evo",
                "keywords" : "Evo Gaming, Evolution Gaming Casino, Evo Casino Game"
            },
            "nlc": {
                "title": "NLC Casino Game on fomo7 | 400% Signup  Bonus",
                "description": "Casino slot games are high on entertainment. Play slot games for real money on fomo7 and earn exciting rewards.",
                "canonical": "https://fomo7.xyz/online-casino-games/slotGames/no-limit-city",
                "alternate": "https://m.fomo7.xyz/online-casino-games/slotGames/no-limit-city",
                "keywords": "nlc casino Game, nlc, nlc games, slot games for real money, casino slot games"
            },
            "Funky Time": {
                "title": "Funky Time Online | Play for Free or Real Money",
                "description": "Online Casino games are revolutionizing the gaming world. Find out some of the popular live casino online games on fomo7.",
                "canonical": "https://fomo7.xyz/online-casino-games/funky-time",
                "alternate": "https://m.fomo7.xyz/online-casino-games/funky-time",
                "keywords" : "casino games, Funkytime, Funky Time, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
            "First-Person-Blackjack": {
                "title": "Play Blackjack Live Casino Games | Free or Real Money",
                "description": "Play blackjack online on the most safest online casino. Register now at fomo7 and get exclusive welcome bonus and offers to win your favorite games,Blackjack Game is now open for bets. Play Blackjack online on fomo7, India’s leading casino gambling site. Bet with real money through UPI. Blackjack Game is a gambling activity that is popular in casinos throughout the world. It is also called Pontoon and Twenty-one.",
                "canonical": "https://fomo7.xyz/online-casino-games/first%20person%20blackjack",
                "alternate": "https://m.fomo7.xyz/online-casino-games/first%20person%20blackjack",
                "keywords": "First Person BlackJack,first person black jack,blackjack online,blackjack game,playing blackjack,blackjack playing,play blackjack online,blackjack game rules,online blackjack real money,play blackjack,blackjack online game,online blackjack real money,black jack card games"
            },
            "teenpatti": {
                "title": "Play Teenpatti Live Casino Games | Free or Real Money",
                "description": "Teenpatti is a popular casino card game on fomo7. Play this exciting card game for endless entertainment and big wins.",
                "canonical" : "https://fomo7.xyz/online-casino-games/teenpatti",
                "alternate": "https://www.fomo7.xyz/online-casino-games/teenpatti",
                "keywords" : "Teenpatti"
            },
            //member dashboard pages
            "memberDashboard": {
                "canonical" : "https://fomo7.xyz/member/profile",
                "alternate": "https://m.fomo7.xyz/member/profile"
            },
            "memberProfileAccountInfo": {
                "canonical" : "https://fomo7.xyz/member/profile/accountinfo",
                "alternate": "https://m.fomo7.xyz/member/profile/accountinfo"
            },
            "memberProfileChangePassword": {
                "canonical" : "https://fomo7.xyz/member/profile/password",
                "alternate": "https://m.fomo7.xyz/member/profile/password"
            },
            "memberProfileBankDetails": {
                "canonical" : "https://fomo7.xyz/member/profile/bankdetails",
                "alternate": "https://m.fomo7.xyz/member/profile/bankdetails"
            },
            "memberProfileKYC": {
                "canonical" : "https://fomo7.xyz/member/profile/kycpage",
                "alternate": "https://m.fomo7.xyz/member/profile/kycpage"
            },
            "memberFinanceDeposit": {
                "canonical" : "https://fomo7.xyz/member/finance/deposit",
                "alternate": "https://m.fomo7.xyz/member/finance/deposit"
            },
            "memberFinanceWithdrawal": {
                "canonical" : "https://fomo7.xyz/member/finance/withdrawal",
                "alternate": "https://m.fomo7.xyz/member/finance/withdrawal"
            },
            "memberFinanceHistory": {
                "canonical" : "https://fomo7.xyz/member/finance/history",
                "alternate": "https://m.fomo7.xyz/member/finance/history"
            },
            "memberFinanceTransfer": {
                "canonical" : "https://fomo7.xyz/member/finance/transfer",
                "alternate": "https://m.fomo7.xyz/member/finance/transfer"
            },
            "memberMyBets": {
                "canonical" : "https://fomo7.xyz/member/betDetails/mybets",
                "alternate": "https://m.fomo7.xyz/member/betDetails/mybets"
            },
            "memberP&L": {
                "canonical" : "https://fomo7.xyz/member/betDetails/profitandloss",
                "alternate": "https://m.fomo7.xyz/member/betDetails/profitandloss"
            },
            "memberCasinoStatement": {
                "canonical" : "https://fomo7.xyz/member/betDetails/casinostatement",
                "alternate": "https://m.fomo7.xyz/member/betDetails/casinostatement"
            },
            "memberSportsbookStatement": {
                "canonical" : "https://fomo7.xyz/member/betDetails/sportbookstatement",
                "alternate": "https://m.fomo7.xyz/member/betDetails/sportbookstatement"
            },
            "memberSTPStatement": {
                "canonical" : "https://fomo7.xyz/member/betDetails/splitThePotStatement",
                "alternate": "https://m.fomo7.xyz/member/betDetails/splitThePotStatement"
            },
            "memberGalaxysStatement": {
                "canonical" : "https://fomo7.xyz/member/betDetails/galaxyStatement",
                "alternate": "https://m.fomo7.xyz/member/betDetails/galaxyStatement"
            },
            "memberAviatrixStatement": {
                "canonical" : "https://fomo7.xyz/member/betDetails/aviatrixStatement",
                "alternate": "https://m.fomo7.xyz/member/betDetails/aviatrixStatement"
            },
            "memberAccountStatement": {
                "canonical" : "https://fomo7.xyz/member/betDetails/accountStatement",
                "alternate": "https://m.fomo7.xyz/member/betDetails/accountStatement"
            },
            "memberVirtualSportsStatement": {
                "canonical" : "https://fomo7.xyz/member/betDetails/virtualSportsStatement",
                "alternate": "https://m.fomo7.xyz/member/betDetails/virtualSportsStatement"
            },
            "memberTurnoverStatement": {
                "canonical" : "https://fomo7.xyz/member/betDetails/turnoverStatement",
                "alternate": "https://m.fomo7.xyz/member/betDetails/turnoverStatement"
            },
            "memberPrormotions": {
                "canonical" : "https://fomo7.xyz/member/promotions",
                "alternate": "https://m.fomo7.xyz/member/promotions"
            },
            "memberMessages": {
                "canonical" : "https://fomo7.xyz/member/message",
                "alternate": "https://m.fomo7.xyz/member/message"
            },
            "ninjacrash": {
                "title": " Play Ninja Crash Game Online on FOMO7 for Real Money",
                "description": "Ready to showcase your ninja skills? Play the Ninja Crash casino game online from Galaxsys on FOMO7 for real money.",
                "canonical" : "https://fomo7.xyz/instant-games/ninja-crash",
                "alternate": "https://m.fomo7.xyz/instant-games/ninja-crash",
                "keywords" : "NinjaCrash game, Online NinjaCrash game"
            },
            "big-bash": {
                "title": "Play Casino Games | Real Money Gambling | Satta Online 24/7",
                "description": "Play the most popular online casino games at fomo7 & earn a chance to win real money. Get unbeatable welcome bonus & offers on your favorite satta games in India",
                "canonical" : "https://fomo7.xyz/promotions/big-bash",
                "alternate": "https://m.fomo7.xyz/promotions/big-bash",
                "keywords" : "casino games, real money gambling, satta game online, best betting sites in india, gamble online with real money, betting sites in india, online betting sites, best betting app, online betting apps, online betting, gambling real money, casino online gambling, online gambling, best betting sites, gambling apps real money, online gambling india, online gambling real money, gambling app real money, online betting in india, gambling online sites, indian gambling sites, gambling website, best online gambling site"
            },
           
        }

    }

export default seoData;